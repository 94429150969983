// hooks/events/useFetchEventDataById.js

import { useCallback, useEffect, useState } from 'react'
import { fetchEventDetails } from 'services/events-service'

const useFetchEventDataById = (eventId) => {
  const [data, setData] = useState(null)
  const [initialLoading, setInitialLoading] = useState(true) // Estado de carga inicial
  const [loading, setLoading] = useState(false) // Estado de carga para actualizaciones
  const [error, setError] = useState(null)

  const fetchData = useCallback(async () => {
    try {
      if (initialLoading) {
        setInitialLoading(true)
      } else {
        setLoading(true)
      }
      const response = await fetchEventDetails(eventId)
      setData(response?.getEventById?.event)
      setError(null)
    } catch (error) {
      setError(error)
    } finally {
      setInitialLoading(false)
      setLoading(false)
    }
  }, [eventId, initialLoading])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const refreshData = useCallback(() => {
    fetchData()
  }, [fetchData])

  return { data, initialLoading, loading, error, refreshData }
}

export default useFetchEventDataById
