import React, { useState } from 'react'
import { 
  Checkbox, 
  Grid, 
  Typography, 
  IconButton,
  Menu,
  MenuItem,
  Box
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

const UserTableRow = ({ 
  user, 
  onEditUser, 
  onDeleteUser,
  isSelected, 
  onSelectUser,
  isSmallScreen 
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = () => {
    onEditUser(user)
    handleClose()
  }

  const handleDelete = () => {
    onDeleteUser(user.id)
    handleClose()
  }

  return (
    <Box 
      sx={{ 
        px: 2,
        py: 1.5,
        borderBottom: '1px solid #E5E7EB',
        '&:hover': { bgcolor: '#F9FAFB' },
      }}
    >
      <Grid container alignItems="center">
        <Grid item sx={{ width: 48, pr: 1 }}>
          <Checkbox
            checked={isSelected}
            onChange={() => onSelectUser(user.id)}
            sx={{
              color: '#D0D5DD',
              '&.Mui-checked': {
                color: '#F79009',
              },
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Typography sx={{ textAlign: 'center' }}>
            {user.name}
          </Typography>
        </Grid>
        {!isSmallScreen && (
          <Grid item xs={5}>
            <Typography sx={{ textAlign: 'center' }}>
              {user.email}
            </Typography>
          </Grid>
        )}
        <Grid item sx={{ width: 60, ml: 'auto' }}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ color: '#98A2B3' }}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleEdit}>
              <EditIcon sx={{ mr: 1, fontSize: 20 }} />
              Editar
            </MenuItem>
            <MenuItem 
              onClick={handleDelete}
              sx={{ color: 'error.main' }}
            >
              <DeleteIcon sx={{ mr: 1, fontSize: 20 }} />
              Eliminar
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Box>
  )
}

export default UserTableRow
