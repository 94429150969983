import React from 'react'
import { Box, Link, Typography } from '@mui/material'
import { useDropzone } from 'react-dropzone'

const CSVUploader = ({ handleFileUpload, templateLink }) => {
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0]
    if (file && (file.type === 'text/csv' || file.name.endsWith('.csv'))) {
      const reader = new FileReader()
      reader.onloadend = () => {
        handleFileUpload(reader.result, file.name)
      }
      reader.readAsText(file)
    } else {
      alert('Por favor, sube un archivo CSV.')
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv',
  })

  return (
    <Box>
      <Box
        {...getRootProps()}
        sx={{
          border: '2px dashed #FFA726',
          padding: '16px',
          textAlign: 'center',
          borderRadius: '8px',
          bgcolor: isDragActive ? '#FFF3E0' : '#FFF',
          cursor: 'pointer',
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Box mt={3} mb={3}>
            <Typography sx={{ 
              fontFamily: 'Inter',
              fontSize: { xs: '0.875rem', sm: '0.9375rem', md: '0.9375rem' }
            }} color="primary">
              Suelta el archivo aquí...
            </Typography>
          </Box>
        ) : (
          <Box mt={3} mb={3}>
            <Typography sx={{ 
              fontFamily: 'Inter',
              fontSize: { xs: '0.875rem', sm: '0.9375rem', md: '0.9375rem' }
            }}>
              Sube o arrastra tu archivo CSV aquí
            </Typography>
          </Box>
        )}
      </Box>
      <Box mt={2} mb={2}>
        <Typography sx={{ 
          display: 'inline', 
          fontFamily: 'Inter',
          fontSize: { xs: '0.875rem', sm: '0.9375rem', md: '0.9375rem' }
        }}>
          Si no tienes una plantilla, puedes{' '}
          <Link 
            href={templateLink} 
            download
            sx={{ 
              color: '#2196F3',
              textDecoration: 'none',
              fontFamily: 'Inter',
              fontSize: 'inherit',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            descargarla aquí
          </Link>
          .
        </Typography>
      </Box>
    </Box>
  )
}

export default CSVUploader
