import React from 'react'
import { TextField } from '@mui/material'

const ModalTextField = ({ error, helperText, ...props }) => (
  <TextField
    fullWidth
    variant="outlined"
    InputLabelProps={{ style: { color: '#fff' } }}
    InputProps={{
      style: { color: '#fff', backgroundColor: '#263342' },
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: error ? '#f44336' : '#667085',
        },
        '&:hover fieldset': {
          borderColor: error ? '#f44336' : '#667085',
        },
        '&.Mui-focused fieldset': {
          borderColor: error ? '#f44336' : '#F79009',
        },
        height: '40px',
      },
      '& .MuiInputLabel-root': {
        color: error ? '#f44336' : '#fff',
      },
      '& .MuiFormHelperText-root': {
        color: '#f44336',
      },
      '& input': {
        color: '#fff',
      },
    }}
    error={error}
    helperText={helperText}
    {...props}
  />
)

export default ModalTextField
