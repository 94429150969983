import React from 'react'
import { Box, Chip, Typography } from '@mui/material'

import { statusColors, statusText } from '../../../utils/constants'
import { parseIsoDate, parseIsoHour } from '../../../utils/date'
import { truncateString } from '../../../utils/strings'

const EventData = ({ data }) => {
  return (
    // Container Box for Event Data
    <Box
      sx={{
        backgroundColor: '#07181E',
        padding: {
          xs: '16px',
          sm: '20px',
          md: '30px',
        },
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      {/* Title for Event Details */}
      <Typography
        variant="h6"
        sx={{
          color: '#fff',
          marginBottom: {
            xs: '12px',
            sm: '16px'
          },
          fontWeight: 'bold',
          fontSize: {
            xs: '16px',
            sm: '18px',
          },
          textAlign: {
            xs: 'center',
            sm: 'left'
          }
        }}
      >
        Detalles del Evento
      </Typography>

      {/* Divider Line */}
      <Box
        sx={{
          width: '100%',
          height: '1px',
          backgroundColor: '#041E2B',
          marginBottom: {
            xs: '12px',
            sm: '16px'
          },
        }}
      />

      {/* Content Box for Event Fields */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '12px', sm: '16px' }, flexGrow: 1 }}>
        {/* Event Status */}
        <Box
          sx={{
            display: 'flex',
            alignItems: {
              xs: 'center',
              sm: 'flex-start'
            },
            flexDirection: {
              xs: 'column',
              sm: 'row'
            },
            gap: {
              xs: '4px',
              sm: '0'
            }
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: '14px',
                sm: '16px'
              },
              width: {
                xs: 'auto',
                sm: '120px',
              },
              marginBottom: {
                xs: '4px',
                sm: '0',
              },
            }}
          >
            Estado:
          </Typography>
          <Box
            sx={{
              color: '#000',
              padding: '2px 12px',
              borderRadius: '12px',
              fontWeight: 'bold',
              fontSize: {
                xs: '12px',
                sm: '14px'
              },
            }}
          >
            <Chip
              label={statusText[data?.status]}
              color={statusColors[data?.status]}
              sx={{ 
                minWidth: '120px',
                height: '24px', 
                borderRadius: '20px',
                '& .MuiChip-label': {
                  fontSize: {
                    xs: '12px',
                    sm: '14px'
                  },
                  padding: '0 8px',
                  whiteSpace: 'nowrap',
                  overflow: 'visible'
                }
              }}
            />
          </Box>
        </Box>

        {/* Event Start Date */}
        <Box
          sx={{
            display: 'flex',
            alignItems: {
              xs: 'center',
              sm: 'flex-start'
            },
            flexDirection: {
              xs: 'column',
              sm: 'row'
            },
            gap: {
              xs: '4px',
              sm: '0'
            }
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: '14px',
                sm: '16px'
              },
              width: {
                xs: 'auto',
                sm: '120px',
              },
              marginBottom: {
                xs: '4px',
                sm: '0',
              },
            }}
          >
            Fecha Inicio:
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              color: '#98A2B3', 
              fontSize: {
                xs: '14px',
                sm: '16px'
              }
            }}
          >
            {parseIsoDate(data?.start_at)}, {parseIsoHour(data?.start_hour)}hrs
          </Typography>
        </Box>

        {/* Event End Date */}
        <Box
          sx={{
            display: 'flex',
            alignItems: {
              xs: 'center',
              sm: 'flex-start'
            },
            flexDirection: {
              xs: 'column',
              sm: 'row'
            },
            gap: {
              xs: '4px',
              sm: '0'
            }
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: '14px',
                sm: '16px'
              },
              width: {
                xs: 'auto',
                sm: '120px',
              },
              marginBottom: {
                xs: '4px',
                sm: '0',
              },
            }}
          >
            Fecha Termino:
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              color: '#98A2B3', 
              fontSize: {
                xs: '14px',
                sm: '16px'
              }
            }}
          >
            {parseIsoDate(data?.end_at)}, {parseIsoHour(data?.end_hour)}hrs
          </Typography>
        </Box>

        {/* Event Address */}
        <Box
          sx={{
            display: 'flex',
            alignItems: {
              xs: 'center',
              sm: 'flex-start'
            },
            flexDirection: {
              xs: 'column',
              sm: 'row'
            },
            gap: {
              xs: '4px',
              sm: '0'
            }
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: '14px',
                sm: '16px'
              },
              width: {
                xs: 'auto',
                sm: '120px',
              },
              marginBottom: {
                xs: '4px',
                sm: '0',
              },
            }}
          >
            Dirección:
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              color: '#98A2B3', 
              fontSize: {
                xs: '14px',
                sm: '16px'
              },
              textAlign: {
                xs: 'center',
                sm: 'left'
              }
            }}
          >
            {truncateString(data?.address, 34)}
          </Typography>
        </Box>

        {/* Event Venue */}
        <Box
          sx={{
            display: 'flex',
            alignItems: {
              xs: 'center',
              sm: 'flex-start'
            },
            flexDirection: {
              xs: 'column',
              sm: 'row'
            },
            gap: {
              xs: '4px',
              sm: '0'
            }
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: '14px',
                sm: '16px'
              },
              width: {
                xs: 'auto',
                sm: '120px',
              },
              marginBottom: {
                xs: '4px',
                sm: '0',
              },
            }}
          >
            Venue:
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              color: '#98A2B3', 
              fontSize: {
                xs: '14px',
                sm: '16px'
              },
              textAlign: {
                xs: 'center',
                sm: 'left'
              }
            }}
          >
            {truncateString(data?.place, 34)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default EventData
