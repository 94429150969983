// hooks/events/useListEventItemsData.js

import { useCallback, useEffect, useState } from 'react'
import { listEventItems } from 'services/events-service'

const useListEventItems = (eventId, type) => {
  const [eventItems, setEventItems] = useState([])
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false) // Para actualizaciones posteriores
  const [error, setError] = useState(null)

  const fetchEventItems = useCallback(async () => {
    try {
      if (initialLoading) {
        setInitialLoading(true)
      } else {
        setLoading(true)
      }
      const data = await listEventItems(eventId, type)
      setEventItems(data?.listEventItems || [])
      setError(null)
    } catch (err) {
      setError(err)
    } finally {
      setInitialLoading(false)
      setLoading(false)
    }
  }, [eventId, type, initialLoading])

  useEffect(() => {
    fetchEventItems()
  }, [fetchEventItems])

  const refreshData = useCallback(() => {
    fetchEventItems()
  }, [fetchEventItems])

  return { eventItems, initialLoading, loading, error, refreshData }
}

export default useListEventItems
