export const countries = [
  { 
    code: 'CL', 
    name: 'Chile', 
    prefix: '+56', 
    mobilePrefix: '9',
    formats: [
      {
        format: 'XXXX XXXX',
        example: '+56 9 1234 5678',
        description: 'Número móvil'
      }
    ]
  },
  { 
    code: 'AR', 
    name: 'Argentina', 
    prefix: '+54', 
    mobilePrefix: '9',
    formats: [
      {
        format: 'XX XXXX XXXX',
        example: '+54 9 11 1234 5678',
        description: 'Buenos Aires y AMBA'
      },
      {
        format: 'XX XXX XXXX',
        example: '+54 9 351 123 4567',
        description: 'Interior del país'
      }
    ]
  },
  { 
    code: 'PE', 
    name: 'Perú', 
    prefix: '+51', 
    mobilePrefix: '9',
    formats: [
      {
        format: 'XXXXXXXXX',
        example: '+51 912345678',
        description: 'Número móvil'
      }
    ]
  },
  { 
    code: 'CO', 
    name: 'Colombia', 
    prefix: '+57', 
    mobilePrefix: '3',
    formats: [
      {
        format: 'XXXXXXXXXX',
        example: '+57 3123456789',
        description: 'Número móvil'
      }
    ]
  },
  { 
    code: 'MX', 
    name: 'México', 
    prefix: '+52', 
    mobilePrefix: '1',
    formats: [
      {
        format: 'XX XXXX XXXX',
        example: '+52 1 55 1234 5678',
        description: 'Ciudad de México y área metropolitana'
      },
      {
        format: 'XXX XXX XXXX',
        example: '+52 1 999 123 4567',
        description: 'Resto del país'
      }
    ]
  },
  { 
    code: 'BR', 
    name: 'Brasil', 
    prefix: '+55', 
    mobilePrefix: '',
    formats: [
      {
        format: 'XX XXXXX XXXX',
        example: '+55 11 91234 5678',
        description: 'São Paulo'
      },
      {
        format: 'XX XXXX XXXX',
        example: '+55 92 9123 4567',
        description: 'Otros estados'
      }
    ]
  },
  { 
    code: 'UY', 
    name: 'Uruguay', 
    prefix: '+598', 
    mobilePrefix: '9',
    formats: [
      {
        format: 'X XXXX XXXX',
        example: '+598 9 1234 5678',
        description: 'Número móvil'
      }
    ]
  },
  { 
    code: 'PY', 
    name: 'Paraguay', 
    prefix: '+595', 
    mobilePrefix: '9',
    formats: [
      {
        format: 'X XXXX XXXX',
        example: '+595 9 1234 5678',
        description: 'Número móvil'
      }
    ]
  },
  { 
    code: 'BO', 
    name: 'Bolivia', 
    prefix: '+591', 
    mobilePrefix: '7',
    formats: [
      {
        format: 'XXXX XXXX',
        example: '+591 7 1234 5678',
        description: 'Número móvil'
      }
    ]
  },
  { 
    code: 'EC', 
    name: 'Ecuador', 
    prefix: '+593', 
    mobilePrefix: '9',
    formats: [
      {
        format: 'XX XXX XXXX',
        example: '+593 9 987 654 321',
        description: 'Número móvil'
      }
    ]
  },
  { 
    code: 'VE', 
    name: 'Venezuela', 
    prefix: '+58', 
    mobilePrefix: '4',
    formats: [
      {
        format: 'XXX XXX XXXX',
        example: '+58 4 123 456 7890',
        description: 'Número móvil'
      }
    ]
  },
  { 
    code: 'CR', 
    name: 'Costa Rica', 
    prefix: '+506', 
    mobilePrefix: '',
    formats: [
      {
        format: 'XXXX XXXX',
        example: '+506 8123 4567',
        description: 'Número móvil'
      }
    ]
  },
  { 
    code: 'PA', 
    name: 'Panamá', 
    prefix: '+507', 
    mobilePrefix: '6',
    formats: [
      {
        format: 'XXXX XXXX',
        example: '+507 6123 4567',
        description: 'Número móvil'
      }
    ]
  },
  {
    code: 'US',
    name: 'Estados Unidos',
    prefix: '+1',
    mobilePrefix: '',
    formats: [
      {
        format: 'XXX XXX XXXX',
        example: '+1 212 555 0123',
        description: 'Todos los números'
      }
    ]
  },
  {
    code: 'CA',
    name: 'Canadá',
    prefix: '+1',
    mobilePrefix: '',
    formats: [
      {
        format: 'XXX XXX XXXX',
        example: '+1 416 555 0123',
        description: 'Todos los números'
      }
    ]
  },
  {
    code: 'ES',
    name: 'España',
    prefix: '+34',
    mobilePrefix: '6',
    formats: [
      {
        format: 'XXX XXX XXX',
        example: '+34 612 345 678',
        description: 'Móvil (prefijo 6)'
      },
      {
        format: 'XXX XXX XXX',
        example: '+34 712 345 678',
        description: 'Móvil (prefijo 7)'
      }
    ]
  },
  {
    code: 'GB',
    name: 'Reino Unido',
    prefix: '+44',
    mobilePrefix: '7',
    formats: [
      {
        format: 'XXXX XXXXXX',
        example: '+44 7123 456789',
        description: 'Número móvil'
      }
    ]
  },
  {
    code: 'FR',
    name: 'Francia',
    prefix: '+33',
    mobilePrefix: '6',
    formats: [
      {
        format: 'XX XX XX XX XX',
        example: '+33 6 12 34 56 78',
        description: 'Móvil (prefijo 6)'
      },
      {
        format: 'XX XX XX XX XX',
        example: '+33 7 12 34 56 78',
        description: 'Móvil (prefijo 7)'
      }
    ]
  },
  {
    code: 'DE',
    name: 'Alemania',
    prefix: '+49',
    mobilePrefix: '1',
    formats: [
      {
        format: 'XXX XXXXXXX',
        example: '+49 1512 3456789',
        description: 'Número móvil'
      }
    ]
  },
  {
    code: 'IT',
    name: 'Italia',
    prefix: '+39',
    mobilePrefix: '3',
    formats: [
      {
        format: 'XXX XXX XXXX',
        example: '+39 312 345 6789',
        description: 'Número móvil'
      }
    ]
  },
  {
    code: 'PT',
    name: 'Portugal',
    prefix: '+351',
    mobilePrefix: '9',
    formats: [
      {
        format: 'XXX XXX XXX',
        example: '+351 912 345 678',
        description: 'Número móvil'
      }
    ]
  },
  {
    code: 'NL',
    name: 'Países Bajos',
    prefix: '+31',
    mobilePrefix: '6',
    formats: [
      {
        format: 'XX XXX XXXX',
        example: '+31 612 345 678',
        description: 'Número móvil'
      }
    ]
  }
]

// Regex para validar el formato de teléfono según el país y formato específico
export const getPhoneRegex = (countryCode, formatIndex = 0) => {
  const country = countries.find(c => c.code === countryCode)
  if (!country || !country.formats[formatIndex]) return null

  // Convertir el formato a regex
  const format = country.formats[formatIndex].format
    .replace(/\(/g, '\\(')
    .replace(/\)/g, '\\)')
    .replace(/\s/g, '\\s?')
    .replace(/X/g, '[0-9]')

  return new RegExp(`^${country.prefix}\\s?${country.mobilePrefix ? country.mobilePrefix + '\\s?' : ''}${format}$`)
}

// Función para formatear el número según el país y formato específico
export const formatPhoneNumber = (countryCode, formatIndex = 0) => {
  const country = countries.find(c => c.code === countryCode)
  if (!country || !country.formats[formatIndex]) return ''
  return `${country.prefix} ${country.mobilePrefix ? country.mobilePrefix + ' ' : ''}${country.formats[formatIndex].format}`
}

// Función para obtener el ejemplo de número según el país y formato específico
export const getPhoneExample = (countryCode, formatIndex = 0) => {
  const country = countries.find(c => c.code === countryCode)
  if (!country || !country.formats[formatIndex]) return ''
  return country.formats[formatIndex].example
}

// Función para obtener la descripción del formato
export const getFormatDescription = (countryCode, formatIndex = 0) => {
  const country = countries.find(c => c.code === countryCode)
  if (!country || !country.formats[formatIndex]) return ''
  return country.formats[formatIndex].description
}

// Función para obtener todos los formatos disponibles para un país
export const getCountryFormats = (countryCode) => {
  const country = countries.find(c => c.code === countryCode)
  if (!country) return []
  return country.formats
} 