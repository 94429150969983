import { useEffect, useState } from 'react'
import { batchCreateEventItem, createEvent } from 'services/events-service'

const useCreateEvent = ({ event, tickets, bar }) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    if (!event) return

    const createEventData = async () => {
      try {
        setLoading(true)
        setError(null)
        const eventData = await createEvent(event)
        await batchCreateEventItem(tickets, eventData?.createEvent?.id)
        if (bar.length) {
          await batchCreateEventItem(bar, eventData?.createEvent?.id)
        }
        setData(eventData)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
        setCompleted(true)
      }
    }

    createEventData()
  }, [event])

  return { data, loading, error, completed }
}

export default useCreateEvent
