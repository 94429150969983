// components/organisms/ImageSection.jsx

import React from 'react'
import { Box } from '@mui/material'
import { StyledImage } from '../../atoms'

const ImageSection = ({ data }) => {
  const imageUrl = data?.image || 'default_image_url'

  return (
    <Box
      sx={{
        borderRadius: { xs: '12px', sm: '16px', md: '18px' },
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#1A1A1A',
      }}
    >
      <StyledImage
        src={imageUrl}
        alt="Imagen del evento"
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
    </Box>
  )
}

export default ImageSection
