import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { useMangoController } from '../../../context'
import { ButtonStyle } from './Button.style'

const Button = forwardRef(
  (
    { color = 'white', variant = 'contained', size = 'medium', circular = false, iconOnly = false, children, ...rest },
    ref
  ) => {
    const [controller] = useMangoController()
    const { darkMode } = controller

    return (
      <ButtonStyle
        {...rest}
        ref={ref}
        color="primary"
        variant={variant === 'gradient' ? 'contained' : variant}
        size={size}
        ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
      >
        {children}
      </ButtonStyle>
    )
  }
)

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['text', 'contained', 'outlined', 'gradient']),
  color: PropTypes.oneOf(['white', 'primary', 'secondary', 'info', 'success', 'warning', 'error', 'light', 'dark']),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Button
