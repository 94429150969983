import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

import { useTotalSalesData } from '../../../hooks/eventDetails/useTotalSalesData'
import { LoadingSpinner } from '../../atoms'

const TotalSales = ({ eventId }) => {
  const { netSales, ticketSales, barSales, loading, error } = useTotalSalesData({ eventId })
  const formatNumber = (number) => number.toLocaleString().replaceAll(',', '.')

  if (loading) return <LoadingSpinner />

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          textAlign: 'center',
          backgroundColor: '#07181E',
          borderRadius: '10px',
        }}
      >
        <Typography variant="h6" color="error">
          {error.message}
        </Typography>
        <Typography variant="body1">
          Por favor, intenta refrescar la página o contacta a soporte si el problema persiste.
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        backgroundColor: '#07181E',
        padding: {
          xs: '16px',
          sm: '20px',
          md: '30px',
        },
        borderRadius: '10px',
        color: '#fff',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      {/* Título de Total Ventas */}
      <Typography
        variant="h5"
        sx={{
          color: '#fff',
          fontSize: {
            xs: '16px',
            sm: '18px',
            lg: '18px',
          },
          fontWeight: 'bold',
          marginBottom: {
            xs: '8px',
            sm: '12px'
          },
          textAlign: {
            xs: 'center',
            sm: 'left'
          }
        }}
      >
        Total Ventas
      </Typography>

      {/* Línea divisora debajo del título */}
      <Box
        sx={{
          width: '100%',
          height: '1px',
          backgroundColor: '#041E2B',
          marginBottom: {
            xs: '12px',
            sm: '16px',
            lg: '20px',
          },
        }}
      />

      {/* Contenedor para Ventas Neto y Monto Total */}
      <Box
        sx={{
          marginBottom: {
            xs: '12px',
            sm: '16px',
            lg: '24px',
          },
          textAlign: {
            xs: 'center',
            sm: 'left'
          }
        }}
      >
        {/* Texto de Ventas Neto */}
        <Typography
          variant="body1"
          sx={{
            color: '#f4a261',
            fontSize: {
              xs: '12px',
              sm: '14px'
            },
            fontWeight: '500',
            marginBottom: '4px',
          }}
        >
          Ventas Neto
        </Typography>

        {/* Monto Total en blanco */}
        <Typography
          variant="h3"
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: {
              xs: '24px',
              sm: '28px',
              md: '36px',
            },
          }}
        >
          ${formatNumber(netSales)}
        </Typography>
      </Box>

      {/* Línea divisora encima de Ventas Tickets y Ventas Barra */}
      <Box
        sx={{
          width: '100%',
          height: '1px',
          backgroundColor: '#041E2B',
          marginBottom: {
            xs: '12px',
            sm: '16px',
            lg: '20px',
          },
        }}
      />

      {/* Divisiones de Ventas Tickets y Barra */}
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingRight: {
              sm: '20px',
              lg: '20px',
            },
            borderRight: {
              sm: '1px solid #041E2B',
              lg: '1px solid #041E2B',
            },
            marginBottom: {
              xs: '12px',
              sm: '0',
            },
            textAlign: {
              xs: 'center',
              sm: 'left'
            }
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: '#8796A5',
              fontSize: {
                xs: '14px',
                sm: '16px'
              },
              marginBottom: '4px',
            }}
          >
            Ventas Tickets
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: '18px',
                sm: '22px'
              },
              fontWeight: '500',
              color: '#fff',
              marginBottom: '0px',
            }}
          >
            ${formatNumber(ticketSales)}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: {
              sm: '20px',
              lg: '20px',
            },
            textAlign: {
              xs: 'center',
              sm: 'left'
            }
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: '#8796A5',
              fontSize: {
                xs: '14px',
                sm: '16px'
              },
              marginBottom: '4px',
            }}
          >
            Ventas Barra
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: '18px',
                sm: '22px'
              },
              fontWeight: '500',
              color: '#fff',
              marginBottom: '0px',
            }}
          >
            ${formatNumber(barSales)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TotalSales
