import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '../../atoms'
import money from '../../../assets/mango/icons/money.png'
import mango from '../../../assets/mango/icons/mango.png'
import ticket from '../../../assets/mango/icons/ticket.png'

function formatCurrency(value) {
  return '$' + value.toLocaleString().replaceAll(',', '.')
}

function formatPercentage(value) {
  return `${value}%`
}

function formatNumber(value) {
  return value.toLocaleString().replaceAll(',', '.')
}

function formatValue(value, format) {
  if (value === 0) {
    return '-'
  }
  if (format === 'PERCENTAGE') return formatPercentage(value)
  if (format === 'NUMBER') return formatNumber(value)
  return formatCurrency(value)
}

function getIcon(icon) {
  switch (icon) {
    case 'money':
      return money
    case 'mango':
      return mango
    case 'ticket':
      return ticket
    default:
      return mango
  }
}

function StatisticInfo({ title, value, format, icon }) {
  return (
    <>
      <Typography 
        variant="body2" 
        sx={{
          color: (theme) => theme.palette.text.secondary,
          fontSize: { xs: '0.875rem', sm: '0.875rem', md: '0.875rem' },
          fontWeight: 500,
          mb: { xs: 1, sm: 1, md: 0.5 }
        }}
      >
        {title}
      </Typography>
      <Box 
        display="flex" 
        flexDirection="row" 
        justifyContent="space-between" 
        alignItems="center"
        sx={{
          gap: { xs: 2, sm: 2, md: 1 }
        }}
      >
        <Typography 
          variant="h4" 
          sx={{
            color: (theme) => theme.palette.text.primary,
            fontSize: { xs: '1.5rem', sm: '1.5rem', md: '1.5rem' },
            fontWeight: 600,
            lineHeight: 1.2
          }}
        >
          {formatValue(value, format)}
        </Typography>
        <Box
          component="img"
          src={getIcon(icon)}
          alt="statistic-icon"
          sx={{
            width: { xs: '32px', sm: '32px', md: '32px' },
            height: { xs: '32px', sm: '32px', md: '32px' },
            objectFit: 'contain'
          }}
        />
      </Box>
    </>
  )
}

StatisticInfo.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default StatisticInfo
