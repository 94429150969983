import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { transformDate, transformTimes } from './date'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

export function buildEventData({
  id,
  name,
  description,
  address,
  place,
  image,
  start_at,
  end_at,
  start_hour,
  end_hour,
}) {
  const sanitizedStartAt = start_at ? start_at.replace(' UTC', '') : ''
  const sanitizedEndAt = end_at ? end_at.replace(' UTC', '') : ''

  const parsedStartDate = dayjs(sanitizedStartAt, 'YYYY-MM-DD HH:mm:ss ZZ')
  const parsedEndDate = dayjs(sanitizedEndAt, 'YYYY-MM-DD HH:mm:ss ZZ')

  const validStartDate = parsedStartDate.isValid() ? parsedStartDate.tz('UTC') : null
  const validEndDate = parsedEndDate.isValid() ? parsedEndDate.tz('UTC') : null

  return {
    id,
    name,
    description,
    date: transformDate(start_at),
    time: transformTimes(start_hour, end_hour),
    address: address,
    image,
    rawStartHour: dayjs(start_hour, 'HH:mm'),
    rawEndHour: dayjs(end_hour, 'HH:mm'),
    rawStartDate: validStartDate,
    rawEndDate: validEndDate,
    venue: place,
  }
}

function convertTimeToISOString(timeString, dateString) {
  const [hours, minutes] = timeString.split(':').map(Number)

  const [datePart] = dateString.split(' ')
  const [year, month, day] = datePart.split('-').map(Number)

  const date = new Date(Date.UTC(year, month - 1, day, hours, minutes, 0, 0))

  return date.toISOString()
}

export function transformEventData({
  id,
  address,
  description,
  image,
  name,
  rawEndDate,
  rawEndHour,
  rawStartDate,
  rawStartHour,
  venue,
}) {
  const startHour = rawStartHour.format('HH:mm:00')
  const endHour = rawEndHour.format('HH:mm:00')
  const startDate = rawStartDate.format('YYYY-MM-DD HH:mm:ss')
  const endDate = rawEndDate.format('YYYY-MM-DD HH:mm:ss')
  return {
    id,
    name,
    place: venue,
    description,
    image,
    start_at: rawStartDate.toISOString(),
    end_at: rawEndDate.toISOString(),
    start_hour: convertTimeToISOString(startHour, startDate),
    end_hour: convertTimeToISOString(endHour, endDate),
    address,
  }
}
