import { changeCashierPassword } from '../../services/cashiers-service'
import { useState } from 'react'

const useChangePassword = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const changePassword = async (id, password) => {
    try {
      setLoading(true)
      setError(null)
      await changeCashierPassword(id, password)
    } catch (err) {
      setError(err)
      throw err
    } finally {
      setLoading(false)
    }
  }

  return { changePassword, loading, error }
}

export default useChangePassword
