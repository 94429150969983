import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

import { TicketControls } from '../../molecules'

const BarItem = ({ ticketNumber, name, price, image, count, onIncrement, onDecrement }) => (
  <Grid
    container
    mt={1}
    pl={2}
    pb={2}
    pt={2}
    sx={{ backgroundColor: '#092838', borderRadius: '10px' }}
    alignItems="center"
  >
    {image && (
      <Grid item xs={2} container justifyContent="center">
        <Box
          sx={{
            width: '30px',
            height: '30px',
            borderRadius: '5px',
            overflow: 'hidden',
          }}
        >
          <img
            src={image}
            alt={`Imagen de ${name}`}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
      </Grid>
    )}
    <Grid item xs={image ? 5 : 8} container alignItems="center">
      <Box ml={1}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          {name ? name : `Trago N°${ticketNumber}`}
        </Typography>
        <Typography variant="body2" sx={{ color: '#fb8c00' }}>
          {price ? `$ ${parseInt(price).toLocaleString('es-CL')}` : '-'}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={image ? 4 : 3} container justifyContent="flex-end" alignItems="center" ml={1} pr={0}>
      <TicketControls count={count} onIncrement={onIncrement} onDecrement={onDecrement} />
    </Grid>
  </Grid>
)

export default BarItem
