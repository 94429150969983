export const eventDataErrors = {
  REQUIRED_NAME: 'El nombre es requerido',
  REQUIRED_ADDRESS: 'La dirección es requerida',
  REQUIRED_VENUE: 'El venue es requerido',
  REQUIRED_DESCRIPTION: 'La descripción es requerida',
  MIN_LENGTH_DESCRIPTION: 'La descripción debe tener al menos 10 caracteres',
  REQUIRED_START_DATE: 'La fecha de inicio es requerida',
  INVALID_START_DATE: 'Fecha de inicio inválida',
  INVALID_END_DATE: 'Fecha de término inválida',
  INVALID_START_END_DATE: 'La fecha de inicio debe ser antes de la fecha de término',
  REQUIRED_START_HOUR: 'La hora de inicio es requerida',
  INVALID_START_HOUR: 'Hora de inicio inválida',
  REQUIRED_END_HOUR: 'La hora de término es requerida',
  INVALID_END_HOUR: 'Hora de término inválida',
  INVALID_START_END_HOUR: 'La hora de inicio debe ser antes de la hora de término',
  INVALID_END_START_HOUR: 'La hora de término debe ser después de la hora de inicio',
  INVALID_END_START_DATE: 'La fecha de término debe ser después de la fecha de inicio',
  INVALID_FORMAT_HOUR: 'Hora no válida. Formato esperado: HH:MM',
  REQUIRED_IMAGE: 'La imagen es requerida',
  MAX_SIZE_IMAGE: 'El tamaño de la imagen excede el máximo permitido',
  SAME_START_END_DATETIME: 'La fecha de inicio y término no pueden ser iguales',
}
