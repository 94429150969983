// components/pages/EventDetailPage.jsx

import React from 'react'
import { useParams } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { es } from 'date-fns/locale'

import { DashboardLayout, DashboardNavbar, EventDetailTemplate } from '../../templates'
import theme from './theme'
import useFetchEventDataById from '../../../hooks/events/useFetchEventDataById'
import { LoadingSpinner } from '../../atoms'
import { Typography } from '@mui/material'

const EventDetailPage = () => {
  const { eventId } = useParams()

  const [initialLoad, setInitialLoad] = React.useState(false)
  const { data, loading, error, refreshData } = useFetchEventDataById(eventId)
  if (!initialLoad) {
    refreshData()
    setInitialLoad(true)
  }

  if (loading) return <LoadingSpinner />

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          textAlign: 'center',
          backgroundColor: 'inherit',
          borderRadius: '10px',
        }}
      >
        <Typography variant="h6" color="error">
          {error.message}
        </Typography>
        <Typography variant="body1">
          Por favor, intenta refrescar la página o contacta a soporte si el problema persiste.
        </Typography>
      </Box>
    )
  }

  return (
    <DashboardLayout>
      <Box py={{ xs: 2, sm: 2, md: 3 }}>
        <DashboardNavbar absolute showBackButton title={data?.name ? `Detalles de ${data?.name}` : 'Detalles de'} />
      </Box>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
          <EventDetailTemplate data={data} eventId={eventId} />
        </LocalizationProvider>
      </ThemeProvider>
    </DashboardLayout>
  )
}

export default React.memo(EventDetailPage)
