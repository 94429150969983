import React from 'react'
import { CircularProgress } from '@mui/material'

import { Box } from '../index'

const LoadingSpinner = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
    <CircularProgress />
  </Box>
)

export default LoadingSpinner
