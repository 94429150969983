import React from 'react'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { Doughnut } from 'react-chartjs-2'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Chart as ChartJS, registerables } from 'chart.js'

import { useTicketSalesData } from '../../../hooks/eventDetails/useTicketSalesData'
import { LoadingSpinner } from '../../atoms'

ChartJS.register(...registerables)

const TicketSales = ({ eventId }) => {
  const { ticketData, loading, error } = useTicketSalesData({ eventId })

  if (loading) return <LoadingSpinner />

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          textAlign: 'center',
          backgroundColor: '#07181E',
          borderRadius: '10px',
        }}
      >
        <Typography variant="h6" color="error">
          {error.message}
        </Typography>
        <Typography variant="body1">
          Por favor, intenta refrescar la página o contacta a soporte si el problema persiste.
        </Typography>
      </Box>
    )
  }

  const allSoldZero = ticketData.every((ticket) => ticket.sold === 0)

  const generateColors = (num) => {
    const colors = []
    for (let i = 0; i < num; i++) {
      const hue = (i * 137.508) % 360
      colors.push(`hsl(${hue}, 70%, 50%)`)
    }
    return colors
  }

  const assignedColors = generateColors(ticketData.length)

  // Configurar los colores del gráfico de dona
  const chartColors = allSoldZero
    ? ['#B0B0B0'] // Color gris cuando no hay ventas
    : assignedColors // Colores asignados cuando hay ventas

  // Configurar los datos para el gráfico de dona
  const data = allSoldZero
    ? {
        labels: ['Sin Ventas'],
        datasets: [
          {
            data: [1],
            backgroundColor: ['#B0B0B0'], // Color gris
            borderWidth: 0,
          },
        ],
      }
    : {
        labels: ticketData.map((ticket) => ticket.name),
        datasets: [
          {
            data: ticketData.map((ticket) => ticket.sold.toLocaleString().replaceAll(',', '.')),
            backgroundColor: chartColors,
            borderWidth: 0,
          },
        ],
      }

  // Opciones de configuración para el gráfico
  const options = {
    plugins: {
      legend: {
        display: false, // Ocultar la leyenda
      },
      tooltip: {
        enabled: !allSoldZero, // Habilitar tooltips solo si hay ventas
      },
    },
    cutout: '70%', // Tamaño del agujero central
    maintainAspectRatio: false, // Permitir ajustar el tamaño del gráfico
  }

  return (
    <Box
      sx={{
        backgroundColor: '#07181E',
        padding: {
          xs: '16px',
          sm: '20px',
          md: '30px',
        },
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxHeight: {
          xs: 'auto',
          sm: '487px'
        },
        width: '100%',
      }}
    >
      {/* Título con botón de más opciones */}
      <Box
        sx={{
          flexShrink: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: {
            xs: '12px',
            sm: '16px'
          },
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: {
              xs: '16px',
              sm: '18px',
            },
            textAlign: {
              xs: 'center',
              sm: 'left'
            },
            flex: 1
          }}
        >
          Ventas de Ticket
        </Typography>
        <IconButton sx={{ color: '#fff', padding: { xs: '4px', sm: '8px' } }}>
          <MoreHorizIcon sx={{ fontSize: { xs: '20px', sm: '24px' } }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          flexShrink: 0,
          width: '100%',
          height: '1px',
          backgroundColor: '#041E2B',
          marginBottom: {
            xs: '12px',
            sm: '16px'
          },
        }}
      />

      {/* Contenedor principal ajustado */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: {
            xs: 'center',
            md: 'flex-start'
          },
          flexGrow: 1,
          overflow: 'hidden',
        }}
      >
        {/* Gráfico de Dona */}
        <Box
          sx={{
            flexShrink: 0,
            flexBasis: {
              xs: 'auto',
              md: '40%',
            },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: {
              xs: '16px',
              md: '0',
            },
            marginRight: {
              md: '16px',
            },
            width: {
              xs: '100%',
              md: 'auto'
            }
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: {
                xs: '140px',
                sm: '180px',
                md: '200px',
              },
              height: {
                xs: '140px',
                sm: '180px',
                md: '200px',
              },
            }}
          >
            <Doughnut data={data} options={options} />
          </Box>
        </Box>

        {/* Detalles de los tickets con scroll */}
        <Box
          sx={{
            flexGrow: 1,
            maxHeight: {
              xs: '200px',
              sm: '100%'
            },
            overflowY: 'auto',
            marginLeft: {
              md: '16px',
            },
            width: '100%',
            display: 'flex',
            justifyContent: {
              xs: 'center',
              md: 'flex-start'
            }
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: '8px',
            width: {
              xs: '80%',
              sm: '100%'
            }
          }}>
            {ticketData.map((ticket, index) => (
              <Box
                key={index}
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: {
                    xs: 'center',
                    sm: 'space-between'
                  },
                  padding: '4px 0'
                }}
              >
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  flex: 1,
                  minWidth: 0,
                  justifyContent: {
                    xs: 'center',
                    sm: 'flex-start'
                  }
                }}>
                  {/* Cuadrado de color */}
                  <Box
                    sx={{
                      minWidth: '12px',
                      width: '12px',
                      height: '12px',
                      backgroundColor: assignedColors[index],
                      marginRight: '8px',
                      flexShrink: 0,
                    }}
                  />
                  {/* Tooltip que muestra el nombre completo al pasar el mouse */}
                  <Tooltip title={ticket.name}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#98A2B3',
                        fontWeight: 'bold',
                        fontSize: {
                          xs: '12px',
                          sm: '14px'
                        },
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        minWidth: 0,
                        flex: 1,
                        textAlign: {
                          xs: 'center',
                          sm: 'left'
                        }
                      }}
                    >
                      {ticket.name}
                    </Typography>
                  </Tooltip>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: '#fff',
                    fontSize: {
                      xs: '14px',
                      sm: '16px'
                    },
                    fontWeight: 'bold',
                    marginLeft: '16px',
                    flexShrink: 0,
                    textAlign: {
                      xs: 'center',
                      sm: 'right'
                    }
                  }}
                >
                  {ticket.sold.toLocaleString().replaceAll(',', '.')}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TicketSales
