import { useCallback, useState } from 'react'
import { batchDeleteEventItems } from '../../services/events-service'

const useDeleteEventItem = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [completed, setCompleted] = useState(false)

  const deleteItems = useCallback(async (eventId, itemIds) => {
    setLoading(true)
    setError(null)
    setCompleted(false)
    try {
      const response = await batchDeleteEventItems(itemIds, eventId)
      setData(response)
      setCompleted(true)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }, [])

  return { data, loading, error, completed, deleteItems }
}

export default useDeleteEventItem
