import React, { useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

const DragAndDropBox = ({ setImageData, data }) => {
  const [image, setImage] = useState(null)
  const [name, setName] = useState('')
  const [crop, setCrop] = useState({
    unit: 'px',
    width: 500,
    height: 500,
    x: 0,
    y: 0,
  })
  const [openModal, setOpenModal] = useState(false)
  const [imageRef, setImageRef] = useState(null)

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0]
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImage(reader.result)
        setName(file.name)
        setOpenModal(true)
      }
      reader.readAsDataURL(file)
    } else {
      alert('Por favor, sube un archivo de imagen.')
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    return canvas.toDataURL('image/jpeg')
  }

  const handleCompleteCrop = () => {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = getCroppedImg(imageRef, crop)
      setImageData({ croppedImage, name })
      setOpenModal(false)
    }
  }

  return (
    <Box>
      <Box
        {...getRootProps()}
        sx={{
          border: '2px dashed #FFA726',
          padding: '16px',
          textAlign: 'center',
          borderRadius: '8px',
          bgcolor: isDragActive ? '#FFF3E0' : '#FFF',
          cursor: 'pointer',
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Box mt={3} mb={3}>
            <Typography color="primary">Suelta los archivos aquí...</Typography>
          </Box>
        ) : (
          <Box mt={3} mb={3}>
            <Typography>{data?.rawImageName || 'Sube o arrastra tu afiche aquí'}</Typography>
          </Box>
        )}
      </Box>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="xl"
        fullWidth
        sx={{ '& .MuiDialog-paper': { minHeight: '60vh', maxHeight: '80vh', minWidth: '60vh', maxWidth: '80vh' } }}
      >
        <DialogTitle>Corta la imagen</DialogTitle>
        <DialogContent
          dividers
          style={{
            height: '100%',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          {image && (
            <ReactCrop crop={crop} onChange={(c) => setCrop(c)} locked>
              <img
                src={image}
                ref={setImageRef}
                style={{
                  width: '500px',
                  objectFit: 'contain',
                  display: 'block',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                alt="To be cropped"
              />
            </ReactCrop>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCompleteCrop} color="primary">
            Cortar y Guardar
          </Button>
          <Button onClick={() => setOpenModal(false)} color="secondary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default DragAndDropBox
