import Axios from 'axios'

const API_URL = process.env.REACT_APP_MANGO_DASHBOARD_API_URL

const ME_QUERY = `
  query Me {
    me {
      user {
        id
        firstname
        lastname
        email
        phone
        birth_date
        roles
        picture
      }
    }
  }
`;

class GraphqlService {
  constructor() {
    this._axios = Axios.create({
      baseURL: API_URL,
      withCredentials: true,
    })

    this._axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true

          try {
            const newToken = await this.handleTokenRefresh()
            if (newToken) {
              originalRequest.headers['Authorization'] = `Bearer ${newToken}`
              return this._axios(originalRequest)
            }
            throw new Error('AUTH_REQUIRED')
          } catch (err) {
            throw new Error('AUTH_REQUIRED')
          }
        }

        return Promise.reject(error)
      }
    )
  }

  async verifyAuth() {
    try {
      const response = await this.request({
        query: `
          query VerifyAuth {
              verifyAuth {
                  isAuthenticated
                  message
              }
          }
        `,
        operationName: 'VerifyAuth',
      })

      return response.verifyAuth || { isAuthenticated: false, message: 'Not authenticated' }
    } catch (error) {
      console.error('Auth verification error:', error)
      return { isAuthenticated: false, message: 'Authentication error' }
    }
  }

  async logout() {
    try {
      document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      return true
    } catch (error) {
      console.error('Logout error:', error)
      return false
    }
  }

  async request({ query, variables, operationName }) {
    try {
      const res = await this._axios.post(
        '/graphql',
        {
          query,
          variables,
          operationName,
        },
        {
          headers: {
            'x-user-platform': 'web',
          },
        }
      )

      if (res?.data?.errors) {
        const errorMessage = res.data.errors.map((err) => err.message).join(', ')
        throw new Error(errorMessage)
      }

      return res.data.data
    } catch (err) {
      throw err
    }
  }

  async handleTokenRefresh() {
    try {
      return await this.refreshToken()
    } catch (error) {
      return null
    }
  }

  async refreshToken() {
    try {
      const response = await this._axios.post('/graphql', {
        query: `
          mutation RefreshToken {
            refreshToken {
              success
              token
              message
            }
          }
        `,
        operationName: 'RefreshToken',
      })

      const { success, token, message } = response.data.data.refreshToken

      if (!success) {
        throw new Error(message)
      }

      return token
    } catch (error) {
      throw error
    }
  }

  async getMe() {
    try {
      const response = await this.request({
        query: ME_QUERY,
        operationName: 'Me',
        variables: {}
      });
      
      console.log('GraphQL Response:', response);
      
      return response.me;
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
  }
}

export default new GraphqlService()
