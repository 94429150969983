import { Navigate, useLocation } from 'react-router-dom'

const ProtectedRoute = ({ isAuthenticated, children }) => {
  const location = useLocation()
  
  // Lista de rutas públicas que no requieren autenticación
  const publicRoutes = ['/', '/auth/login', '/auth/register']
  
  // Si es una ruta pública, permitir acceso sin autenticación
  if (publicRoutes.includes(location.pathname)) {
    return children
  }
  
  // Si no es una ruta pública y no está autenticado, redirigir a login
  if (!isAuthenticated) {
    // Guardamos la URL actual para redirigir después del login
    return <Navigate to={`/auth/login?returnUrl=${encodeURIComponent(location.pathname)}`} replace />
  }

  return children
}

export default ProtectedRoute
