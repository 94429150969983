import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { InputStyle } from './Input.style'

const Input = forwardRef(({ error = false, success = false, disabled = false, ...rest }, ref) => (
  <InputStyle {...rest} ref={ref} ownerState={{ error, success, disabled }} />
))

Input.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Input
