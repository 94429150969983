// src/components/StatisticsCard/index.jsx

import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'

import { ErrorBoundary } from '../../templates'
import { StatisticInfo, StatisticVariation } from '../../molecules'
import { Box } from '../../atoms'

function StatisticsCard({ 
  title, 
  icon, 
  type = 'default', 
  value, 
  format, 
  variations = [] 
}) {
  // Asegurarse de que cada variación tenga un label
  const processedVariations = variations.map(variation => ({
    ...variation,
    label: variation.label || variation.interval || 'Variación'
  }))

  return (
    <ErrorBoundary>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: { xs: 1.5, sm: 2, md: 2 },
          boxShadow: (theme) => ({
            xs: theme.shadows[2],
            sm: theme.shadows[2],
            md: theme.shadows[2],
          }),
          backgroundColor: 'white',
          border: 'none',
          padding: { xs: 1.5, sm: 2, md: 1.5 },
          '& .MuiTypography-body2': {
            color: (theme) => theme.palette.text.secondary,
            fontSize: { xs: '0.875rem', sm: '0.875rem', md: '0.875rem' }
          },
          '& .MuiTypography-h4': {
            color: (theme) => theme.palette.text.primary,
            fontWeight: 500,
            fontSize: { xs: '1.5rem', sm: '1.5rem', md: '1.5rem' }
          }
        }}
      >
        <Box 
          pt={{ xs: 1, sm: 1, md: 1 }} 
          px={{ xs: 1.5, sm: 1.5, md: 1.5 }} 
          flexGrow={1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <StatisticInfo title={title} value={value} icon={icon} format={format} />
        </Box>
        <Box 
          mt={{ xs: 1, sm: 1, md: 1 }} 
          pb={{ xs: 1, sm: 1, md: 1 }}
          px={{ xs: 1.5, sm: 1.5, md: 1.5 }}
        >
          {processedVariations.map((variation, index) => (
            <StatisticVariation 
              key={index} 
              variation={variation} 
              type={type} 
            />
          ))}
        </Box>
      </Card>
    </ErrorBoundary>
  )
}

StatisticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.node.isRequired,
  type: PropTypes.string,
  format: PropTypes.string,
  variations: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      interval: PropTypes.string,
      format: PropTypes.string,
      label: PropTypes.string,
    })
  ),
}

export default memo(StatisticsCard)
