import React from 'react'

import { ReactComponent as RemoveCircleSvg } from '../../../assets/mango/icons/remove-circle-outline.svg'

const RemoveCircleIcon = (props) => (
  <RemoveCircleSvg
    {...props}
    style={{
      height: '20px',
      width: '20px',
      color: 'white',
      display: 'block',
    }}
  />
)

export default RemoveCircleIcon
