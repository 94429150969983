// components/organisms/TicketEditModal.jsx

import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, Modal, Typography } from '@mui/material'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { es } from 'date-fns/locale'

import { TicketFormItemModal } from '../../organisms'
import { ReactComponent as AddCircleIcon } from '../../../assets/mango/icons/add-circle-outline.svg'

const TicketEditModal = ({ open, onClose, tickets, setTickets, handleSave }) => {
  const [expandedTicketId, setExpandedTicketId] = useState(null)
  const [deletedTickets, setDeletedTickets] = useState([])
  const [nextTicketId, setNextTicketId] = useState(1)

  useEffect(() => {
    if (open) {
      setExpandedTicketId(tickets.length > 0 ? tickets[0].id : null)
      setDeletedTickets([])
      setNextTicketId(tickets.length + 1)
    }
  }, [open, tickets.length])

  const handleAddTicket = useCallback(() => {
    const newTicketId = nextTicketId
    const newTicket = {
      id: newTicketId,
      isNew: true,
      priority: tickets.length,
      name: '',
      description: '',
      price: 0,
      formattedPrice: '',
      stock: 0,
      maxPerSale: 0,
      includesCover: false,
      endDate: null,
      endHour: null,
      type: 'ENTRANCE',
    }
    setTickets((prevTickets) => [...prevTickets, newTicket])
    setNextTicketId((prevId) => prevId + 1)
    setExpandedTicketId(newTicketId)
  }, [nextTicketId, tickets.length])

  const handleDeleteTicket = useCallback(
    (ticketId) => {
      const ticketToDelete = tickets.find((ticket) => ticket.id === ticketId)
      if (ticketToDelete) {
        if (!ticketToDelete.isNew) {
          setDeletedTickets((prev) => [...prev, ticketId])
        }
        const newTickets = tickets.filter((ticket) => ticket.id !== ticketId)
        setTickets(newTickets)
        if (expandedTicketId === ticketId) {
          setExpandedTicketId(newTickets.length > 0 ? newTickets[0].id : null)
        }
      }
    },
    [tickets, expandedTicketId]
  )

  const handleExpandTicket = useCallback((ticketId, isExpanded) => {
    setExpandedTicketId(isExpanded ? ticketId : null)
  }, [])

  const handleDragEnd = useCallback(
    (result) => {
      if (!result.destination) return
      const newTickets = Array.from(tickets)
      const [movedTicket] = newTickets.splice(result.source.index, 1)
      newTickets.splice(result.destination.index, 0, movedTicket)
      const updatedTickets = newTickets.map((ticket, index) => ({
        ...ticket,
        priority: index,
      }))
      setTickets(updatedTickets)
    },
    [tickets]
  )

  const setTicketDataAtIndex = useCallback(
    (index) => (updatedData) => {
      setTickets((prevTickets) => {
        const newTickets = [...prevTickets]
        newTickets[index] = { ...newTickets[index], ...updatedData }
        return newTickets
      })
    },
    []
  )

  const handleSaveClick = useCallback(() => {
    handleSave(tickets, deletedTickets)
    onClose()
  }, [handleSave, tickets, deletedTickets, onClose])

  const handleCancel = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Modal open={open} onClose={onClose}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <Box
          sx={{
            // Estilos del modal
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', md: '60%' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 2,
            maxHeight: '80vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Contenido del modal con scroll interno */}
          <Box
            sx={{
              p: 4,
              overflowY: 'auto',
              flex: '1 1 auto',
            }}
          >
            <Typography variant="h4" sx={{ mb: 2 }}>
              Editar Tickets
            </Typography>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="tickets">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {tickets.map((ticket, index) => (
                      <Draggable key={ticket.id} draggableId={`ticket-${ticket.id}`} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              marginBottom: '16px',
                            }}
                          >
                            <TicketFormItemModal
                              ticketNumber={ticket.id}
                              ticketData={ticket}
                              setTicketData={setTicketDataAtIndex(index)}
                              expanded={expandedTicketId === ticket.id}
                              onChange={(isExpanded) => handleExpandTicket(ticket.id, isExpanded)}
                              onDelete={() => handleDeleteTicket(ticket.id)}
                              dragHandleProps={provided.dragHandleProps}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {/* Nueva área para "Agregar nuevo Ticket" */}
            <Grid
              container
              width="100%"
              border="1px dashed #000"
              sx={{
                borderRadius: '10px',
                marginTop: '16px',
                '&::before': { display: 'none' },
                padding: '8px 16px',
                backgroundColor: '#fff',
              }}
            >
              <Grid container alignItems="center" sx={{ minHeight: '48px' }}>
                <Grid item xs={6}>
                  <Typography sx={{ color: '#000' }}>Agregar otro Ticket</Typography>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end" alignItems="center">
                  <IconButton onClick={handleAddTicket}>
                    <AddCircleIcon style={{ height: '32px', width: '32px', color: '#fb8c00' }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {/* Botones fijos en la parte inferior */}
          <Box
            sx={{
              p: 2,
              borderTop: '1px solid #ccc',
              display: 'flex',
              justifyContent: 'flex-end',
              backgroundColor: '#fff',
            }}
          >
            <Button onClick={handleCancel} variant="outlined" sx={{ mr: 2 }}>
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={handleSaveClick}>
              Guardar
            </Button>
          </Box>
        </Box>
      </LocalizationProvider>
    </Modal>
  )
}

export default TicketEditModal
