import React from 'react'
import { FormControl, FormHelperText, IconButton, MenuItem, Select } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { ReactComponent as DownArrow } from '../../../assets/mango/icons/chevron-down-outline.svg'

const SelectAtom = ({ options, value, onChange, placeholder, name, error, helperText }) => (
  <FormControl
    fullWidth
    variant="outlined"
    error={error}
    sx={{ backgroundColor: '#263342', borderRadius: 1, height: '40px' }}
  >
    <Select
      name={name}
      value={value}
      onChange={onChange}
      displayEmpty
      inputProps={{ 'aria-label': 'Sin selección' }}
      sx={{
        color: 'white',
        height: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
          border: error ? '1px solid #f44336' : '1px solid #667085',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: error ? '1px solid #f44336' : '1px solid #667085',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: error ? '2px solid #f44336' : '2px solid #F79009',
        },
        '& .MuiSelect-select': {
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        },
      }}
      renderValue={(selected) => {
        if (!selected) {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span style={{ color: 'white', alignItems: 'flex-start' }}>{placeholder}</span>
              <IconButton>
                <DownArrow
                  style={{
                    height: '15px',
                    width: '15px',
                    color: '#718EBF',
                    alignItems: 'flex-end',
                  }}
                />
              </IconButton>
            </div>
          )
        }
        const selectedOption = options.find((option) => option.value.toLowerCase() === selected.toLowerCase())
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span style={{ color: 'white', alignItems: 'flex-start' }}>
              {selectedOption ? selectedOption.displayName : selected}
            </span>
            <IconButton>
              <DownArrow
                style={{
                  height: '15px',
                  width: '15px',
                  color: '#718EBF',
                  alignItems: 'flex-end',
                }}
              />
            </IconButton>
          </div>
        )
      }}
      IconComponent={ArrowDropDownIcon}
      MenuProps={{
        PaperProps: {
          style: {
            backgroundColor: '#263342',
            color: 'white',
          },
        },
        disableScrollLock: true,
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value} style={{ color: 'black' }}>
          {option.displayName}
        </MenuItem>
      ))}
    </Select>
    {error && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
)

export default SelectAtom
