// src/theme.js
import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#f79009',
    },
    background: {
      default: '#04121a',
    },
    text: {
      primary: '#000',
      secondary: '#98a2b3',
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontSize: '22px',
      fontWeight: 700,
      letterSpacing: '-0.88px',
    },
    h2: {
      fontSize: '18px',
      fontWeight: 700,
      letterSpacing: '-0.72px',
    },
    h3: {
      fontSize: '16px',
      fontWeight: 700,
      letterSpacing: '-0.64px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '14px',
      fontWeight: 500,
    },
    caption: {
      fontSize: '12px',
      fontWeight: 500,
    },
  },
})

export default theme
