import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box
} from '@mui/material'
import { useCreateUser } from '../../../hooks/userManagement/useCreateUser'
import { useUpdateUser } from '../../../hooks/userManagement/useUpdateUser'
import { useNotification } from '../../../contexts/NotificationContext'
import PersonAddIcon from '@mui/icons-material/PersonAdd'

const AddEditUserDialog = ({ open, onClose, user, refetchUsers }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: ''
  })
  const { createUser, loading: creatingUser } = useCreateUser()
  const { updateUser, loading: updatingUser } = useUpdateUser()
  const { showNotification } = useNotification()

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name || '',
        email: user.email || ''
      })
    } else {
      setFormData({
        name: '',
        email: ''
      })
    }
  }, [user])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (user) {
        await updateUser(user.id, formData)
        showNotification('Usuario actualizado exitosamente', 'success')
      } else {
        await createUser(formData)
        showNotification('Usuario creado exitosamente', 'success')
      }
      await refetchUsers()
      onClose()
    } catch (error) {
      showNotification(
        `Error al ${user ? 'actualizar' : 'crear'} el usuario: ${error.message}`,
        'error'
      )
    }
  }

  const handleClose = () => {
    setFormData({ name: '', email: '' })
    onClose()
  }

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          maxWidth: '500px',
          width: '100%'
        }
      }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid #E5E7EB',
        p: 3
      }}>
        <Typography 
          variant="h6" 
          sx={{ 
            color: '#101828',
            fontWeight: 600,
            fontFamily: 'Plus Jakarta Sans',
            fontSize: '18px'
          }}
        >
          {user ? 'Editar Usuario' : 'Agregar Usuario'}
        </Typography>
      </DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              placeholder="Nombre"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              fullWidth
              required
              sx={{
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  height: '44px',
                  fontSize: '14px',
                  '& fieldset': {
                    borderColor: '#E5E7EB',
                  },
                  '&:hover fieldset': {
                    borderColor: '#F79009',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#F79009',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#6B7280',
                  fontSize: '14px',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#F79009',
                },
              }}
            />

            <TextField
              placeholder="Email"
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              fullWidth
              required
              sx={{
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  height: '44px',
                  fontSize: '14px',
                  '& fieldset': {
                    borderColor: '#E5E7EB',
                  },
                  '&:hover fieldset': {
                    borderColor: '#F79009',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#F79009',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#6B7280',
                  fontSize: '14px',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#F79009',
                },
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ 
          p: 3, 
          borderTop: '1px solid #E5E7EB',
          gap: 2
        }}>
          <Button
            onClick={handleClose}
            sx={{
              color: '#344054',
              textTransform: 'none',
              fontWeight: 500,
              fontFamily: 'Inter',
              fontSize: '14px',
              '&:hover': {
                backgroundColor: '#F9FAFB'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!formData.name || !formData.email || creatingUser || updatingUser}
            startIcon={<PersonAddIcon sx={{ fontSize: 20 }} />}
            sx={{
              backgroundColor: '#F79009',
              color: '#fff',
              textTransform: 'none',
              fontWeight: 500,
              fontFamily: 'Inter',
              fontSize: '14px',
              '&:hover': {
                backgroundColor: '#E27D08'
              },
              '&.Mui-disabled': {
                backgroundColor: '#FDA94E',
                color: '#fff'
              }
            }}
          >
            {creatingUser || updatingUser ? 'Guardando...' : user ? 'Guardar Cambios' : 'Agregar Usuario'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddEditUserDialog
