import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useUser } from '../../../hooks/auth/useUser'
import { useAuth } from '../../../hooks/auth/useAuth'
import { Skeleton } from '@mui/material'

import { Avatar, Box, Typography } from '../../atoms'
import { validateImage } from '../../../utils/images'
import UserProfileModal from '../UserProfileModal/UserProfileModal'

const UserThumbnail = ({ displayUserData = true }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { user, loading, error, refreshUserData } = useUser()
  const { setIsAuthenticated } = useAuth()

  console.log('UserThumbnail render:', { user, loading, error })

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleSaveProfile = async (formData) => {
    console.log('Saving profile data:', formData)
    await refreshUserData()
  }

  if (loading) {
    console.log('UserThumbnail loading state')
    return (
      <Box display="flex" alignItems="center" lineHeight={1} ml={3}>
        <Skeleton variant="circular" width={40} height={40} />
        {displayUserData && (
          <Box ml={2} lineHeight={1}>
            <Skeleton variant="text" width={100} />
            <Skeleton variant="text" width={150} />
          </Box>
        )}
      </Box>
    )
  }

  if (!user) {
    console.log('UserThumbnail: No user data')
    return null
  }

  console.log('UserThumbnail user data:', {
    firstname: user?.firstname,
    lastname: user?.lastname,
    email: user?.email,
    picture: user?.picture
  })

  const fullName = `${user?.firstname} ${user?.lastname}`

  return (
    <>
      <Box 
        display="flex" 
        alignItems="center" 
        lineHeight={1} 
        ml={3}
        onClick={handleOpenModal}
        sx={{ 
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.8
          }
        }}
      >
        <Avatar src={validateImage(user?.picture)} name={fullName} size="sm" />
        {displayUserData && (
          <Box ml={2} lineHeight={1}>
            <Typography display="block" color="white" variant="button" fontWeight="medium">
              {fullName}
            </Typography>
            <Typography color="white" variant="caption">
              {user?.email}
            </Typography>
          </Box>
        )}
      </Box>

      <UserProfileModal
        open={isModalOpen}
        onClose={handleCloseModal}
        userData={{
          name: fullName,
          email: user?.email,
          image: user?.picture,
          phone: user?.phone,
          dni: user?.dni,
          gender: user?.gender,
          birthDate: user?.birth_date,
        }}
        onSave={handleSaveProfile}
        setIsAuthenticated={setIsAuthenticated}
      />
    </>
  )
}

UserThumbnail.propTypes = {
  displayUserData: PropTypes.bool,
}

export default UserThumbnail
