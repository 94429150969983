import React, { useState, useCallback, memo } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Avatar,
  Typography,
  Alert,
  Grid
} from '@mui/material'
import authService from '../../../services/auth-service'
import { gql, useMutation } from '@apollo/client'

const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      success
      message
    }
  }
`

const UserProfileModal = ({ open, onClose, userData, onSave, setIsAuthenticated }) => {
  const [formData, setFormData] = useState({
    firstname: userData?.name?.split(' ')[0] || '',
    lastname: userData?.name?.split(' ').slice(1).join(' ') || '',
    email: userData?.email || '',
    phone: userData?.phone || '',
    image: userData?.image || ''
  })
  const [imagePreview, setImagePreview] = useState(userData?.image || '')
  const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' })

  const handleLogout = useCallback(async () => {
    try {
      const response = await authService.logout()
      if (response.logout.success) {
        setIsAuthenticated(false)
        onClose()
      }
    } catch (error) {
      console.error('Error during logout:', error)
    }
  }, [setIsAuthenticated, onClose])

  const [updateUserProfile, { loading }] = useMutation(UPDATE_USER_PROFILE, {
    onCompleted: (data) => {
      if (data?.updateUserProfile?.success) {
        onSave({
          ...formData,
          name: `${formData.firstname} ${formData.lastname}`.trim()
        })
        onClose()
      }
    },
    onError: (error) => {
      console.error('Mutation error:', error)
      setSubmitStatus({
        type: 'error',
        message: error.message || 'Error al actualizar el perfil. Por favor, intenta nuevamente.'
      })
    }
  })

  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const handleImageChange = useCallback((e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImagePreview(reader.result)
        setFormData(prev => ({
          ...prev,
          image: file
        }))
      }
      reader.readAsDataURL(file)
    }
  }, [])

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()
    if (loading) return

    setSubmitStatus({
      type: 'success',
      message: 'Guardando cambios...'
    })

    try {
      await updateUserProfile({
        variables: {
          input: {
            firstname: formData.firstname,
            lastname: formData.lastname,
            phone: formData.phone,
            picture: formData.image
          }
        }
      })
    } catch (error) {
      console.error('Error updating profile:', error)
      setSubmitStatus({
        type: 'error',
        message: 'Error al actualizar el perfil. Por favor, intenta nuevamente.'
      })
    }
  }, [loading, updateUserProfile, formData])

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          maxWidth: '500px',
          width: '100%'
        }
      }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid #E5E7EB',
        p: 3
      }}>
        <Typography 
          variant="h6" 
          sx={{ 
            color: '#101828',
            fontWeight: 600,
            fontFamily: 'Plus Jakarta Sans',
            fontSize: '18px'
          }}
        >
          Editar Perfil
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        {submitStatus.message && (
          <Alert 
            severity={submitStatus.type} 
            sx={{ mb: 2 }}
            onClose={() => setSubmitStatus({ type: '', message: '' })}
          >
            {submitStatus.message}
          </Alert>
        )}
        <Box 
          component="form" 
          id="profile-form"
          onSubmit={handleSubmit} 
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt: 2 }}>
            <Box sx={{ position: 'relative' }}>
              <input
                accept="image/*"
                type="file"
                id="image-upload"
                onChange={handleImageChange}
                style={{ display: 'none' }}
              />
              <label htmlFor="image-upload">
                <Avatar
                  src={imagePreview}
                  sx={{ 
                    width: 100, 
                    height: 100, 
                    cursor: 'pointer',
                    '&:hover': {
                      opacity: 0.8
                    }
                  }}
                />
              </label>
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="firstname"
                label="Nombre"
                value={formData.firstname}
                onChange={handleChange}
                fullWidth
                sx={{
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-root': {
                    height: '44px',
                    fontSize: '14px',
                    color: '#101828',
                    '& fieldset': {
                      borderColor: '#E5E7EB',
                    },
                    '&:hover fieldset': {
                      borderColor: '#F79009',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#F79009',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#6B7280',
                    fontSize: '14px',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#F79009',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="lastname"
                label="Apellido"
                value={formData.lastname}
                onChange={handleChange}
                fullWidth
                sx={{
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-root': {
                    height: '44px',
                    fontSize: '14px',
                    color: '#101828',
                    '& fieldset': {
                      borderColor: '#E5E7EB',
                    },
                    '&:hover fieldset': {
                      borderColor: '#F79009',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#F79009',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#6B7280',
                    fontSize: '14px',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#F79009',
                  },
                }}
              />
            </Grid>
          </Grid>
          <TextField
            name="email"
            label="Email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            disabled
            sx={{
              backgroundColor: '#fff',
              '& .MuiOutlinedInput-root': {
                height: '44px',
                fontSize: '14px',
                color: '#101828',
                '& fieldset': {
                  borderColor: '#E5E7EB',
                },
                '&:hover fieldset': {
                  borderColor: '#E5E7EB',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#E5E7EB',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#F9FAFB',
                  color: '#101828',
                  '& fieldset': {
                    borderColor: '#E5E7EB !important',
                  },
                },
              },
              '& .MuiInputLabel-root': {
                color: '#6B7280',
                fontSize: '14px',
              },
              '& .MuiInputLabel-root.Mui-disabled': {
                color: '#6B7280',
              },
            }}
          />
          <TextField
            name="phone"
            label="Teléfono"
            value={formData.phone}
            onChange={handleChange}
            fullWidth
            sx={{
              backgroundColor: '#fff',
              '& .MuiOutlinedInput-root': {
                height: '44px',
                fontSize: '14px',
                color: '#101828',
                '& fieldset': {
                  borderColor: '#E5E7EB',
                },
                '&:hover fieldset': {
                  borderColor: '#F79009',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#F79009',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#6B7280',
                fontSize: '14px',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#F79009',
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ 
        p: 3, 
        borderTop: '1px solid #E5E7EB',
        gap: 2,
        display: 'flex',
        justifyContent: 'center'
      }}>
        <Button
          type="submit"
          form="profile-form"
          variant="contained"
          disabled={loading}
          sx={{
            backgroundColor: '#F79009',
            color: '#fff',
            textTransform: 'none',
            fontWeight: 500,
            fontFamily: 'Inter',
            fontSize: '14px',
            minWidth: '160px',
            '&:hover': {
              backgroundColor: '#E27D08'
            },
            '&.Mui-disabled': {
              backgroundColor: '#FDA94E',
              color: '#fff'
            }
          }}
        >
          {loading ? 'Guardando...' : 'Guardar Cambios'}
        </Button>
        <Button
          onClick={handleLogout}
          variant="outlined"
          sx={{
            color: '#344054',
            borderColor: '#E5E7EB',
            textTransform: 'none',
            fontWeight: 500,
            fontFamily: 'Inter',
            fontSize: '14px',
            minWidth: '160px',
            '&:hover': {
              borderColor: '#F79009',
              backgroundColor: '#F9FAFB'
            }
          }}
        >
          Cerrar Sesión
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UserProfileModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  setIsAuthenticated: PropTypes.func.isRequired,
}

export default memo(UserProfileModal) 