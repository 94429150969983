import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'

import { FormField } from '../../molecules'

const ChangePasswordDialog = ({ open, onClose, onSavePassword, worker }) => {
  const [passwordData, setPasswordData] = useState({
    password: '',
    confirmPassword: '',
  })
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (!open) {
      setPasswordData({
        password: '',
        confirmPassword: '',
      })
      setErrors({})
    }
  }, [open])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setPasswordData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const validate = () => {
    const newErrors = {}

    if (!passwordData.password.trim()) {
      newErrors.password = 'La contraseña es requerida'
    } else if (passwordData.password.length < 6) {
      newErrors.password = 'La contraseña debe tener al menos 6 caracteres'
    }

    if (passwordData.password !== passwordData.confirmPassword) {
      newErrors.confirmPassword = 'Las contraseñas no coinciden'
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSave = () => {
    if (validate()) {
      onSavePassword(worker.id, passwordData.password)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#092838',
          borderRadius: '0.5 rem',
        },
      }}
    >
      <DialogTitle style={{ backgroundColor: '#092838', color: '#fff' }}>
        <span>Cambiar Contraseña</span>
        <Box
          mt={1}
          sx={{
            flexShrink: 0,
            width: '100%',
            height: '1px',
            backgroundColor: '#041E2B !important',
          }}
        />
      </DialogTitle>
      <DialogContent style={{ backgroundColor: '#092838', color: '#fff' }}>
        <Box
          component="form"
          onSubmit={(e) => e.preventDefault()}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            marginTop: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormField
                label="Nueva Contraseña"
                name="password"
                type="password"
                value={passwordData.password}
                onChange={handleInputChange}
                error={!!errors.password}
                helperText={errors.password}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                label="Confirmar Contraseña"
                name="confirmPassword"
                type="password"
                value={passwordData.confirmPassword}
                onChange={handleInputChange}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#092838' }}>
        <Button onClick={onClose} style={{ color: '#F79009' }}>
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          style={{
            backgroundColor: '#F79009',
            color: 'white',
            borderRadius: '8px',
            fontWeight: 'bold',
          }}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangePasswordDialog
