// components/organisms/EventInfoSection.jsx

import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, IconButton, Modal, Typography } from '@mui/material'

import { EventFormModal } from '../../organisms'
import { LoadingSpinner, SnackbarAlert, StyledImage } from '../../atoms'
import { ReactComponent as EditIcon } from '../../../assets/mango/icons/edit.svg'
import { buildEventData, transformEventData } from '../../../utils/events'
import useFetchEventDataById from '../../../hooks/events/useFetchEventDataById'
import useUpdateEventData from '../../../hooks/events/useUpdateEventData'

const EventInfoSection = React.memo(({ eventId }) => {
  const [eventData, setEventData] = useState(null)
  const [open, setOpen] = useState(false)
  const [modalEventData, setModalEventData] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  // Fetch event data
  const { data, initialLoading, loading, error, refreshData: refreshEventData } = useFetchEventDataById(eventId)

  // Update event data
  const { updateEvent } = useUpdateEventData()

  // Handle errors
  useEffect(() => {
    if (error) {
      setErrorMessage(error.message)
      setSnackbarOpen(true)
    }
  }, [error])

  const handleSnackbarClose = useCallback(() => {
    setSnackbarOpen(false)
    setErrorMessage('')
    setSuccessMessage('')
  }, [])

  // Parse event data
  useEffect(() => {
    if (data) {
      const parsedData = buildEventData(data)
      setEventData(parsedData)
      setModalEventData(parsedData)
    }
  }, [data])

  const handleOpen = useCallback(() => {
    setModalEventData(eventData)
    setOpen(true)
  }, [eventData])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const validateEventData = useCallback((data) => {
    const combineDateAndTime = (date, time) => {
      if (!date.isValid()) {
        return null
      }
      if (time && time.isValid()) {
        return date.hour(time.hour()).minute(time.minute()).toDate()
      }
      return date.toDate()
    }

    const startDateTime = combineDateAndTime(data.rawStartDate, data.rawStartHour)
    const endDateTime = combineDateAndTime(data.rawEndDate, data.rawEndHour)

    if (!data.name.trim()) {
      return 'El nombre del evento es requerido.'
    }
    if (!data.address.trim()) {
      return 'La dirección del evento es requerida.'
    }
    if (!data.venue.trim()) {
      return 'El lugar del evento es requerido.'
    }
    if (!data.description.trim()) {
      return 'La descripción del evento es requerida.'
    }
    if (data.description.length < 10) {
      return 'La descripción del evento debe tener al menos 10 caracteres.'
    }
    if (!data.rawStartDate.isValid()) {
      return 'La fecha de inicio del evento es requerida.'
    }
    if (data.rawStartHour && !data.rawStartHour.isValid()) {
      return 'La hora de inicio es inválida.'
    }
    if (!data.rawEndDate.isValid()) {
      return 'La fecha de finalización del evento es requerida.'
    }
    if (data.rawEndHour && !data.rawEndHour.isValid()) {
      return 'La hora de finalización es inválida.'
    }
    if (startDateTime && endDateTime && startDateTime > endDateTime) {
      return 'La fecha y hora de inicio no pueden ser posteriores a la fecha y hora de finalización.'
    }
    if (startDateTime && endDateTime && startDateTime.getTime() === endDateTime.getTime()) {
      return 'La fecha y hora de inicio no pueden ser iguales a la fecha y hora de finalización.'
    }
    if (!data.image) {
      return 'La imagen del evento es requerida.'
    }
    return ''
  }, [])
  const handleSaveClick = useCallback(async () => {
    const errorMsg = validateEventData(modalEventData)
    if (errorMsg) {
      setErrorMessage(errorMsg)
      setSnackbarOpen(true)
      return
    }

    const transformedData = transformEventData(modalEventData)

    try {
      await updateEvent(eventId, transformedData)
      setEventData(modalEventData)
      setOpen(false)
      setSuccessMessage('Evento actualizado con éxito.')
      setSnackbarOpen(true)
      refreshEventData()
    } catch (error) {
      console.error('Error updating event:', error)
      setErrorMessage('Error al actualizar la información del evento.')
      setSnackbarOpen(true)
    }
  }, [modalEventData, updateEvent, eventId, refreshEventData, validateEventData])

  if (initialLoading) return <LoadingSpinner />

  if (error && !eventData) {
    return (
      <Box sx={{ color: 'error.main' }}>
        <Typography variant="h6" color="error">
          {errorMessage}
        </Typography>
      </Box>
    )
  }

  if (!eventData) {
    return null
  }

  return (
    <>
      {/* Información del evento */}
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: { xs: '12px', sm: '16px', md: '18px' },
          backgroundColor: '#07181E',
          padding: { xs: 2, sm: 2.5, md: 3 },
          color: '#fff',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '3px',
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            marginBottom: { xs: 1.5, sm: 2 },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: '#fff',
              wordBreak: 'break-word',
              paddingRight: '48px',
              fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
              lineHeight: { xs: 1.3, sm: 1.4, md: 1.5 },
            }}
          >
            {eventData.name}
          </Typography>
          <IconButton
            aria-label="Editar Evento"
            sx={{
              color: '#fff',
              position: 'absolute',
              top: 0,
              right: 0,
              padding: { xs: 1, sm: 1.5 },
            }}
            onClick={handleOpen}
          >
            <EditIcon />
          </IconButton>
        </Box>

        {/* Fecha y hora */}
        <Box sx={{ marginTop: { xs: 1.5, sm: 2 } }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
            <StyledImage
              src="https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/34641026f2cab03033752052f88bf40e0375a1b6ea6bd094731ff2e85e943255"
              alt="Calendario"
              sx={{ 
                width: { xs: '20px', sm: '24px' }, 
                height: { xs: '20px', sm: '24px' },
                marginTop: '2px'
              }}
            />
            <Typography 
              variant="body2" 
              sx={{ 
                color: '#98a2b3',
                fontSize: { xs: '0.875rem', sm: '0.9375rem' },
                lineHeight: { xs: 1.4, sm: 1.5 },
              }}
            >
              {eventData.date}
              <br />
              {eventData.time}
            </Typography>
          </Box>
        </Box>

        {/* Ubicación */}
        <Box sx={{ marginTop: { xs: 1.5, sm: 2 } }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
            <StyledImage
              src="https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/1e308a310a6ba25993acc4f09b741be893dcdd3de4313f0d5dd38b0bc34c7012"
              alt="Ubicación"
              sx={{ 
                width: { xs: '20px', sm: '24px' }, 
                height: { xs: '20px', sm: '24px' },
                marginTop: '2px'
              }}
            />
            <Typography 
              variant="body2" 
              sx={{ 
                color: '#98a2b3',
                fontSize: { xs: '0.875rem', sm: '0.9375rem' },
                lineHeight: { xs: 1.4, sm: 1.5 },
              }}
            >
              {eventData.venue}
              <br />
              {eventData.address}
            </Typography>
          </Box>
        </Box>

        {/* Descripción del evento */}
        <Box sx={{ marginTop: { xs: 2, sm: 2.5, md: 3 } }}>
          <Typography 
            variant="h6" 
            sx={{ 
              color: '#fff',
              fontSize: { xs: '1rem', sm: '1.125rem', md: '1.25rem' },
              marginBottom: { xs: 0.5, sm: 1 },
            }}
          >
            Sobre el evento
          </Typography>
          <Box
            sx={{
              width: '100%',
              height: '1px',
              backgroundColor: '#344054',
              marginBottom: { xs: 1, sm: 1.5 },
            }}
          />
          <Typography 
            variant="body2" 
            sx={{ 
              color: '#eaecf0', 
              fontSize: { xs: '0.875rem', sm: '0.9375rem' },
              lineHeight: { xs: 1.4, sm: 1.5 },
            }}
          >
            {eventData.description}
          </Typography>
        </Box>
      </Box>

      {/* Edit Event Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            // Estilos del modal
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', md: '60%' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 2,
            maxHeight: '80vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Contenido del modal con scroll interno */}
          <Box
            sx={{
              p: 4,
              overflowY: 'auto',
              flex: '1 1 auto',
            }}
          >
            <Typography variant="h4" sx={{ mb: 2 }}>
              Editar Evento
            </Typography>
            <EventFormModal setData={setModalEventData} data={modalEventData} />
          </Box>

          {/* Botones fijos en la parte inferior */}
          <Box
            sx={{
              p: 2,
              borderTop: '1px solid #ccc',
              display: 'flex',
              justifyContent: 'flex-end',
              backgroundColor: '#fff',
            }}
          >
            <Button onClick={handleClose} variant="outlined" sx={{ mr: 2 }}>
              Cancelar
            </Button>
            <Button onClick={handleSaveClick} variant="contained" color="primary">
              Guardar
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Snackbar Alert */}
      <SnackbarAlert
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={errorMessage || successMessage}
        severity={errorMessage ? 'error' : 'success'}
      />
    </>
  )
})

export default EventInfoSection
