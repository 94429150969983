import React from 'react'
import { useParams } from 'react-router-dom'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'

import useFetchEventDataById from '../../../hooks/events/useFetchEventDataById'
import { DashboardLayout, DashboardNavbar } from '../../templates'
import { BarSales, CashierTable, EventData, TicketSales, TotalSales } from '../../organisms'
import { LoadingSpinner } from '../../atoms'

function applyDraggableProps(draggableProps) {
  return {
    ...draggableProps,
    style: {
      ...draggableProps.style,
      cursor: 'default',
    },
  }
}

function EventMetricsPage() {
  const { eventId: id } = useParams()
  const [initialLoad, setInitialLoad] = React.useState(false)
  const { data, loading, error, refreshData } = useFetchEventDataById(id)
  if (!initialLoad) {
    refreshData()
    setInitialLoad(true)
  }

  const [elements, setElements] = React.useState([
    {
      id: 'totalSales',
      component: ({ id }) => <TotalSales eventId={id} />,
      name: 'Total Sales',
      gridSize: { xs: 12, md: 6 },
    },
    {
      id: 'eventData',
      component: ({ data }) => <EventData data={data} />,
      name: 'Event Data',
      gridSize: { xs: 12, md: 6 },
    },
    {
      id: 'ticketSales',
      component: ({ id }) => <TicketSales eventId={id} />,
      name: 'Ticket Sales',
      gridSize: { xs: 12, md: 6 },
    },
    {
      id: 'barSales',
      component: ({ id }) => <BarSales eventId={id} />,
      name: 'Bar Sales',
      gridSize: { xs: 12, md: 6 },
    },
    {
      id: 'cashierTable',
      component: ({ id }) => <CashierTable eventId={id} />,
      name: 'Cashier Table',
      gridSize: { xs: 12, md: 12 },
    },
  ])

  const onDragEnd = (result) => {
    if (!result.destination) return

    const items = Array.from(elements)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setElements(items)
  }

  if (loading) return <LoadingSpinner />

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          textAlign: 'center',
          backgroundColor: 'inherit',
          borderRadius: '10px',
        }}
      >
        <Typography variant="h6" color="error">
          {error.message}
        </Typography>
        <Typography variant="body1">
          Por favor, intenta refrescar la página o contacta a soporte si el problema persiste.
        </Typography>
      </Box>
    )
  }

  return (
    <DashboardLayout>
      <Box py={3}>
        <DashboardNavbar absolute title={data?.name ? `Metricas de ${data?.name}` : 'Metricas de'} showBackButton />
      </Box>
      <Box 
        sx={{ 
          backgroundColor: 'inherit',
          padding: {
            xs: '16px',
            sm: '24px',
            md: '32px'
          }
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Grid 
                container 
                spacing={{ xs: 2, sm: 3, md: 4 }} 
                alignItems="stretch" 
                {...provided.droppableProps} 
                ref={provided.innerRef}
              >
                {elements.map((element, index) => (
                  <Draggable key={element.id} draggableId={element.id} index={index}>
                    {(provided) => (
                      <Grid
                        item
                        xs={12}
                        sm={element.gridSize.xs === 12 ? 12 : 6}
                        md={element.gridSize.md}
                        ref={provided.innerRef}
                        {...applyDraggableProps(provided.draggableProps)}
                        {...provided.dragHandleProps}
                      >
                        <element.component id={id} data={data} />
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </DashboardLayout>
  )
}

export default EventMetricsPage
