import { gql, useMutation } from '@apollo/client'

const BATCH_CREATE_CUSTOMER_USERS = gql`
  mutation BatchCreateCustomerUsers($input: [CustomerUserData!]!) {
    batchCreateCustomerUser(input: $input) {
      id
      external_id
      email
      name
      created_at
      updated_at
    }
  }
`

export const useBatchCreateUsers = () => {
  const [batchCreateUsers] = useMutation(BATCH_CREATE_CUSTOMER_USERS, {
    onError: (error) => {
      console.error('Error en mutación GraphQL:', error)
    }
  })

  const createBatchUsers = async (users) => {
    try {
      console.log('Enviando datos a GraphQL:', users) // Debug

      const { data } = await batchCreateUsers({
        variables: {
          input: users.map(user => ({
            name: user.name,
            email: user.email
          }))
        }
      })

      console.log('Respuesta de GraphQL:', data) // Debug
      return data.batchCreateCustomerUser
    } catch (error) {
      console.error('Error en createBatchUsers:', error) // Debug
      throw error
    }
  }

  return { createBatchUsers }
} 