import React from 'react'

import { Select } from '../../atoms'

const positions = [
  { displayName: 'Portero', value: 'doorman' },
  { displayName: 'Barra', value: 'barman' },
  { displayName: 'Cajero', value: 'cashier' },
]

const PositionSelect = ({ value, onChange, name, error, helperText }) => {
  return (
    <Select
      options={positions}
      value={value}
      onChange={onChange}
      placeholder="Cargo"
      name={name}
      error={error}
      helperText={helperText}
    />
  )
}

export default PositionSelect
