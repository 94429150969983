// SnackbarAlert.jsx

import React from 'react'
import { Alert, Snackbar } from '@mui/material'

const SnackbarAlert = React.memo(({ open, onClose, message, severity }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
})

export default SnackbarAlert
