import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'

import { FormField, PositionSelect } from '../../molecules'

const AddCashierDialog = ({ open, onClose, onSave, worker, onInputChange, isEditing }) => {
  const [errors, setErrors] = useState({})

  const validate = () => {
    const newErrors = {}

    if (!worker?.name?.trim()) {
      newErrors.name = 'El nombre es requerido'
    }

    if (!worker?.email?.trim()) {
      newErrors.email = 'El correo es requerido'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(worker.email)) {
      newErrors.email = 'Ingresa un correo electrónico válido'
    }

    if (!isEditing && !worker?.password?.trim()) {
      newErrors.password = 'La contraseña es requerida'
    } else if (!isEditing && worker?.password?.length < 6) {
      newErrors.password = 'La contraseña debe tener al menos 6 caracteres'
    }

    if (!worker?.role) {
      newErrors.role = 'El cargo es requerido'
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  // Validar cuando se intenta guardar
  const handleSave = () => {
    if (validate()) {
      onSave()
    }
  }

  // Limpiar errores al cerrar el modal
  useEffect(() => {
    if (!open) {
      setErrors({})
    }
  }, [open])

  // Función para manejar el submit del formulario
  const handleFormSubmit = (e) => {
    e.preventDefault() // Prevenir recarga de la página
    handleSave()
  }

  if (!worker) return null

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#092838',
          borderRadius: '0.5rem',
        },
      }}
    >
      <DialogTitle style={{ backgroundColor: '#092838', color: '#fff' }}>
        <span>{isEditing ? 'Editar Trabajador' : 'Agregar Trabajador'}</span>
        <Box
          mt={1}
          sx={{
            flexShrink: 0,
            width: '100%',
            height: '1px',
            backgroundColor: '#041E2B !important',
          }}
        />
      </DialogTitle>
      <DialogContent style={{ backgroundColor: '#092838', color: '#fff' }}>
        <Box
          component="form"
          onSubmit={handleFormSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            marginTop: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Nombre"
                name="name"
                value={worker.name || ''}
                onChange={onInputChange}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Correo"
                name="email"
                type="email"
                value={worker.email || ''}
                onChange={onInputChange}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            {!isEditing && (
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Contraseña"
                  name="password"
                  type="password"
                  value={worker.password || ''}
                  onChange={onInputChange}
                  error={!!errors.password}
                  helperText={errors.password}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={isEditing ? 12 : 6}>
              <PositionSelect
                value={worker.role || ''}
                onChange={onInputChange}
                name="role"
                error={!!errors.role}
                helperText={errors.role}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#092838' }}>
        <Button onClick={onClose} style={{ color: '#F79009' }}>
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          type="submit"
          style={{
            backgroundColor: '#F79009',
            color: 'white',
            borderRadius: '8px',
            fontWeight: 'bold',
          }}
        >
          {isEditing ? 'Guardar' : 'Agregar'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddCashierDialog
