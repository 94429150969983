import React from 'react'
import { Box, Typography, Button, Container } from '@mui/material'

const CallToAction = ({ onContact }) => {
  return (
    <Box
      component="section"
      sx={{
        textAlign: 'center',
        padding: { xs: '100px 20px', md: '112px 64px' },
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '40px', md: '48px' },
            fontFamily: '"Plus Jakarta Sans", sans-serif',
            fontWeight: 700,
            lineHeight: 1.2,
            marginBottom: '24px',
          }}
        >
          Simplifica tus eventos.
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '40px', md: '48px' },
            fontFamily: '"Plus Jakarta Sans", sans-serif',
            fontWeight: 700,
            lineHeight: 1.2,
            marginBottom: '24px',
          }}
        >
          Cámbiate a Mango
        </Typography>
        <Typography
          sx={{
            fontSize: '18px',
            fontFamily: '"Inter", sans-serif',
            fontWeight: 400,
            color: '#aaa',
            maxWidth: '600px',
            margin: '0 auto 48px',
          }}
        >
          Únete a la revolución en la gestión de eventos. Optimiza tus ventas, simplifica la experiencia de tus asistentes y haz crecer tu negocio con nosotros.
        </Typography>
        <Box sx={{ display: 'flex', gap: '24px', justifyContent: 'center' }}>
          <Button
            variant="contained"
            href="/events/new"
            sx={{
              backgroundColor: '#f79009',
              color: '#fff',
              borderRadius: '10px',
              padding: '12px 24px',
              fontSize: '16px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#e67e00',
              },
            }}
          >
            Crear Evento
          </Button>
          <Button
            variant="outlined"
            onClick={onContact}
            sx={{
              color: '#fff',
              borderColor: '#f79009',
              borderRadius: '10px',
              padding: '12px 24px',
              fontSize: '16px',
              textTransform: 'none',
              '&:hover': {
                borderColor: '#e67e00',
                backgroundColor: 'rgba(247, 144, 9, 0.1)',
              },
            }}
          >
            Hablemos
          </Button>
        </Box>
      </Container>
    </Box>
  )
}

export default CallToAction 