import React, { useState } from 'react'
import { 
  Box, 
  Button, 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  DialogActions,
  TextField, 
  Typography,
  Alert 
} from '@mui/material'
import { gql, useMutation } from '@apollo/client'

const SUBMIT_CONTACT_FORM = gql`
  mutation SubmitContactForm($input: ContactFormData!) {
    submitContactForm(input: $input) {
      success
      message
    }
  }
`

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

const validatePhone = (phone) => {
  const phoneRegex = /^[0-9]{9,10}$/
  return phoneRegex.test(phone.replace(/\D/g, ''))
}

const ContactModal = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: ''
  })
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: ''
  })
  const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' })
  
  const [submitContactForm, { loading }] = useMutation(SUBMIT_CONTACT_FORM, {
    onCompleted: (data) => {
      if (data.submitContactForm.success) {
        setSubmitStatus({ 
          type: 'success', 
          message: data.submitContactForm.message || 'Formulario enviado exitosamente' 
        })
        setFormData({ name: '', email: '', phone: '' })
        setErrors({ name: '', email: '', phone: '' })
        setTimeout(() => {
          onClose()
          setSubmitStatus({ type: '', message: '' })
        }, 2000)
      }
    },
    onError: (error) => {
      setSubmitStatus({ 
        type: 'error', 
        message: error.message || 'Hubo un error al enviar el formulario. Por favor, intenta nuevamente.' 
      })
    }
  })

  const validateForm = () => {
    const newErrors = {
      name: '',
      email: '',
      phone: ''
    }
    let isValid = true

    // Validar nombre
    if (!formData.name.trim()) {
      newErrors.name = 'El nombre es requerido'
      isValid = false
    } else if (formData.name.trim().length < 3) {
      newErrors.name = 'El nombre debe tener al menos 3 caracteres'
      isValid = false
    }

    // Validar email
    if (!formData.email.trim()) {
      newErrors.email = 'El email es requerido'
      isValid = false
    } else if (!validateEmail(formData.email)) {
      newErrors.email = 'Por favor ingresa un email válido'
      isValid = false
    }

    // Validar teléfono
    if (!formData.phone.trim()) {
      newErrors.phone = 'El teléfono es requerido'
      isValid = false
    } else if (!validatePhone(formData.phone)) {
      newErrors.phone = 'Por favor ingresa un número de teléfono válido (9-10 dígitos)'
      isValid = false
    }

    setErrors(newErrors)
    return isValid
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
    // Limpiar error del campo cuando el usuario empieza a escribir
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }))
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!validateForm()) {
      return
    }
    try {
      await submitContactForm({
        variables: {
          input: {
            ...formData,
            phone: formData.phone.replace(/\D/g, '') // Eliminar caracteres no numéricos
          }
        }
      })
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          maxWidth: '500px',
          width: '100%'
        }
      }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid #E5E7EB',
        p: 3
      }}>
        <Typography 
          variant="h6" 
          sx={{ 
            color: '#101828',
            fontWeight: 600,
            fontFamily: 'Plus Jakarta Sans',
            fontSize: '18px'
          }}
        >
          Contáctanos
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Typography 
          variant="body2" 
          sx={{ 
            mb: 3,
            mt: 1,
            color: '#475467',
            fontFamily: 'Inter',
            fontSize: '14px'
          }}
        >
          Déjanos tus datos y nos pondremos en contacto contigo a la brevedad.
        </Typography>
        {submitStatus.message && (
          <Alert 
            severity={submitStatus.type} 
            sx={{ mb: 2 }}
            onClose={() => setSubmitStatus({ type: '', message: '' })}
          >
            {submitStatus.message}
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            required
            name="name"
            label="Nombre"
            value={formData.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
            fullWidth
            sx={{
              backgroundColor: '#fff',
              '& .MuiOutlinedInput-root': {
                height: '44px',
                fontSize: '14px',
                color: '#101828',
                '& fieldset': {
                  borderColor: '#E5E7EB',
                },
                '&:hover fieldset': {
                  borderColor: '#F79009',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#F79009',
                },
                '&.Mui-error fieldset': {
                  borderColor: '#d32f2f',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#6B7280',
                fontSize: '14px',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#F79009',
              },
              '& .MuiFormHelperText-root': {
                marginLeft: 0,
                fontFamily: 'Inter',
              },
            }}
          />
          <TextField
            required
            name="email"
            label="Email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            fullWidth
            sx={{
              backgroundColor: '#fff',
              '& .MuiOutlinedInput-root': {
                height: '44px',
                fontSize: '14px',
                color: '#101828',
                '& fieldset': {
                  borderColor: '#E5E7EB',
                },
                '&:hover fieldset': {
                  borderColor: '#F79009',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#F79009',
                },
                '&.Mui-error fieldset': {
                  borderColor: '#d32f2f',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#6B7280',
                fontSize: '14px',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#F79009',
              },
              '& .MuiFormHelperText-root': {
                marginLeft: 0,
                fontFamily: 'Inter',
              },
            }}
          />
          <TextField
            required
            name="phone"
            label="Teléfono"
            type="tel"
            value={formData.phone}
            onChange={handleChange}
            error={!!errors.phone}
            helperText={errors.phone}
            fullWidth
            sx={{
              backgroundColor: '#fff',
              '& .MuiOutlinedInput-root': {
                height: '44px',
                fontSize: '14px',
                color: '#101828',
                '& fieldset': {
                  borderColor: '#E5E7EB',
                },
                '&:hover fieldset': {
                  borderColor: '#F79009',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#F79009',
                },
                '&.Mui-error fieldset': {
                  borderColor: '#d32f2f',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#6B7280',
                fontSize: '14px',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#F79009',
              },
              '& .MuiFormHelperText-root': {
                marginLeft: 0,
                fontFamily: 'Inter',
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ 
        p: 3, 
        borderTop: '1px solid #E5E7EB',
        gap: 2
      }}>
        <Button
          onClick={onClose}
          sx={{
            color: '#344054',
            textTransform: 'none',
            fontWeight: 500,
            fontFamily: 'Inter',
            fontSize: '14px',
            '&:hover': {
              backgroundColor: '#F9FAFB'
            }
          }}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={loading}
          onClick={handleSubmit}
          sx={{
            backgroundColor: '#F79009',
            color: '#fff',
            textTransform: 'none',
            fontWeight: 500,
            fontFamily: 'Inter',
            fontSize: '14px',
            '&:hover': {
              backgroundColor: '#E27D08'
            },
            '&.Mui-disabled': {
              backgroundColor: '#FDA94E',
              color: '#fff'
            }
          }}
        >
          {loading ? 'Enviando...' : 'Enviar'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ContactModal 