import GraphqlService from './graphql.service'
import AuthService from './auth-service'

class SocialAuthService {
  initGoogleClient() {
    if (!window.google) return

    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: this.handleGoogleResponse
    })
  }

  handleGoogleResponse = (response) => {
    if (!response.credential) {
      console.error('No credentials received from Google')
      return
    }

    // Decodificar el token JWT que viene de Google
    const payload = JSON.parse(atob(response.credential.split('.')[1]))

    // Preparar datos para el backend
    const loginData = {
      source: 'google',
      email: payload.email,
      firstname: payload.given_name,
      lastname: payload.family_name,
      picture: payload.picture,
      accessToken: response.credential
    }

    // Llamar a nuestro backend
    fetch('/auth/google/callback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-user-platform': 'web'
      },
      body: JSON.stringify(loginData)
    })
    .then(result => {
      if (!result.ok) {
        throw new Error('Failed to authenticate with the server')
      }

      // Si la respuesta es una redirección, seguirla
      if (result.redirected) {
        window.location.href = result.url
        return
      }

      // Si no hay redirección, procesar la respuesta
      return result.json()
    })
    .then(data => {
      if (data && data.access_token) {
        AuthService.setToken(data.access_token)
        window.location.href = '/'
      }
    })
    .catch(error => {
      console.error('Error during Google login:', error)
      // Aquí puedes manejar el error como prefieras
    })
  }

  loginWithGoogle = () => {
    if (!window.google) {
      console.error('Google Sign-In SDK not loaded')
      return
    }

    // Inicializar el cliente si no se ha hecho
    this.initGoogleClient()

    // Mostrar el popup de Google
    window.google.accounts.id.prompt()
  }

  loginWithFacebook = () => {
    // Verificar que FB esté disponible
    if (!window.FB) {
      console.error('Facebook SDK not loaded')
      return
    }

    window.FB.login(function(response) {
      if (response.authResponse) {
        // Obtener información del usuario
        window.FB.api('/me', { fields: 'email,first_name,last_name,picture' }, function(userInfo) {
          if (userInfo.error) {
            console.error('Error getting user info:', userInfo.error)
            return
          }

          // Preparar datos para el backend
          const loginData = {
            source: 'facebook',
            email: userInfo.email,
            firstname: userInfo.first_name,
            lastname: userInfo.last_name,
            picture: userInfo.picture?.data?.url,
            accessToken: response.authResponse.accessToken,
          }

          // Llamar a nuestro backend
          fetch('/auth/facebook/callback', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-user-platform': 'web'
            },
            body: JSON.stringify(loginData)
          })
          .then(result => {
            if (!result.ok) {
              throw new Error('Failed to authenticate with the server')
            }

            // Si la respuesta es una redirección, seguirla
            if (result.redirected) {
              window.location.href = result.url
              return
            }

            // Si no hay redirección, procesar la respuesta
            return result.json()
          })
          .then(data => {
            if (data && data.access_token) {
              AuthService.setToken(data.access_token)
              window.location.href = '/'
            }
          })
          .catch(error => {
            console.error('Error during Facebook login:', error)
            // Aquí puedes manejar el error como prefieras
          })
        })
      } else {
        console.log('User cancelled login or did not fully authorize.')
      }
    }, { scope: 'email,public_profile' })
  }

  loginWithApple = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/apple`
  }

  getUser = async () => {
    const query = `
      query GetUser {
        me {
          id
          firstname
          lastname
          email
          dni
          gender
          phone
          birth_date
          preferences
          news_subscription
          roles
          resale_sign
          resale_contract_url
          picture
          source
          created_at
          updated_at
          deleted_at
        }
      }
    `
    return await GraphqlService.request({
      query,
      operationName: 'GetUser',
    })
  }
}

export default new SocialAuthService() 