// src/hooks/useDeleteUser.js

import { gql, useMutation } from '@apollo/client'

const DELETE_CUSTOMER_USER = gql`
  mutation DeleteCustomerUser($id: ID!) {
    deleteCustomerUser(id: $id)
  }
`

const BATCH_DELETE_CUSTOMER_USERS = gql`
  mutation BatchDeleteCustomerUsers($ids: [ID!]!) {
    batchDeleteCustomerUser(ids: $ids)
  }
`

export const useDeleteUser = () => {
  const [deleteUserMutation] = useMutation(DELETE_CUSTOMER_USER)
  const [batchDeleteUsersMutation] = useMutation(BATCH_DELETE_CUSTOMER_USERS)

  const deleteUser = async (id) => {
    try {
      const { data } = await deleteUserMutation({
        variables: { id }
      })
      return data.deleteCustomerUser
    } catch (error) {
      throw error
    }
  }

  const batchDeleteUsers = async (ids) => {
    try {
      const { data } = await batchDeleteUsersMutation({
        variables: { ids }
      })
      return data.batchDeleteCustomerUser
    } catch (error) {
      throw error
    }
  }

  return {
    deleteUser,
    batchDeleteUsers
  }
}
