// LandingPage.js
import React, { useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { ThemeProvider } from '@mui/material/styles'

import { Benefits, FAQ, Hero, LandingFooter, LandingHeader, Testimonial, CallToAction } from '../../organisms'
import theme from './theme'
import ContactModal from '../../molecules/ContactModal/ContactModal'

const LandingPage = () => {
  const faqRef = useRef(null)
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)

  const handleOpenContactModal = () => {
    setIsContactModalOpen(true)
  }

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false)
  }

  const scrollToFAQ = () => {
    faqRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  return (
    <ThemeProvider theme={theme}>
      <Box 
        sx={{ 
          backgroundColor: 'inherit',
          color: '#fff', 
          fontFamily: 'Inter, sans-serif',
          width: '100%',
          margin: 0,
          padding: 0,
          overflow: 'hidden',
          paddingTop: { xs: '64px', md: '80px' },
        }}
      >
        <LandingHeader scrollToFAQ={scrollToFAQ} onContact={handleOpenContactModal} />
        <main>
          <Hero />
          <Benefits onContact={handleOpenContactModal} />
          <Testimonial />
          <FAQ ref={faqRef} onContact={handleOpenContactModal} />
          <CallToAction onContact={handleOpenContactModal} />
        </main>
        <LandingFooter />
        <ContactModal 
          open={isContactModalOpen} 
          onClose={handleCloseContactModal} 
        />
      </Box>
    </ThemeProvider>
  )
}

export default LandingPage
