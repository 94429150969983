import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ApolloProvider } from '@apollo/client'
import { CssBaseline } from '@mui/material'

import App from 'App'
import { AuthContextProvider, MangoControllerProvider } from 'context'
import client from './services/apollo-service'

Sentry.init({
  dsn: 'https://91270e621f8ab618df379f5102847db8@o4508393889464320.ingest.us.sentry.io/4508393891102720',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const rootElement = document.getElementById('root')
rootElement.style.margin = 0
rootElement.style.padding = 0
const root = createRoot(rootElement)

const MemoizedAuthContextProvider = React.memo(AuthContextProvider)
const MemoizedMangoControllerProvider = React.memo(MangoControllerProvider)

root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <MemoizedAuthContextProvider>
        <MemoizedMangoControllerProvider>
          <ApolloProvider client={client}>
            <CssBaseline />
            <App />
          </ApolloProvider>
        </MemoizedMangoControllerProvider>
      </MemoizedAuthContextProvider>
    </GoogleOAuthProvider>
  </BrowserRouter>
)
