import React from 'react'
import { Button } from '@mui/material'

const FormButton = ({ children, ...props }) => {
  return (
    <Button
      variant="contained"
      sx={{
        borderRadius: '20px',
        padding: '10px 20px',
        fontWeight: 500,
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

export default FormButton
