// components/organisms/TicketSection.jsx

import React, { useCallback, useEffect, useState } from 'react'
import { Box, IconButton, Paper, Typography } from '@mui/material'

import { ReactComponent as EditIcon } from '../../../assets/mango/icons/edit.svg'
import { LoadingSpinner, SnackbarAlert } from '../../atoms'
import { TicketItem } from '../../molecules'
import { TicketEditModal } from '../index'
import useListEventItemsData from '../../../hooks/events/useListEventItems'
import useUpdateEventItem from '../../../hooks/events/useUpdateEventItems'
import useCreateEventItems from '../../../hooks/events/useCreateEventItems'
import useDeleteEventItem from '../../../hooks/events/useDeleteEventItem'

const TicketSection = React.memo(({ eventId, sx = {} }) => {
  const [tickets, setTickets] = useState([])
  const [selectedTicketIndex, setSelectedTicketIndex] = useState(0)
  const [open, setOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  // Fetch tickets
  const {
    eventItems: fetchedTickets,
    initialLoading,
    loading,
    error,
    refreshData: refreshTickets,
  } = useListEventItemsData(eventId, 'ENTRANCE')

  // Update handlers
  const { updateItems: updateTickets } = useUpdateEventItem()
  const { createItems } = useCreateEventItems()
  const { deleteItems } = useDeleteEventItem()

  // Handle errors
  useEffect(() => {
    if (error) {
      setErrorMessage(error.message)
      setSnackbarOpen(true)
    }
  }, [error])

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
    setErrorMessage('')
    setSuccessMessage('')
  }

  // Update tickets state
  useEffect(() => {
    if (fetchedTickets) {
      setTickets(fetchedTickets)
    }
  }, [fetchedTickets])

  const handleTicketHover = useCallback((index) => {
    setSelectedTicketIndex(index)
  }, [])

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const handleSave = useCallback(
    async (updatedTickets, deletedTicketIds) => {
      try {
        const existingTickets = updatedTickets.filter((ticket) => !ticket.isNew)
        const newTickets = updatedTickets.filter((ticket) => ticket.isNew)

        if (existingTickets.length > 0) {
          await updateTickets(eventId, existingTickets)
        }

        if (newTickets.length > 0) {
          await createItems(eventId, newTickets)
        }

        if (deletedTicketIds.length > 0) {
          await deleteItems(eventId, deletedTicketIds)
        }

        refreshTickets()
        setOpen(false)
        setSuccessMessage('Tickets actualizados con éxito.')
        setSnackbarOpen(true)
      } catch (error) {
        console.error('Error saving tickets:', error)
        setErrorMessage('Error al guardar los tickets.')
        setSnackbarOpen(true)
      }
    },
    [eventId, updateTickets, createItems, deleteItems, refreshTickets]
  )

  if (initialLoading) return <LoadingSpinner />

  if (error && !tickets.length) {
    return (
      <Box sx={{ ...sx, color: 'error.main' }}>
        <Typography variant="h6" color="error">
          {errorMessage}
        </Typography>
      </Box>
    )
  }

  return (
    <Paper
      elevation={3}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: { xs: '12px', sm: '16px', md: '18px' },
        backgroundColor: '#07181e',
        padding: { xs: 2, sm: 2.5, md: 3 },
        color: '#fff',
        overflowY: 'auto',
        ...sx,
      }}
    >
      {/* Título de la sección */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          marginBottom: { xs: 1.5, sm: 2 },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: '#fff',
            marginBottom: { xs: 0.5, sm: 0 },
            fontSize: { xs: '1.25rem', sm: '1.5rem' },
          }}
        >
          Tickets
        </Typography>
        <IconButton 
          aria-label="Editar Tickets" 
          sx={{ 
            color: '#fff',
            padding: { xs: 1, sm: 1.5 },
          }} 
          onClick={handleOpen}
        >
          <EditIcon />
        </IconButton>
      </Box>

      {/* Lista de tickets */}
      <Box
        sx={{
          maxHeight: { xs: '250px', sm: '300px', md: '300px' },
          overflowY: 'auto',
          pr: 1,
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '3px',
          },
        }}
      >
        {tickets
          .sort((a, b) => a.priority - b.priority)
          .map((ticket, index) => (
            <TicketItem
              key={ticket.id}
              name={ticket.name}
              description={ticket.description}
              price={`$ ${parseInt(ticket.price).toLocaleString('es-CL')}`}
              quantity={ticket.stock}
              selected={selectedTicketIndex === index}
              onMouseEnter={() => handleTicketHover(index)}
            />
          ))}
      </Box>

      {/* Edit Tickets Modal */}
      <TicketEditModal
        open={open}
        onClose={handleClose}
        tickets={tickets}
        setTickets={setTickets}
        handleSave={handleSave}
      />

      {/* Snackbar Alert */}
      <SnackbarAlert
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={errorMessage || successMessage}
        severity={errorMessage ? 'error' : 'success'}
      />
    </Paper>
  )
})

export default TicketSection
