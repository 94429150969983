import GraphqlService from './graphql.service'
import { parseCashierRole } from '../utils/roles'

export const createCashier = async (cashier) => {
  const query = `
  mutation CreateCashier($input: CreateCashierData!) {
    createCashier(input: $input) {
      id
      email
      role
      name
    }
}
  `

  const variables = {
    input: {
      email: cashier.email,
      password: cashier.password,
      role: parseCashierRole(cashier.role),
      name: cashier.name,
    },
  }

  return await GraphqlService.request({ query, variables, operationName: 'CreateCashier' })
}

export const updateCashier = async (updatedInfo) => {
  const query = `
  mutation UpdateCashier($input: UpdateCashierData!) {
    updateCashier(input: $input) {
      id
      email
      role
      name
    }
}
  `

  const variables = {
    input: {
      id: updatedInfo.id,
      email: updatedInfo.email,
      password: updatedInfo.password,
      role: parseCashierRole(updatedInfo.role),
      name: updatedInfo.name,
    },
  }

  return await GraphqlService.request({ query, variables, operationName: 'UpdateCashier' })
}

export const changeCashierPassword = async (id, password) => {
  const query = `
  mutation ChangeCashierPassword($input: ChangeCashierPasswordData!) {
    changeCashierPassword(input: $input) {
      id
      email
      role
      name
    }
}
  `

  const variables = {
    input: {
      id,
      password,
    },
  }

  return await GraphqlService.request({ query, variables, operationName: 'ChangeCashierPassword' })
}

export const deleteCashier = async (id) => {
  const query = `
  mutation DeleteCashier($input: DeleteCashierData!) {
    deleteCashier(input: $input) {
      id
      email
      role
      name
    }
}
  `

  const variables = {
    input: {
      id,
    },
  }

  return await GraphqlService.request({ query, variables, operationName: 'DeleteCashier' })
}
