// src/hooks/userManagement/useSendCourtesies.js

/**
 * Este hook maneja la funcionalidad de envío de cortesías a usuarios.
 * Utiliza una mutación GraphQL para enviar cortesías a uno o varios usuarios
 * para un evento específico.
 * 
 * @module useSendCourtesies
 */

import { gql, useMutation } from '@apollo/client'
import { useNotification } from '../../contexts/NotificationContext'

// Definición de la mutación GraphQL para enviar cortesías
const SEND_COURTESIES = gql`
  mutation SendCourtesies($input: SendCourtesiesInput!) {
    sendCourtesies(input: $input) {
      success
      message
    }
  }
`

/**
 * Hook personalizado para manejar el envío de cortesías.
 * 
 * @returns {Object} Objeto con la función sendCourtesies y el estado de loading
 */
export const useSendCourtesies = () => {
  // Inicializar la mutación de Apollo y obtener el estado de loading
  const [sendCourtesiesMutation, { loading }] = useMutation(SEND_COURTESIES)
  // Hook para mostrar notificaciones en la UI
  const { showNotification } = useNotification()

  /**
   * Función para enviar cortesías a uno o varios usuarios.
   * TEMPORAL: Actualmente solo muestra una notificación de éxito sin realizar la mutación
   * 
   * @param {Object} params - Parámetros para enviar cortesías
   * @param {Array|string} params.userIds - ID o array de IDs de usuarios
   * @param {string} params.eventId - ID del evento
   * @param {number} params.amount - Cantidad de cortesías a enviar
   * @returns {Promise} Resultado simulado de éxito
   */
  const sendCourtesies = async ({ userIds, eventId, amount }) => {
    // TEMPORAL: Mostrar solo notificación de éxito
    showNotification('Cortesías enviadas exitosamente', 'success')
    
    // COMENTADO: Llamada real a la API
    /*
    try {
      const { data } = await sendCourtesiesMutation({
        variables: {
          input: {
            userIds: Array.isArray(userIds) ? userIds : [userIds],
            eventId,
            amount: parseInt(amount, 10)
          }
        }
      })
      return data.sendCourtesies
    } catch (error) {
      throw error
    }
    */

    // Devolver un objeto simulado de éxito
    return {
      success: true,
      message: 'Cortesías enviadas exitosamente'
    }
  }

  return {
    sendCourtesies,
    loading: false // TEMPORAL: Forzar loading a false ya que no hay llamada real
  }
}
