import { useState, useEffect } from 'react';
import graphqlService from '../../services/graphql.service';

export const useUser = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const data = await graphqlService.getMe();
      console.log('User data received:', data);
      setUserData(data);
      setError(null);
    } catch (err) {
      console.error('Error in useUser hook:', err);
      setError(err);
      setUserData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const refreshUserData = () => {
    fetchUserData();
  };

  const clearUserData = () => {
    setUserData(null);
  };

  return {
    user: userData?.user,
    orders: userData?.orders,
    tickets: userData?.tickets,
    drinks: userData?.drinks,
    events: userData?.events,
    loading,
    error,
    refreshUserData,
    clearUserData
  };
}; 