// CustomerEventsTable.jsx
import React, { memo, useCallback } from 'react'
import { Box, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import { parseIsoDate } from '../../../utils/date'
import { statusColors, statusText } from '../../../utils/constants'

const CustomerEventsTable = ({ height = '100%', events = [] }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleMetricsClick = useCallback(
    (eventId, e) => {
      e.stopPropagation()
      navigate(`/events/${eventId}/metrics`)
    },
    [navigate]
  )

  const handleRowClick = useCallback(
    (eventId) => {
      navigate(`/events/${eventId}/details`)
    },
    [navigate]
  )

  return (
    <TableContainer 
      component={Paper} 
      sx={{ 
        width: '100%',
        height,
        borderRadius: { xs: 2, sm: 3 },
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#666',
          borderRadius: '4px',
        },
      }}
    >
      <Table 
        sx={{ 
          minWidth: { xs: 'auto', sm: 650 }, 
          marginBottom: '10px',
          tableLayout: isMobile ? 'fixed' : 'auto',
        }} 
        aria-label="customer events table"
      >
        <TableHead sx={{ display: 'table-header-group' }}>
          <TableRow>
            <TableCell 
              sx={{ 
                width: { xs: '35%', sm: '10%' },
                padding: { xs: '8px 0px 8px 8px', sm: 2 },
              }}
            >
            </TableCell>
            <TableCell 
              sx={{ 
                width: { xs: '65%', sm: '30%' },
                padding: { xs: '8px 8px 8px 0px', sm: 2 },
                textAlign: { xs: 'center', sm: 'left' },
              }}
            >
              <Typography 
                variant="h6" 
                fontWeight="bold" 
                color="white"
                sx={{ 
                  fontSize: { xs: '0.9rem', sm: '1rem', md: '1.25rem' },
                  textAlign: { xs: 'center', sm: 'left' },
                  ml: { xs: '-35%', sm: 0 },
                }}
              >
                Nombre
              </Typography>
            </TableCell>
            <TableCell 
              sx={{ 
                width: { xs: '40%', sm: '30%' },
                padding: { xs: 1, sm: 2 },
                display: { xs: 'none', sm: 'table-cell' },
              }}
            >
              <Box ml={4} display="flex" alignItems="left">
                <Typography 
                  variant="h6" 
                  fontWeight="bold" 
                  color="white"
                  sx={{ fontSize: { xs: '0.9rem', sm: '1rem', md: '1.25rem' } }}
                >
                  Fecha
                </Typography>
              </Box>
            </TableCell>
            <TableCell 
              sx={{ 
                width: { xs: '40%', sm: '30%' },
                padding: { xs: 1, sm: 2 },
                display: { xs: 'none', sm: 'table-cell' },
              }}
            >
              <Box justifyContent="center" display="flex" alignItems="left">
                <Typography 
                  variant="h6" 
                  fontWeight="bold" 
                  color="white"
                  sx={{ fontSize: { xs: '0.9rem', sm: '1rem', md: '1.25rem' } }}
                >
                  Estado
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map((eventItem) => (
            <TableRow 
              key={eventItem.id} 
              hover 
              onClick={() => handleRowClick(eventItem.id)} 
              sx={{ 
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05) !important',
                },
              }}
            >
              <TableCell 
                sx={{ 
                  width: { xs: '35%', sm: '10%' },
                  padding: { xs: '8px 0px 8px 8px', sm: 2 },
                }}
              >
                <Box 
                  display="flex" 
                  justifyContent="center" 
                  alignItems="center"
                >
                  <Avatar
                    variant="square"
                    src={eventItem.image}
                    alt={eventItem.name}
                    sx={{ 
                      width: { xs: 60, sm: 55, md: 65 }, 
                      height: { xs: 60, sm: 55, md: 65 }, 
                      borderRadius: 3,
                    }}
                  />
                </Box>
              </TableCell>
              <TableCell 
                align="center"
                sx={{ 
                  width: { xs: '65%', sm: '30%' },
                  padding: { xs: '8px 8px 8px 0px', sm: 2 },
                  textAlign: { xs: 'center', sm: 'left' },
                }}
              >
                <Typography 
                  variant="body1" 
                  fontWeight="bold"
                  sx={{ 
                    fontSize: { xs: '0.875rem', sm: '0.9375rem', md: '1rem' },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    width: '100%',
                    textAlign: { xs: 'left', sm: 'left' },
                    paddingLeft: { xs: '24px', sm: 0 },
                  }}
                >
                  {eventItem.name}
                </Typography>
              </TableCell>
              <TableCell 
                sx={{ 
                  padding: { xs: 1, sm: 2 },
                  display: { xs: 'none', sm: 'table-cell' },
                }}
              >
                <Box display="flex" alignItems="left">
                  <Typography 
                    variant="body1" 
                    color="textSecondary"
                    sx={{ fontSize: { xs: '0.875rem', sm: '0.9375rem', md: '1rem' } }}
                  >
                    {parseIsoDate(eventItem.start_at)}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell 
                sx={{ 
                  padding: { xs: 1, sm: 2 },
                  display: { xs: 'none', sm: 'table-cell' },
                }}
              >
                <Box display="flex" justifyContent="center">
                  <Chip
                    label={statusText[eventItem.status]}
                    color={statusColors[eventItem.status]}
                    sx={{ 
                      width: { xs: '80px', sm: '88px' }, 
                      height: { xs: '20px', sm: '24px' }, 
                      borderRadius: '20px',
                      '& .MuiChip-label': {
                        fontSize: { xs: '0.75rem', sm: '0.8125rem' },
                        padding: { xs: '0 8px', sm: '0 12px' },
                      },
                    }}
                  />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default memo(CustomerEventsTable)
