// src/hooks/useCreateUser.js

import { gql, useMutation } from '@apollo/client'

const CREATE_CUSTOMER_USER = gql`
  mutation CreateCustomerUser($input: CustomerUserData!) {
    createCustomerUser(input: $input) {
      id
      external_id
      email
      name
      created_at
      updated_at
    }
  }
`

export const useCreateUser = () => {
  const [createUserMutation, { loading }] = useMutation(CREATE_CUSTOMER_USER)

  const createUser = async (userData) => {
    try {
      const { data } = await createUserMutation({
        variables: {
          input: {
            name: userData.name,
            email: userData.email
          }
        }
      })
      return data.createCustomerUser
    } catch (error) {
      throw error
    }
  }

  return {
    createUser,
    loading
  }
}
