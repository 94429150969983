export const parseCashierRole = (role) => {
  if (!role) return null
  switch (role?.toLowerCase()) {
    case 'portero':
      return 'doorman'
    case 'barra':
      return 'barman'
    case 'cajero':
      return 'cashier'
    default:
      return role
  }
}
