import { useEffect, useState } from 'react'
import { gql, useQuery, useSubscription } from '@apollo/client'

const GET_TICKET_SALES = gql`
  query GetTicketSales($input: TotalSalesInput!) {
    ticketSales(input: $input) {
      name
      sold
      total
      revenue
    }
  }
`

const TICKET_SALES_SUBSCRIPTION = gql`
  subscription OnTicketSalesUpdated($input: TotalSalesInput!) {
    ticketSalesUpdated(input: $input) {
      name
      sold
      total
      revenue
    }
  }
`

export const useTicketSalesData = ({ eventId }) => {
  const [ticketData, setTicketData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useQuery(GET_TICKET_SALES, {
    variables: { input: { event_id: eventId } }
  })

  const { data: subscriptionData, error: subscriptionError } = useSubscription(TICKET_SALES_SUBSCRIPTION, {
    variables: { input: { event_id: eventId } },
  })

  useEffect(() => {
    if (queryData) {
      setTicketData(queryData.ticketSales)
      setLoading(false)
    }
    if (queryError) {
      setError(queryError)
      setLoading(false)
    }
  }, [queryData, queryError])

  useEffect(() => {
    if (subscriptionData) {
      setTicketData(subscriptionData.ticketSalesUpdated)
    }
    if (subscriptionError) {
      setError(subscriptionError)
    }
  }, [subscriptionData, subscriptionError])

  return {
    ticketData,
    loading: loading || queryLoading,
    error,
  }
}
