import PropTypes from 'prop-types'
import { useMangoController } from '../../../context'
import { Box } from '../../atoms'
import { useIsMobile } from '../../../hooks/useIsMobile'

function DashboardLayout({ children }) {
  const [controller] = useMangoController()
  const { miniSidenav } = controller
  const isMobile = useIsMobile()

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      sx={{
        position: 'relative',
        width: '100%',
        maxWidth: '100vw',
        overflowX: 'hidden',
        px: { xs: 2, sm: 2, md: 3 }
      }}
    >
      {children}
    </Box>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DashboardLayout
