import { useEffect, useState } from 'react'
import { gql, useQuery, useSubscription } from '@apollo/client'

const STATISTICS_QUERY = gql`
  query GetStatistics {
    statistics {
      name
      value
      icon
      format
      variations {
        type
        amount
        interval
        format
      }
    }
  }
`

const STATISTICS_SUBSCRIPTION = gql`
  subscription OnStatisticsUpdated {
    statisticsUpdated {
      name
      value
      icon
      format
      variations {
        type
        amount
        interval
        format
      }
    }
  }
`

export const useStatisticsData = () => {
  const [statisticsData, setStatisticsData] = useState([])
  const { data: queryData, loading: queryLoading, error: queryError } = useQuery(STATISTICS_QUERY)
  const {
    data: subscriptionData,
    loading: subscriptionLoading,
    error: subscriptionError,
  } = useSubscription(STATISTICS_SUBSCRIPTION)

  useEffect(() => {
    if (queryData) {
      setStatisticsData(queryData.statistics)
    }
  }, [queryData])

  useEffect(() => {
    if (subscriptionData) {
      setStatisticsData(subscriptionData.statisticsUpdated)
    }
  }, [subscriptionData])

  const loading = queryLoading && !queryData

  const error = queryError || subscriptionError

  return {
    statisticsData,
    loading,
    error,
  }
}
