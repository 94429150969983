import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { ErrorComponent, Typography } from '../../atoms'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorComponent
          message={
            <>
              <Typography variant="h4" color="error">
                Something went wrong.
              </Typography>
              <Typography variant="body1">{this.state.error && this.state.error.toString()}</Typography>
              <Typography variant="body2">{this.state.errorInfo.componentStack}</Typography>
            </>
          }
        />
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary
