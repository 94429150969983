import { forwardRef, memo } from 'react'
import PropTypes from 'prop-types'

import { BoxStyle } from './Box.style'

const Box = forwardRef(
  (
    {
      variant = 'contained',
      bgColor = 'transparent',
      color = 'dark',
      opacity = 1,
      borderRadius = 'none',
      shadow = 'none',
      coloredShadow = 'none',
      ...rest
    },
    ref
  ) => {
    const ownerState = { variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow }

    return <BoxStyle {...rest} ref={ref} ownerState={ownerState} />
  }
)

Box.propTypes = {
  variant: PropTypes.oneOf(['contained', 'gradient']),
  bgColor: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.number,
  borderRadius: PropTypes.string,
  shadow: PropTypes.string,
  coloredShadow: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
    'none',
  ]),
}

export default memo(Box)
