// src/hooks/useUsersData.js

import { gql, useQuery } from '@apollo/client'

const LIST_CUSTOMER_USERS = gql`
  query ListCustomerUsers {
    listCustomerUsers {
      id
      external_id
      email
      name
      created_at
      updated_at
    }
  }
`

export const useUsersData = () => {
  const { data, loading, error, refetch } = useQuery(LIST_CUSTOMER_USERS)

  return {
    usersData: data?.listCustomerUsers || [],
    loading,
    error,
    refetch
  }
}
