// src/components/StatisticVariation/index.jsx

import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'
import Box from '@mui/material/Box'

import { Typography } from '../../atoms'

function getIcon(variation) {
  if (variation.type === 'INCREASE') return 'arrow_drop_up'
  if (variation.type === 'DECREASE') return 'arrow_drop_down'
  return 'remove'
}

function getColor(variation) {
  if (variation.type === 'INCREASE') return 'success'
  if (variation.type === 'DECREASE') return 'error'
  return 'text'
}

function getOperation(variation) {
  if (variation.type === 'INCREASE') return 'más que'
  if (variation.type === 'DECREASE') return 'menos que'
  if (variation.type === 'NONE') return 'métrica no disponible'
  return ''
}

function getInterval(variation) {
  if (variation.type === 'NONE') return ''
  if (variation.interval === 'DAY') return 'el día de ayer'
  if (variation.interval === 'WEEK') return 'la semana pasada'
  if (variation.interval === 'MONTH') return 'el mes pasado'
  if (variation.interval === 'YEAR') return 'el año pasado'
  return ''
}

function getAmount(variation) {
  if (variation.type === 'NONE') return ''
  if (variation.format === 'PERCENTAGE') return `${variation.amount}%`
  return variation.amount
}

function StatisticVariation({ variation, type }) {
  return (
    <Typography
      component="p"
      variant="button"
      display="flex"
      alignItems="center"
      color={getColor(variation)}
      sx={{ 
        mb: 0.5,
        whiteSpace: 'nowrap',
        fontSize: { xs: '0.75rem', sm: '0.75rem', md: '0.75rem' },
        overflow: { sm: 'hidden' },
        textOverflow: { sm: 'ellipsis' },
        width: { sm: '100%' },
        '& .MuiIcon-root': {
          flexShrink: 0
        }
      }}
    >
      <Icon fontSize="small" sx={{ mr: 0.5 }}>
        {getIcon(variation)}
      </Icon>
      <Typography 
        component="span" 
        variant="button" 
        fontWeight="bold" 
        color={getColor(variation)}
        sx={{
          fontSize: { xs: '0.75rem', sm: '0.75rem', md: '0.75rem' },
          flexShrink: 0
        }}
      >
        {getAmount(variation, type)}
      </Typography>
      <Box component="span" sx={{ overflow: { sm: 'hidden' }, textOverflow: { sm: 'ellipsis' } }}>
        &nbsp;{getOperation(variation)} {getInterval(variation)}
      </Box>
    </Typography>
  )
}

StatisticVariation.propTypes = {
  variation: PropTypes.shape({
    type: PropTypes.string.isRequired,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
}

export default StatisticVariation
