// components/organisms/BarEditModal.jsx

import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, Modal, Typography } from '@mui/material'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { BarFormItemModal, CSVUploader } from '../../molecules'
import { ReactComponent as AddCircleIcon } from '../../../assets/mango/icons/add-circle-outline.svg'

const BarEditModal = ({ open, onClose, barItems, setBarItems, handleSave }) => {
  const [localBarItems, setLocalBarItems] = useState([])
  const [expandedBarId, setExpandedBarId] = useState(null)
  const [nextBarId, setNextBarId] = useState(1)
  const [deletedBarIds, setDeletedBarIds] = useState([])

  useEffect(() => {
    if (open) {
      setLocalBarItems([...barItems])
      setExpandedBarId(barItems.length > 0 ? barItems[0].id : null)
      setNextBarId(barItems.length + 1)
      setDeletedBarIds([])
    }
  }, [open, barItems.length])

  const handleAddBar = useCallback(() => {
    const newBarId = nextBarId
    const newBar = {
      id: newBarId,
      isNew: true,
      name: '',
      description: '',
      price: '',
      formattedPrice: '',
      stock: '',
      image: null,
      imageName: '',
      priority: localBarItems.length,
      type: 'DRINK',
    }
    setLocalBarItems((prevItems) => [...prevItems, newBar])
    setNextBarId((prevId) => prevId + 1)
    setExpandedBarId(newBarId)
  }, [nextBarId, localBarItems.length])

  const handleDeleteBar = useCallback(
    (barId) => {
      const barToDelete = localBarItems.find((item) => item.id === barId)
      if (barToDelete) {
        if (!barToDelete.isNew) {
          setDeletedBarIds((prev) => [...prev, barId])
        }
        const newBars = localBarItems.filter((item) => item.id !== barId)
        setLocalBarItems(newBars)
        if (expandedBarId === barId) {
          setExpandedBarId(newBars.length > 0 ? newBars[0].id : null)
        }
      }
    },
    [localBarItems, expandedBarId]
  )

  const handleExpandBar = useCallback((barId, isExpanded) => {
    setExpandedBarId(isExpanded ? barId : null)
  }, [])

  const handleDragEnd = useCallback(
    (result) => {
      if (!result.destination) return
      const newBars = Array.from(localBarItems)
      const [movedBar] = newBars.splice(result.source.index, 1)
      newBars.splice(result.destination.index, 0, movedBar)
      const updatedBars = newBars.map((item, index) => ({
        ...item,
        priority: index,
      }))
      setLocalBarItems(updatedBars)
    },
    [localBarItems]
  )

  const parseCSV = useCallback(
    (csvContent) => {
      const separator = csvContent.includes(';') ? ';' : ','

      const lines = csvContent.trim().split('\n')
      const headers = lines[0].split(separator)

      const keyMap = {
        nombre: 'name',
        precio: 'price',
        cantidad: 'stock',
        descripcion: 'description',
      }

      const items = lines.slice(1).map((line, index) => {
        const values = line.split(separator)
        const itemData = {}
        headers.forEach((header, idx) => {
          const key = keyMap[header.trim().toLowerCase()] || header.trim()
          itemData[key] = values[idx]?.trim() || ''
        })

        if (itemData.price) {
          const number = parseInt(itemData.price, 10)
          if (!isNaN(number)) {
            itemData.formattedPrice = '$ ' + number.toLocaleString('es-CL')
          } else {
            itemData.formattedPrice = ''
          }
        } else {
          itemData.formattedPrice = ''
        }

        return {
          id: nextBarId + index,
          isNew: true,
          name: itemData.name || '',
          description: itemData.description || '',
          price: itemData.price || '',
          formattedPrice: itemData.formattedPrice || '',
          stock: itemData.stock || '',
          image: itemData.image || null,
          imageName: itemData.imageName || '',
          priority: localBarItems.length + index,
          type: 'DRINK',
        }
      })
      return items
    },
    [nextBarId, localBarItems.length]
  )

  const handleFileUpload = useCallback(
    (fileContent) => {
      const newBars = parseCSV(fileContent)
      setLocalBarItems((prevItems) => [...prevItems, ...newBars])
      setNextBarId((prevId) => prevId + newBars.length)
    },
    [parseCSV]
  )

  const setBarDataAtIndex = useCallback(
    (index) => (updatedData) => {
      setLocalBarItems((prevBar) => {
        const newBars = [...prevBar]
        newBars[index] = { ...newBars[index], ...updatedData }
        return newBars
      })
    },
    []
  )

  const handleSaveClick = useCallback(() => {
    handleSave(localBarItems, deletedBarIds)
    onClose()
  }, [handleSave, localBarItems, deletedBarIds, onClose])

  const handleCancel = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          // Estilos del modal
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', md: '60%' },
          bgcolor: '#fff',
          boxShadow: 24,
          borderRadius: 2,
          maxHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Contenido del modal con scroll interno */}
        <Box
          sx={{
            p: 4,
            overflowY: 'auto',
            flex: '1 1 auto',
          }}
        >
          <Typography variant="h4" sx={{ mb: 2 }}>
            Editar Barra
          </Typography>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="barItems">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {localBarItems.map((item, index) => (
                    <Draggable key={item.id} draggableId={`bar-${item.id}`} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            marginBottom: '16px',
                          }}
                        >
                          <BarFormItemModal
                            barNumber={item.id}
                            barData={item}
                            setBarData={setBarDataAtIndex(index)}
                            expanded={expandedBarId === item.id}
                            onChange={(isExpanded) => handleExpandBar(item.id, isExpanded)}
                            onDelete={() => handleDeleteBar(item.id)}
                            dragHandleProps={provided.dragHandleProps}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {/* Área para "Agregar nuevo Trago" */}
          <Grid
            container
            width="100%"
            border="1px dashed #000"
            sx={{
              borderRadius: '10px',
              marginTop: '16px',
              '&::before': { display: 'none' },
              padding: '8px 16px',
              alignItems: 'center',
              backgroundColor: '#fff',
            }}
          >
            <Grid item xs={12} sm={10}>
              <Typography variant="h6" sx={{ color: '#000' }}>
                Agregar nuevo trago
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} container justifyContent="flex-end" alignItems="center">
              <IconButton onClick={handleAddBar}>
                <AddCircleIcon style={{ height: '32px', width: '32px', color: '#fb8c00' }} />
              </IconButton>
            </Grid>
          </Grid>

          {/* Componente CSVUploader */}
          <Box mt={2}>
            <CSVUploader handleFileUpload={handleFileUpload} templateLink="/assets/csv/BarTemplate.csv" />
          </Box>
        </Box>

        {/* Botones fijos en la parte inferior */}
        <Box
          sx={{
            p: 2,
            borderTop: '1px solid #ccc',
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: '#fff',
          }}
        >
          <Button onClick={handleCancel} variant="outlined" sx={{ mr: 2 }}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={handleSaveClick}>
            Guardar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default BarEditModal
