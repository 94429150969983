import GraphqlService from './graphql.service'

export const fetchEventDetails = async (id) => {
  const query = `
  query GetEventById($id: String!) {
    getEventById(id: $id) {
      event {
        id
        name
        description
        place
        start_at
        end_at
        start_hour
        end_hour
        active
        image
        categories
        created_at
        updated_at
        deleted_at
        status
        address
      }
    }
}
  `

  const variables = {
    id,
  }
  return await GraphqlService.request({ query, variables, operationName: 'GetEventById' })
  const getEventById = `/events/${id}`
  return 'not implemented'
}

export const updateEventDetails = async (id, updatedEvent) => {
  const { name, address, description, place, start_at, end_at, start_hour, end_hour, image } = updatedEvent
  const query = `
  mutation UpdateEvent($input: UpdateEventData!) {
    updateEvent(input: $input) {
        id
        name
        description
        place
        start_at
        end_at
        image
        categories
        created_at
        updated_at
        deleted_at
    }
}
  `

  const variables = {
    input: {
      id,
      name,
      place,
      description,
      image,
      start_at: new Date(start_at).toISOString(),
      end_at: new Date(end_at).toISOString(),
      start_hour: new Date(start_hour).toISOString(),
      end_hour: new Date(end_hour).toISOString(),
      address,
    },
  }

  return await GraphqlService.request({ query, variables, operationName: 'UpdateEvent' })
  return 'not implemented'
}

export const listEventItems = async (eventId, type) => {
  const query = `
    query ListEventItems($input: ListEventItemsData!) {
      listEventItems(input: $input) {
        id
        event_id
        name
        description
        type
        priority
        stock
        max_per_sale
        price
        resale
        end_at
        end_hour
        created_at
        updated_at
        deleted_at
        out_of_stock
      }
    }
  `

  const variables = {
    input: {
      event_id: eventId,
      type,
    },
  }

  return await GraphqlService.request({ query, variables, operationName: 'ListEventItems' })
}

export const updateEventItem = async (eventId, itemId, updatedItem) => {
  const updateEventItem = `/events/${eventId}/items/${itemId}`
  return 'not implemented'
}

export const fetchEvents = async () => {
  const query = `
  query GetCustomerEvents {
    getCustomerEvents {
        id
        image
        name
        start_at
        end_at
        start_hour
        end_hour
        status
    }
  }
`
  return await GraphqlService.request({ query })
}

export const updateEventStatus = async (eventId, status) => {
  const updateStatus = `/events/${eventId}?status=${status}`
  return 'not implemented'
}

export const deleteEvent = async (eventId) => {
  const deleteEvent = `/events/${eventId}`
  return 'not implemented'
}

function transformTimeToDate(baseDate, time) {
  // Parse the base date
  const date = new Date(baseDate)

  // Split the time string to get hours and minutes
  const [hours, minutes] = time.split(':').map(Number)

  // Set the hours and minutes to the date
  date.setUTCHours(hours)
  date.setUTCMinutes(minutes)

  // Return the date as ISO string in UTC
  return date.toISOString()
}

export const createEvent = async (event) => {
  const query = `
  mutation CreateEvent($input: CreateEventData!) {
    createEvent(input: $input) {
        id
        name
        description
        place
        start_at
        active
        image
        categories
        created_at
        updated_at
        deleted_at
        address
    }
}
  `

  const variables = {
    input: {
      name: event.name,
      place: event.place,
      description: event.description,
      image: event.image,
      start_at: event.start_at,
      end_at: event.end_at,
      start_hour: transformTimeToDate(event.start_at, event.start_hour),
      end_hour: transformTimeToDate(event.end_at, event.end_hour),
      nominated: event.nominated,
      address: event.address,
    },
  }
  return await GraphqlService.request({ query, variables, operationName: 'CreateEvent' })
}

export const batchCreateEventItem = async (eventItems, eventId) => {
  const query = `
mutation BatchCreateEventItem($input: [CreateEventItemData!]!) {
    batchCreateEventItem(input: $input) {
        id
        event_id
        name
        description
        type
        priority
        stock
        max_per_sale
        price
        resale
        end_at
        end_hour
        created_at
        updated_at
        deleted_at
    }
}

`

  const variables = {
    input: eventItems.map((item) => ({
      event_id: eventId,
      name: item.name,
      description: item.description,
      type: item.type,
      priority: item.priority,
      stock: item.stock,
      max_per_sale: 5,
      price: item.price,
      end_at: item.endDate,
      end_hour: item.end_hour ? new Date(item.endHour).toISOString() : null,
      image: item?.image,
    })),
  }

  return await GraphqlService.request({ query, variables, operationName: 'BatchCreateEventItem' })
}

export const batchUpdateEventItem = async (eventItems, eventId) => {
  console.log('eventItems', eventItems)
  const query = `
mutation BatchUpdateEventItem($input: [UpdateEventItemData!]!) {
    batchUpdateEventItem(input: $input) {
        id
        event_id
        name
        description
        type
        priority
        stock
        max_per_sale
        price
        resale
        end_at
        end_hour
        created_at
        updated_at
        deleted_at
        out_of_stock
    }
}

`

  const variables = {
    input: eventItems.map((item) => ({
      id: item.id,
      event_id: eventId,
      name: item.name,
      description: item.description,
      type: item.type,
      priority: item.priority,
      stock: item.stock,
      max_per_sale: 5,
      price: item.price,
      end_at: new Date(item?.end_at).toISOString(),
      end_hour: item.end_hour,
      image: item?.image,
      out_of_stock: item?.out_of_stock,
    })),
  }

  return await GraphqlService.request({ query, variables, operationName: 'BatchUpdateEventItem' })
}

export const batchDeleteEventItems = async (eventItems, eventId) => {
  const query = `
mutation BatchDeleteEventItem($input: BatchDeleteEventItemData!) {
    batchDeleteEventItem(input: $input) {
        id
        }
    }
        
  `

  const variables = {
    input: {
      event_id: eventId,
      ids: eventItems,
    },
  }

  return await GraphqlService.request({ query, variables, operationName: 'BatchDeleteEventItem' })
}
