export const statusColors = {
  EVENT_PENDING: 'warning',
  EVENT_ACTIVE: 'primary',
  EVENT_CANCELED: 'error',
  EVENT_FINISHED: 'secondary',
  EVENT_APPROVED: 'success',
  EVENT_PUBLISHED: 'info',
}

export const statusText = {
  EVENT_PENDING: 'Pendiente',
  EVENT_ACTIVE: 'Activo',
  EVENT_CANCELED: 'Cancelado',
  EVENT_FINISHED: 'Finalizado',
  EVENT_APPROVED: 'Aprobado',
  EVENT_PUBLISHED: 'Publicado',
}
