import React from 'react'
import { Grid, Typography } from '@mui/material'

import { TicketItem } from '../index'

const TicketPreview = ({ tickets, setTickets, isCombined = false }) => {
  const handleIncrement = (index) => {
    const newTickets = [...tickets]
    if (!newTickets[index].data.count) {
      newTickets[index].data.count = 0
    }
    newTickets[index].data.count += 1
    setTickets(newTickets)
  }

  const handleDecrement = (index) => {
    const newTickets = [...tickets]
    if (newTickets[index].data.count > 0) {
      newTickets[index].data.count -= 1
      setTickets(newTickets)
    }
  }

  return (
    <Grid
      container
      width="96%"
      margin="5px"
      pt={2}
      pb={3}
      mt={isCombined ? 3 : 1}
      sx={{
        color: 'white',
        backgroundColor: '#04121A',
        borderRadius: '10px',
        paddingLeft: '5px',
        paddingRight: '5px',
      }}
    >
      <Grid item xs={12} pl={2} mt={1}>
        <Typography variant="h6">Tickets</Typography>
      </Grid>
      {tickets.map((ticket, index) => {
        return (
          <TicketItem
            key={ticket.id}
            ticketNumber={ticket.id}
            name={ticket.data.name}
            price={ticket.data.price}
            count={ticket.data.count || 0}
            onIncrement={() => handleIncrement(index)}
            onDecrement={() => handleDecrement(index)}
          />
        )
      })}
    </Grid>
  )
}

export default TicketPreview
