// components/templates/EventDetailTemplate.jsx

import React from 'react'
import { Box, Grid } from '@mui/material'
import { BarSection, EventInfoSection, ImageSection, TicketSection } from '../../organisms'

const EventDetailTemplate = React.memo(({ eventId, data }) => {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '1259px',
        margin: { xs: 0, sm: 0, md: 0 },
        px: { xs: 1, sm: 2, md: 3 }
      }}
    >
      <Grid 
        container 
        spacing={{ xs: 2, sm: 3, md: 2 }} 
        alignItems="stretch"
      >
        {/* Imagen */}
        <Grid 
          item 
          xs={12} 
          sm={6}
          md={4} 
          order={{ xs: 1, sm: 1, md: 1 }}
          sx={{
            height: { xs: '300px', sm: '400px', md: 'auto' }
          }}
        >
          <ImageSection data={data} eventId={eventId} />
        </Grid>
        {/* Tickets */}
        <Grid 
          item 
          xs={12} 
          sm={6}
          md={8} 
          order={{ xs: 2, sm: 2, md: 2 }}
        >
          <TicketSection eventId={eventId} />
        </Grid>
        {/* Barra */}
        <Grid 
          item 
          xs={12} 
          sm={12}
          md={8} 
          order={{ xs: 4, sm: 4, md: 4 }}
        >
          <BarSection eventId={eventId} />
        </Grid>
        {/* Información */}
        <Grid 
          item 
          xs={12} 
          sm={12}
          md={4} 
          order={{ xs: 3, sm: 3, md: 3 }}
        >
          <EventInfoSection data={data} eventId={eventId} />
        </Grid>
      </Grid>
    </Box>
  )
})

export default EventDetailTemplate
