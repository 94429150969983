import React from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import { useDrinkSalesData } from '../../../hooks/eventDetails/useDrinksSalesData'
import { LoadingSpinner } from '../../atoms'

const BarSales = ({ eventId }) => {
  const { drinksData, loading, error } = useDrinkSalesData({ eventId })

  if (loading) return <LoadingSpinner />

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          textAlign: 'center',
          backgroundColor: '#07181E',
          borderRadius: '10px',
        }}
      >
        <Typography variant="h6" color="error">
          {error.message}
        </Typography>
        <Typography variant="body1">
          Por favor, intenta refrescar la página o contacta a soporte si el problema persiste.
        </Typography>
      </Box>
    )
  }

  const sumRevenue = () => {
    let sum = 0
    drinksData?.forEach((drink) => {
      sum += drink.revenue
    })
    return sum
  }

  return (
    <Box
      sx={{
        backgroundColor: '#07181E',
        padding: {
          xs: '16px',
          sm: '20px',
          md: '30px',
        },
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        color: '#fff',
        height: '100%',
        maxHeight: {
          xs: 'auto',
          sm: '487px'
        },
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {/* Título */}
      <Box
        sx={{
          flexShrink: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: {
            xs: '12px',
            sm: '16px'
          },
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: {
              xs: '16px',
              sm: '18px',
            },
          }}
        >
          Ventas en Barra
        </Typography>
        <IconButton sx={{ color: '#fff', padding: { xs: '4px', sm: '8px' } }}>
          <MoreHorizIcon sx={{ fontSize: { xs: '20px', sm: '24px' } }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          flexShrink: 0,
          width: '100%',
          height: '1px',
          backgroundColor: '#041E2B',
          marginBottom: {
            xs: '12px',
            sm: '16px'
          },
        }}
      />

      {/* Encabezados */}
      <Grid container spacing={1} sx={{ marginBottom: '8px', flexShrink: 0 }}>
        <Grid item xs={6} sm={6}>
          <Typography 
            variant="body2" 
            sx={{ 
              color: '#8796A5', 
              fontSize: {
                xs: '12px',
                sm: '14px'
              }
            }}
          >
            Trago
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography 
            variant="body2" 
            sx={{ 
              color: '#8796A5', 
              fontSize: {
                xs: '12px',
                sm: '14px'
              }, 
              textAlign: 'center' 
            }}
          >
            Cantidad
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography 
            variant="body2" 
            sx={{ 
              color: '#8796A5', 
              fontSize: {
                xs: '12px',
                sm: '14px'
              }, 
              textAlign: 'right' 
            }}
          >
            Subtotal
          </Typography>
        </Grid>
      </Grid>

      {/* Línea divisora debajo de los encabezados */}
      <Box
        sx={{
          flexShrink: 0,
          width: '100%',
          height: '1px',
          backgroundColor: 'white !important',
          marginBottom: {
            xs: '12px',
            sm: '16px'
          },
        }}
      />

      {/* Contenedor scrollable para los items */}
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          maxHeight: {
            xs: '200px',
            sm: 'none'
          }
        }}
      >
        <Grid container spacing={1}>
          {drinksData?.map((item, index) => (
            <Grid key={index} item xs={12} container alignItems="center" sx={{ marginBottom: '8px' }}>
              <Grid item xs={6} sm={6}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: '12px',
                      sm: '14px'
                    },
                    color: '#fff',
                    wordBreak: 'break-word',
                  }}
                >
                  {item.name}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    fontSize: {
                      xs: '12px',
                      sm: '14px'
                    }, 
                    color: '#98A2B3', 
                    textAlign: 'center' 
                  }}
                >
                  {item.sold.toLocaleString().replaceAll(',', '.')}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    fontSize: {
                      xs: '12px',
                      sm: '14px'
                    }, 
                    color: '#00E676', 
                    textAlign: 'right' 
                  }}
                >
                  ${item.revenue.toLocaleString().replaceAll(',', '.')}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Sección Total */}
      <Box sx={{ flexShrink: 0, marginTop: { xs: '12px', sm: '16px' } }}>
        <Box sx={{ textAlign: 'right' }}>
          {/* Título Total */}
          <Typography
            variant="body1"
            sx={{
              fontWeight: 'bold',
              fontSize: {
                xs: '14px',
                sm: '16px'
              },
            }}
          >
            Total
          </Typography>
          {/* Línea divisora */}
          <Box
            sx={{
              width: '100%',
              height: '1px',
              backgroundColor: '#ffffff',
              margin: {
                xs: '6px 0',
                sm: '8px 0'
              },
            }}
          />
          {/* Monto Total */}
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              fontSize: {
                xs: '16px',
                sm: '18px'
              },
              color: '#00E676',
            }}
          >
            ${sumRevenue().toLocaleString().replaceAll(',', '.')}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default BarSales
