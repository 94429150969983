import React from 'react'

import { ModalTextField } from '../../atoms'

const FormField = ({ label, name, value, onChange, type = 'text', error, helperText }) => (
  <ModalTextField
    label={label}
    name={name}
    value={value}
    onChange={onChange}
    type={type}
    error={error}
    helperText={helperText}
  />
)

export default FormField
