import React, { useContext, useState } from 'react'
import { getFriendlyErrorMessage } from '../../../utils/error-messages'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card'
import { Box, Button, Link, Typography } from '@mui/material'
import MangoLogoWhite from '../../../assets/images/MangoTicket-Color-White.svg'

import AuthService from '../../../services/auth-service'
import { AuthContext } from '../../../context'
import { BasicLayoutLanding } from '../../templates'
import { Input } from '../../atoms'

function Login() {
  const navigate = useNavigate()
  const location = useLocation()
  const { login } = useContext(AuthContext)
  const [credentialsError, setCredentialsError] = useState(null)

  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  })

  const [errors, setErrors] = useState({
    emailError: false,
    passwordError: false,
  })

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    })
  }

  const submitHandler = async (e) => {
    e.preventDefault()

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    if (inputs.email.trim().length === 0 || !inputs.email.trim().match(mailFormat)) {
      setErrors({ ...errors, emailError: true })
      return
    }

    if (inputs.password.trim().length < 3) {
      setErrors({ ...errors, passwordError: true })
      return
    }

    try {
      const token = await AuthService.login(inputs.email, inputs.password)
      login(token)

      const params = new URLSearchParams(location.search)
      const returnUrl = params.get('returnUrl')

      navigate(returnUrl || '/dashboard', { replace: true })
    } catch (res) {
      const friendlyMessage = getFriendlyErrorMessage(res.message)
      setCredentialsError(friendlyMessage)
    }

    return () => {
      setInputs({
        email: '',
        password: '',
      })

      setErrors({
        emailError: false,
        passwordError: false,
      })
    }
  }

  return (
    <BasicLayoutLanding>
      <Box
        sx={{
          position: 'absolute',
          top: { xs: '80px', sm: '60px', md: '80px' },
          left: '50%',
          transform: 'translateX(-50%)',
          width: { xs: '280px', sm: '320px', md: '360px' },
          mb: { xs: 3, sm: 4 },
        }}
      >
        <Box
          component="img"
          src={MangoLogoWhite}
          alt="Mango Ticket"
          sx={{
            width: '100%',
            height: 'auto',
          }}
        />
      </Box>

      <Card sx={{
        borderRadius: { xs: '24px', sm: '32px' },
        width: '100%',
        maxWidth: '480px',
        backgroundColor: '#fff',
        boxShadow: 'none',
        mt: { xs: '40px', sm: '60px', md: '80px' },
      }}>
        <Box sx={{
          p: { xs: 2, sm: 4 },
          pt: { xs: 3, sm: 5 },
        }}>
          <Box component="form" onSubmit={submitHandler}>
            <Typography
              component="label"
              sx={{
                display: 'block',
                mb: 1,
                fontSize: { xs: '14px', sm: '16px' },
                fontWeight: 700,
                color: '#000',
                fontFamily: 'Plus Jakarta Sans',
              }}
            >
              Email
            </Typography>
            <Input
              type="email"
              name="email"
              placeholder="tucorreo@gmail.com"
              value={inputs.email}
              onChange={changeHandler}
              error={errors.emailError}
              fullWidth
              sx={{
                mb: { xs: 1.5, sm: 2 },
                backgroundColor: '#f5f5f5',
                borderRadius: { xs: '12px', sm: '16px' },
                height: { xs: '48px', sm: '56px' },
                '& .MuiOutlinedInput-root': {
                  height: '100%',
                  '& input': {
                    padding: { xs: '12px 16px', sm: '16px 20px' },
                    fontSize: { xs: '14px', sm: '16px' },
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    '&::placeholder': {
                      color: 'rgba(0, 0, 0, 0.4)',
                      opacity: 1,
                    },
                  },
                  '& fieldset': { border: 'none' },
                  '&:hover fieldset': { border: 'none' },
                  '&.Mui-focused fieldset': { border: 'none' },
                },
              }}
            />

            <Typography
              component="label"
              sx={{
                display: 'block',
                mb: 1,
                fontSize: { xs: '14px', sm: '16px' },
                fontWeight: 700,
                color: '#000',
                fontFamily: 'Plus Jakarta Sans',
              }}
            >
              Contraseña
            </Typography>
            <Input
              type="password"
              name="password"
              value={inputs.password}
              onChange={changeHandler}
              error={errors.passwordError}
              fullWidth
              sx={{
                mb: { xs: 1.5, sm: 2 },
                backgroundColor: '#f5f5f5',
                borderRadius: { xs: '12px', sm: '16px' },
                height: { xs: '48px', sm: '56px' },
                '& .MuiOutlinedInput-root': {
                  height: '100%',
                  '& input': {
                    padding: { xs: '12px 16px', sm: '16px 20px' },
                    fontSize: { xs: '14px', sm: '16px' },
                    fontFamily: 'Inter',
                    fontWeight: 400,
                  },
                  '& fieldset': { border: 'none' },
                  '&:hover fieldset': { border: 'none' },
                  '&.Mui-focused fieldset': { border: 'none' },
                },
              }}
            />

            <Button
              type="submit"
              fullWidth
              sx={{
                backgroundColor: '#f79009',
                color: '#fff',
                textTransform: 'none',
                fontSize: { xs: '14px', sm: '16px' },
                fontWeight: 600,
                fontFamily: 'Plus Jakarta Sans',
                py: { xs: 1.5, sm: 1.75 },
                borderRadius: { xs: '12px', sm: '16px' },
                mb: { xs: 2, sm: 2.5 },
                '&:hover': {
                  backgroundColor: '#e67e00',
                },
              }}
            >
              Iniciar Sesión
            </Button>

            <Link
              component={RouterLink}
              to="/forgot-password"
              sx={{
                display: 'block',
                textAlign: 'center',
                color: 'rgba(0, 0, 0, 0.6)',
                fontSize: { xs: '12px', sm: '14px' },
                fontFamily: 'Inter',
                fontWeight: 400,
                textDecoration: 'none',
                mb: { xs: 2, sm: 2.5 },
                '&:hover': {
                  color: '#f79009',
                },
              }}
            >
              ¿Olvidaste tu contraseña?
            </Link>

            {/* <Typography sx={{ 
              textAlign: 'center',
              color: 'rgba(0, 0, 0, 0.6)',
              fontSize: { xs: '12px', sm: '14px' },
              fontFamily: 'Inter',
              fontWeight: 400,
              mb: { xs: 2, sm: 2.5 },
            }}>
              o inicia sesión con:
            </Typography>

            <SocialLogin /> */}

            <Typography sx={{
              textAlign: 'center',
              color: 'rgba(0, 0, 0, 0.6)',
              fontSize: { xs: '12px', sm: '14px' },
              fontFamily: 'Inter',
              fontWeight: 400,
            }}>
              ¿Aún no tienes una cuenta?{' '}
              <Link
                component={RouterLink}
                to="/auth/register"
                sx={{
                  color: '#f79009',
                  textDecoration: 'none',
                  fontWeight: 600,
                  fontFamily: 'Plus Jakarta Sans',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Registrate
              </Link>
            </Typography>

            {credentialsError && (
              <Typography
                variant="caption"
                color="error"
                sx={{
                  display: 'block',
                  textAlign: 'center',
                  mt: 2,
                  fontFamily: 'Inter',
                  fontWeight: 400,
                }}
              >
                {credentialsError}
              </Typography>
            )}
          </Box>
        </Box>
      </Card>
    </BasicLayoutLanding>
  )
}

export default Login
