import React, { memo } from 'react'

import { DashboardLayout, DashboardNavbar } from '../../templates'
import { Events, Statistics } from '../../organisms'
import { Box } from '../../atoms'

const MemoizedStatistics = memo(Statistics)

const DashboardPage = () => {
  return (
    <DashboardLayout>
      <Box py={{ xs: 1, sm: 2, md: 3 }}>
        <DashboardNavbar absolute />
      </Box>
      <Box px={{ xs: 1, sm: 2, md: 0 }}>
        <MemoizedStatistics />
      </Box>
      <Box 
        mt={{ xs: 2, sm: 3, md: 4 }} 
        mb={{ xs: 3, sm: 4, md: 6 }}
        mx={{ xs: 1, sm: 2, md: 0 }}
      >
        <hr
          style={{
            height: '1px',
            border: 'none',
            backgroundColor: '#667085',
            margin: 0,
          }}
        />
      </Box>
      <Box px={{ xs: 1, sm: 2, md: 0 }}>
        <Events 
          title="Mis eventos" 
          height={{ xs: "calc(100vh - 380px)", sm: "400px", md: "360px" }} 
        />
      </Box>
    </DashboardLayout>
  )
}

export default memo(DashboardPage)
