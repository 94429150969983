import { createContext, useContext, useMemo, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '../hooks/auth/useAuth'
import { useNavigate, useLocation } from 'react-router-dom'

export const AuthContext = createContext({
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
})

const AuthContextProvider = ({ children }) => {
  const { isAuthenticated, loading, setIsAuthenticated, logout: authLogout } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const login = () => {
    setIsAuthenticated(true)
    const params = new URLSearchParams(location.search)
    const returnUrl = params.get('returnUrl')
    navigate(returnUrl || '/dashboard')
  }

  const logout = async () => {
    await authLogout()
    navigate('/auth/login')
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return <AuthContext.Provider value={{ isAuthenticated, login, logout }}>{children}</AuthContext.Provider>
}

function reducer(state, action) {
  switch (action.type) {
    case 'MINI_SIDENAV': {
      return { ...state, miniSidenav: action.value }
    }
    case 'MOBILE_VIEW': {
      return { ...state, isMobileView: action.value }
    }
    case 'TRANSPARENT_SIDENAV': {
      return { ...state, transparentSidenav: action.value }
    }
    case 'WHITE_SIDENAV': {
      return { ...state, whiteSidenav: action.value }
    }
    case 'SIDENAV_COLOR': {
      return { ...state, sidenavColor: action.value }
    }
    case 'TRANSPARENT_NAVBAR': {
      return { ...state, transparentNavbar: action.value }
    }
    case 'FIXED_NAVBAR': {
      return { ...state, fixedNavbar: action.value }
    }
    case 'OPEN_CONFIGURATOR': {
      return { ...state, openConfigurator: action.value }
    }
    case 'DIRECTION': {
      return { ...state, direction: action.value }
    }
    case 'LAYOUT': {
      return { ...state, layout: action.value }
    }
    case 'DARKMODE': {
      return { ...state, darkMode: action.value }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const Mango = createContext()

const MangoControllerProvider = ({ children }) => {
  const initialState = {
    miniSidenav: false,
    isMobileView: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: 'mango',
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: 'ltr',
    layout: 'home',
    darkMode: false,
  }

  const [controller, dispatch] = useReducer(reducer, initialState)
  const value = useMemo(() => [controller, dispatch], [controller, dispatch])

  return <Mango.Provider value={value}>{children}</Mango.Provider>
}

function useMangoController() {
  const context = useContext(Mango)

  if (!context) {
    throw new Error('useMangoController should be used inside the MangoControllerProvider.')
  }

  return context
}

MangoControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const setMiniSidenav = (dispatch, value) => dispatch({ type: 'MINI_SIDENAV', value })
const setTransparentNavbar = (dispatch, value) => dispatch({ type: 'TRANSPARENT_NAVBAR', value })
const setOpenConfigurator = (dispatch, value) => dispatch({ type: 'OPEN_CONFIGURATOR', value })
const setLayout = (dispatch, value) => dispatch({ type: 'LAYOUT', value })

export {
  AuthContextProvider,
  MangoControllerProvider,
  useMangoController,
  setMiniSidenav,
  setTransparentNavbar,
  setOpenConfigurator,
  setLayout,
}
