import React from 'react'
import { Box } from '@mui/material'

function BasicLayoutLanding({ children }) {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(180deg, #04121A, #092838)',
        padding: '20px',
      }}
    >
      {children}
    </Box>
  )
}

export default BasicLayoutLanding
