import React from 'react'
import { Box } from '@mui/material'

import { BarPreview, EventPreview, TicketPreview } from '../index'

const CombinedPreview = ({ data, tickets, bar }) => {
  return (
    <Box>
      <EventPreview data={data} />
      <TicketPreview tickets={tickets} setTickets={() => {}} isCombined={true} />
      <BarPreview bar={bar} setBar={() => {}} />
    </Box>
  )
}

export default CombinedPreview
