import { format, parse } from 'date-fns'
import { es } from 'date-fns/locale'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)
dayjs.locale('es')

export function transformDate(dateString) {
  const parsedDate = parse(dateString, "yyyy-MM-dd HH:mm:ss X 'UTC'", new Date())

  const day = format(parsedDate, 'EEEE', { locale: es })
  const dayNum = format(parsedDate, 'dd', { locale: es })
  const month = format(parsedDate, 'MMMM', { locale: es })

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const capitalizedDay = capitalizeFirstLetter(day)
  const capitalizedMonth = capitalizeFirstLetter(month)

  return `${capitalizedDay}, ${dayNum} de ${capitalizedMonth}`
}

export function transformDateDayjs(dateValue) {
  const day = dateValue.locale('es').format('dddd')
  const dayNum = dateValue.locale('es').format('DD')
  const month = dateValue.locale('es').format('MMMM')

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const capitalizedDay = capitalizeFirstLetter(day)
  const capitalizedMonth = capitalizeFirstLetter(month)

  return `${capitalizedDay}, ${dayNum} de ${capitalizedMonth}`
}

export function transformTimesDayjs(startTime, endTime) {
  const formattedStartTime = startTime.format('HH:mm')
  const formattedEndTime = endTime.format('HH:mm')

  return `${formattedStartTime} - ${formattedEndTime} hrs`
}

export function transformTimes(startTime, endTime) {
  return `${startTime} - ${endTime} hrs`
}

export const parseIsoDate = (date) => {
  if (!date) return null

  try {
    const [datePart] = date.split(' ')
    const [year, month, day] = datePart.split('-').map(Number)

    const monthNames = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ]

    return `${String(day).padStart(2, '0')} ${monthNames[month - 1]} ${year}`
  } catch (error) {
    return null
  }
}

export const parseIsoHour = (hour) => {
  if (!hour) return null

  try {
    const [hours, minutes, seconds] = hour.split(':').map(Number)

    if (
      isNaN(hours) ||
      isNaN(minutes) ||
      isNaN(seconds) ||
      hours < 0 ||
      hours > 23 ||
      minutes < 0 ||
      minutes > 59 ||
      seconds < 0 ||
      seconds > 59
    ) {
      throw new Error('Invalid hour')
    }

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
  } catch (error) {
    return null
  }
}
