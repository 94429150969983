// hooks/events/useFetchEventsData.js

import { useCallback, useEffect, useState } from 'react'
import { fetchEvents } from 'services/events-service'

const useFetchEventsData = (filter = null) => {
  const [events, setEvents] = useState([])
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false) // Para actualizaciones posteriores
  const [error, setError] = useState(null)

  const fetchEventsData = useCallback(async () => {
    try {
      if (initialLoading) {
        setInitialLoading(true)
      } else {
        setLoading(true)
      }
      const data = await fetchEvents(filter)
      setEvents(data?.getCustomerEvents || [])
      setError(null)
    } catch (err) {
      setError(err)
    } finally {
      setInitialLoading(false)
      setLoading(false)
    }
  }, [filter, initialLoading])

  useEffect(() => {
    fetchEventsData()
  }, [fetchEventsData])

  const refreshData = useCallback(() => {
    fetchEventsData()
  }, [fetchEventsData])

  return { events, initialLoading, loading, error, refreshData }
}

export default useFetchEventsData
