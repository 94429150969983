// components/organisms/BarSection.jsx

import React, { useCallback, useEffect, useState } from 'react'
import { Box, IconButton, Paper, Typography } from '@mui/material'

import { BarEditModal } from '../../organisms'
import { LoadingSpinner, SnackbarAlert } from '../../atoms'
import { TicketItem } from '../../molecules'
import { ReactComponent as EditIcon } from '../../../assets/mango/icons/edit.svg'
import useListEventItemsData from '../../../hooks/events/useListEventItems'
import useUpdateEventItem from '../../../hooks/events/useUpdateEventItems'
import useCreateEventItems from '../../../hooks/events/useCreateEventItems'
import useDeleteEventItem from '../../../hooks/events/useDeleteEventItem'

const BarSection = React.memo(({ eventId, sx = {} }) => {
  const [barItems, setBarItems] = useState([])
  const [selectedBarItemIndex, setSelectedBarItemIndex] = useState(0)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  // Fetch bar items
  const {
    eventItems: fetchedBarItems,
    initialLoading,
    loading,
    error,
    refreshData: refreshBarItems,
  } = useListEventItemsData(eventId, 'DRINK')

  // Update handlers
  const { updateItems: updateBarItems } = useUpdateEventItem()
  const { createItems } = useCreateEventItems()
  const { deleteItems } = useDeleteEventItem()

  // Handle errors
  useEffect(() => {
    if (error) {
      setErrorMessage(error.message)
      setSnackbarOpen(true)
    }
  }, [error])

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
    setErrorMessage('')
    setSuccessMessage('')
  }

  // Update bar items state
  useEffect(() => {
    if (fetchedBarItems) {
      setBarItems(fetchedBarItems)
    }
  }, [fetchedBarItems])

  const handleBarItemHover = useCallback((index) => {
    setSelectedBarItemIndex(index)
  }, [])

  const handleOpenEditModal = useCallback(() => {
    setIsEditModalOpen(true)
  }, [])

  const handleCloseEditModal = useCallback(() => {
    setIsEditModalOpen(false)
  }, [])

  const handleSaveBarItems = useCallback(
    async (updatedBarItems, deletedBarIds) => {
      try {
        const existingBarItems = updatedBarItems.filter((bar) => !bar.isNew)
        const newBarItems = updatedBarItems.filter((bar) => bar.isNew)

        if (existingBarItems.length > 0) {
          await updateBarItems(eventId, existingBarItems)
        }

        if (newBarItems.length > 0) {
          await createItems(eventId, newBarItems)
        }

        if (deletedBarIds.length > 0) {
          await deleteItems(eventId, deletedBarIds)
        }

        refreshBarItems()
        setIsEditModalOpen(false)
        setSuccessMessage('Barra actualizada con éxito.')
        setSnackbarOpen(true)
      } catch (error) {
        console.error('Error saving bar items:', error)
        setErrorMessage('Error al guardar los ítems de la barra.')
        setSnackbarOpen(true)
      }
    },
    [eventId, updateBarItems, createItems, deleteItems, refreshBarItems]
  )

  if (initialLoading) return <LoadingSpinner />

  if (error && !barItems.length) {
    return (
      <Box sx={{ ...sx, color: 'error.main' }}>
        <Typography variant="h6" color="error">
          {errorMessage}
        </Typography>
      </Box>
    )
  }

  return (
    <Paper
      elevation={3}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: { xs: '12px', sm: '16px', md: '18px' },
        backgroundColor: '#07181e',
        padding: { xs: 2, sm: 2.5, md: 3 },
        color: '#fff',
        overflowY: 'auto',
        ...sx,
      }}
    >
      {/* Título de la sección */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          marginBottom: { xs: 1.5, sm: 2 },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: '#fff',
            marginBottom: { xs: 0.5, sm: 0 },
            fontSize: { xs: '1.25rem', sm: '1.5rem' },
          }}
        >
          Barra
        </Typography>
        <IconButton 
          aria-label="Editar Barra" 
          sx={{ 
            color: '#fff',
            padding: { xs: 1, sm: 1.5 },
          }} 
          onClick={handleOpenEditModal}
        >
          <EditIcon />
        </IconButton>
      </Box>

      {/* Lista de ítems de barra */}
      <Box
        sx={{
          maxHeight: { xs: '350px', sm: '400px', md: '400px' },
          overflowY: 'auto',
          pr: 1,
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '3px',
          },
        }}
      >
        {barItems
          .sort((a, b) => a.priority - b.priority)
          .map((item, index) => (
            <TicketItem
              key={item.id}
              name={item.name}
              description={item.description}
              price={`$ ${parseInt(item.price).toLocaleString('es-CL')}`}
              selected={selectedBarItemIndex === index}
              onMouseEnter={() => handleBarItemHover(index)}
            />
          ))}
      </Box>

      {/* Edit Bar Items Modal */}
      <BarEditModal
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        barItems={barItems}
        setBarItems={setBarItems}
        handleSave={handleSaveBarItems}
      />

      {/* Snackbar Alert */}
      <SnackbarAlert
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={errorMessage || successMessage}
        severity={errorMessage ? 'error' : 'success'}
      />
    </Paper>
  )
})

export default BarSection
