import { useState } from 'react'
import { deleteCashier } from '../../services/cashiers-service'

const useDeleteCashier = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const deleteCashierData = async (cashierId) => {
    try {
      setLoading(true)
      setError(null)
      await deleteCashier(cashierId)
    } catch (err) {
      setError(err)
      throw err
    } finally {
      setLoading(false)
    }
  }

  return { loading, error, deleteCashierData }
}

export default useDeleteCashier
