import notFound from '../assets/images/mango/not_found.jpg'

export const validateImage = (imageUrl) => {
  if (!imageUrl) return '';
  
  // Si la URL es de DigitalOcean Spaces, podríamos necesitar agregar algún token o cabecera
  if (imageUrl.includes('digitaloceanspaces.com')) {
    // Por ahora, podríamos retornar una imagen por defecto o null
    return '/default-avatar.png'; // Asegúrate de tener una imagen por defecto en public/
  }
  
  return imageUrl;
};
