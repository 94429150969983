import { useEffect, useState } from 'react'
import { gql, useQuery, useSubscription } from '@apollo/client'

const GET_DRINK_SALES = gql`
  query GetDrinkSales($input: TotalSalesInput!) {
    drinkSales(input: $input) {
      name
      sold
      revenue
    }
  }
`

const DRINK_SALES_SUBSCRIPTION = gql`
  subscription OnDrinkSalesUpdated($input: TotalSalesInput!) {
    drinkSalesUpdated(input: $input) {
      name
      sold
      revenue
    }
  }
`

export const useDrinkSalesData = ({ eventId }) => {
  const [drinksData, setDrinkData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useQuery(GET_DRINK_SALES, {
    variables: { input: { event_id: eventId } },
  })

  const { data: subscriptionData, error: subscriptionError } = useSubscription(DRINK_SALES_SUBSCRIPTION, {
    variables: { input: { event_id: eventId } },
  })

  useEffect(() => {
    if (queryData) {
      setDrinkData(queryData.drinkSales)
      setLoading(false)
    }
    if (queryError) {
      setError(queryError)
      setLoading(false)
    }
  }, [queryData, queryError])

  useEffect(() => {
    if (subscriptionData) {
      setDrinkData(subscriptionData.drinkSalesUpdated)
    }
    if (subscriptionError) {
      setError(subscriptionError)
    }
  }, [subscriptionData, subscriptionError])

  return {
    drinksData,
    loading: loading || queryLoading,
    error,
  }
}
