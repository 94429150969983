// src/hooks/useUpdateUser.js

import { gql, useMutation } from '@apollo/client'

const UPDATE_CUSTOMER_USER = gql`
  mutation UpdateCustomerUser($id: ID!, $input: CustomerUserData!) {
    updateCustomerUser(id: $id, input: $input) {
      id
      external_id
      email
      name
      created_at
      updated_at
    }
  }
`

export const useUpdateUser = () => {
  const [updateUser, { loading }] = useMutation(UPDATE_CUSTOMER_USER)

  const handleUpdateUser = async (id, userData) => {
    try {
      const { data } = await updateUser({
        variables: {
          id,
          input: {
            name: userData.name,
            email: userData.email
          }
        }
      })
      return data.updateCustomerUser
    } catch (error) {
      throw error
    }
  }

  return {
    updateUser: handleUpdateUser,
    loading
  }
}
