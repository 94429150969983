import { useState } from 'react'
import { updateCashier } from '../../services/cashiers-service'

const useUpdateCashier = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const updateCashierData = async (updatedInfo) => {
    try {
      setLoading(true)
      setError(null)
      await updateCashier(updatedInfo)
    } catch (err) {
      setError(err)
      throw err
    } finally {
      setLoading(false)
    }
  }

  return { loading, error, updateCashierData }
}

export default useUpdateCashier
