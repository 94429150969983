import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'

const Testimonial = () => {
  return (
    <Box
      component="section"
      sx={{
        backgroundColor: '#fff',
        padding: '112px 64px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="md">
        <Box sx={{ textAlign: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: '4px',
              marginBottom: '32px',
            }}
          >
            {[...Array(5)].map((_, index) => (
              <StarIcon
                key={index}
                sx={{
                  color: '#f79009',
                  fontSize: '24px'
                }}
              />
            ))}
          </Box>
          <Typography
            component="blockquote"
            sx={{
              fontSize: '24px',
              fontFamily: '"Plus Jakarta Sans", sans-serif',
              fontWeight: 500,
              color: '#041e2b',
              lineHeight: 1.4,
              marginBottom: '32px',
            }}
          >
            "MangoTicket transformó nuestra manera de gestionar eventos. La plataforma es intuitiva y nos permitió
            vender entradas de manera eficiente."
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '20px',
            }}
          >
            <Box
              sx={{
                width: '56px',
                height: '56px',
                borderRadius: '50%',
                backgroundColor: '#f79009',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#fff',
                fontSize: '24px',
                fontWeight: 700,
              }}
            >
              R
            </Box>
            <Box
              sx={{
                textAlign: 'left',
              }}
            >
              <Typography
                sx={{
                  color: '#041e2b',
                  fontSize: '16px',
                  fontFamily: '"Inter", sans-serif',
                  fontWeight: 400,
                }}
              >
                Rosario Chacon
              </Typography>
              <Typography
                sx={{
                  color: '#666',
                  fontSize: '16px',
                  fontFamily: '"Inter", sans-serif',
                  fontWeight: 400,
                }}
              >
                Product Manager
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Testimonial
