import React, { useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { DashboardLayout, EventFormTemplate } from '../../templates'
import { Box } from '../../atoms'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#FFA726',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
    },
  },
})

const CreateEventPage = () => {
  const [data, setData] = useState({
    name: '',
    defaultName: 'Nombre del evento',
    address: '',
    defaultAddress: 'Dirección del evento',
    venue: '',
    defaultVenue: 'Lugar del evento',
    description: '',
    defaultDescription: 'Descripción del evento',
    startDate: '',
    defaultStartDate: 'Fecha del evento',
    rawStartDate: '',
    startHour: '',
    defaultStartHour: 'Hora del evento',
    rawStartHour: '',
    endDate: '',
    rawEndDate: '',
    endHour: '',
    rawEndHour: '',
    image: '',
    rawImageName: '',
    nominated: false,
  })

  const [step, setStep] = useState(0)
  const [tickets, setTickets] = useState([{ id: 1, data: { name: '', price: '', count: 0 }, priority: 0 }])
  const [nextTicketId, setNextTicketId] = useState(2)
  const [bar, setBar] = useState([])

  const handleStepChange = (newStep) => {
    setStep(newStep)
  }

  return (
    <DashboardLayout>
      <Box pt={2}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <EventFormTemplate
              data={data}
              setData={setData}
              step={step}
              setStep={handleStepChange}
              tickets={tickets}
              setTickets={setTickets}
              nextTicketId={nextTicketId}
              setNextTicketId={setNextTicketId}
              bar={bar}
              setBar={setBar}
            />
          </LocalizationProvider>
        </ThemeProvider>
      </Box>
    </DashboardLayout>
  )
}

export default CreateEventPage
