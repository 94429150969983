import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography'
import { AuthContext, setTransparentNavbar, useMangoController } from '../../../context'
import { Box, Button } from '../../atoms'
import LargeWindowSVG from '../../../assets/mango/logos/MangoTicket-Color-White.svg'
import { navbar } from './DashboardNavbar.style'
import Divider from '@mui/material/Divider'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Icon from '@mui/material/Icon'
import routes from '../../../routes'

function DashboardNavbar({ absolute = false, light = false, isMini = false, title, showBackButton = false }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const [mobileOpen, setMobileOpen] = useState(false)
  const location = useLocation()
  
  const authContext = useContext(AuthContext)
  const [navbarType, setNavbarType] = useState()
  const [controller, dispatch] = useMangoController()
  const { transparentNavbar, fixedNavbar, darkMode, miniSidenav, layout } = controller
  const navigate = useNavigate()

  const currentEventId = location.pathname.split('/')[2]
  const isEventRoute = location.pathname.includes('/events/') && currentEventId && currentEventId !== 'new'

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType('sticky')
    } else {
      setNavbarType('static')
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar)
    }

    window.addEventListener('scroll', handleTransparentNavbar)
    handleTransparentNavbar()

    return () => window.removeEventListener('scroll', handleTransparentNavbar)
  }, [dispatch, fixedNavbar])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleOnClick = (e) => {
    if (e) e.preventDefault()
    navigate('/events/new')
    if (isMobile) {
      handleDrawerToggle()
    }
  }

  const handleOnLogoClick = useCallback(() => {
    navigate('/')
  }, [navigate])

  const renderSVG = () => {
    return miniSidenav ? (
      <img 
        src={LargeWindowSVG} 
        alt="Large Window" 
        style={{ 
          width: isMobile ? '150px' : isTablet ? '175px' : '200px',
          height: 'auto',
        }} 
      />
    ) : null
  }

  const mobileMenu = (
    <Box 
      sx={{ 
        width: 250,
        background: 'linear-gradient(to bottom, #04121A, #092838)',
        height: '100%',
        py: 2,
        color: 'white'
      }}
    >
      <List>
        <ListItem 
          button
          onClick={handleOnClick}
          sx={{
            backgroundColor: 'transparent',
            borderRadius: '8px',
            mx: 1,
            color: '#f79009',
            '&:hover': {
              backgroundColor: 'rgba(247, 144, 9, 0.1)',
            },
            '& .MuiListItemIcon-root': {
              color: '#f79009',
            },
            '& .MuiListItemText-primary': {
              color: '#f79009',
            },
            '& .MuiSvgIcon-root': {
              color: '#f79009',
            }
          }}
        >
          <ListItemIcon sx={{ color: '#f79009', minWidth: 40 }}>
            <Icon>add_circle</Icon>
          </ListItemIcon>
          <ListItemText 
            primary="Crear un Evento" 
            sx={{ 
              '& .MuiTypography-root': { 
                fontSize: '16px',
                fontWeight: 500,
                color: '#f79009'
              } 
            }} 
          />
        </ListItem>
        <Divider sx={{ my: 1, backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
        {routes
          .filter(route => route.type === 'protected')
          .map((route) => {
            // Always show Home
            if (route.key === 'dashboard') {
              const isActive = location.pathname === route.route;
              return (
                <ListItem 
                  key={route.key}
                  button 
                  onClick={() => {
                    navigate(route.route)
                    handleDrawerToggle()
                  }}
                  sx={{
                    backgroundColor: isActive ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                    borderRadius: '8px',
                    mx: 1,
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                      color: '#fff',
                    },
                    '& .MuiListItemIcon-root': {
                      color: '#fff',
                    },
                    '& .MuiListItemText-primary': {
                      color: '#fff',
                    },
                    '& .MuiSvgIcon-root': {
                      color: '#fff',
                    }
                  }}
                >
                  <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText 
                    primary={route.name} 
                    sx={{ 
                      '& .MuiTypography-root': { 
                        fontSize: '16px',
                        fontWeight: isActive ? 500 : 400,
                        color: '#fff'
                      } 
                    }} 
                  />
                </ListItem>
              )
            }

            // Show User Management always except in /events/new
            if (route.key === 'user-management' && !location.pathname.includes('/events/new')) {
              const isActive = location.pathname === route.route;
              return (
                <ListItem 
                  key={route.key}
                  button 
                  onClick={() => {
                    navigate(route.route)
                    handleDrawerToggle()
                  }}
                  sx={{
                    backgroundColor: isActive ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                    borderRadius: '8px',
                    mx: 1,
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                      color: '#fff',
                    },
                    '& .MuiListItemIcon-root': {
                      color: '#fff',
                    },
                    '& .MuiListItemText-primary': {
                      color: '#fff',
                    },
                    '& .MuiSvgIcon-root': {
                      color: '#fff',
                    }
                  }}
                >
                  <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText 
                    primary={route.name} 
                    sx={{ 
                      '& .MuiTypography-root': { 
                        fontSize: '16px',
                        fontWeight: isActive ? 500 : 400,
                        color: '#fff'
                      } 
                    }} 
                  />
                </ListItem>
              )
            }

            // Show Details and Metrics only when in an event route
            if ((route.key === 'event-detail' || route.key === 'event-metrics') && isEventRoute) {
              const isActive = location.pathname === route.route.replace(':eventId', currentEventId);
              return (
                <ListItem 
                  key={route.key}
                  button 
                  onClick={() => {
                    navigate(route.route.replace(':eventId', currentEventId))
                    handleDrawerToggle()
                  }}
                  sx={{
                    backgroundColor: isActive ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                    borderRadius: '8px',
                    mx: 1,
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                      color: '#fff',
                    },
                    '& .MuiListItemIcon-root': {
                      color: '#fff',
                    },
                    '& .MuiListItemText-primary': {
                      color: '#fff',
                    },
                    '& .MuiSvgIcon-root': {
                      color: '#fff',
                    }
                  }}
                >
                  <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText 
                    primary={route.name} 
                    sx={{ 
                      '& .MuiTypography-root': { 
                        fontSize: '16px',
                        fontWeight: isActive ? 500 : 400,
                        color: '#fff'
                      } 
                    }} 
                  />
                </ListItem>
              )
            }

            return null
          })}
      </List>
    </Box>
  )

  return (
    <>
      <AppBar
        position={absolute ? 'absolute' : navbarType}
        color="inherit"
        sx={(theme) => ({
          ...navbar(theme, { transparentNavbar, absolute, light, darkMode }),
          height: { xs: '60px', sm: '60px', md: '75px' },
          margin: 0,
        })}
      >
        <Box 
          display="flex" 
          flexDirection="row" 
          justifyContent="space-between" 
          alignItems="center" 
          px={{ xs: 1.5, sm: 1.5, md: 3 }} 
          py={{ xs: 0.5, sm: 0.5, md: 1 }}
          height="100%"
        >
          {/* Sección Izquierda */}
          <Box 
            display="flex" 
            flexDirection="row" 
            alignItems="center"
            gap={{ xs: 1, sm: 1.5, md: 2 }}
          >
            {layout !== 'event' ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                onClick={handleOnLogoClick}
                sx={{ 
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.02)',
                  },
                }}
              >
                {renderSVG()}
              </Box>
            ) : null}
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: { xs: '40px', sm: '45px', md: '50px' },
                cursor: 'pointer',
                flexDirection: 'column',
                textAlign: 'center',
                maxWidth: { xs: '200px', sm: '300px', md: '400px' },
                overflow: 'hidden',
              }}
            >
              <Typography 
                variant="h4" 
                color="white !important" 
                noWrap 
                sx={{ 
                  marginLeft: '0',
                  fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' },
                  fontWeight: { xs: 500, md: 600 },
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                  whiteSpace: 'nowrap',
                }}
              >
                {title}
              </Typography>
            </Box>
          </Box>

          {/* Sección Derecha */}
          <Box display="flex" alignItems="center">
            {!isMobile && (
              <Button
                variant="contained"
                size={isMobile ? "medium" : "large"}
                color="warning"
                onClick={handleOnClick}
                sx={{ 
                  fontFamily: 'Inter, sans-serif',
                  fontSize: { xs: '0.875rem', sm: '0.9375rem', md: '1rem' },
                  px: { xs: 2, sm: 2.5, md: 3 },
                  py: { xs: 0.75, sm: 1, md: 1.25 },
                  minWidth: { xs: '120px', sm: '140px', md: '160px' },
                  whiteSpace: 'nowrap',
                  transition: 'transform 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                  },
                }}
              >
                Crear un Evento
              </Button>
            )}
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                sx={{ 
                  color: '#fff'
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better mobile performance
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 250,
            border: 'none',
          },
        }}
      >
        {mobileMenu}
      </Drawer>
      <Divider 
        light={true} 
        sx={{ 
          width: '100%', 
          marginTop: { xs: '60px', sm: '60px', md: '75px' },
          opacity: 0.2,
        }} 
      />
    </>
  )
}

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  title: PropTypes.string,
  showBackButton: PropTypes.bool,
}

export default DashboardNavbar
