import React from 'react'
import {
  Checkbox,
  Paper,
  Box,
  Grid,
  Typography,
  Button,
  Fade,
} from '@mui/material'
import { ReactComponent as DownArrow } from '../../../assets/mango/icons/chevron-down-outline.svg'
import { LoadingSpinner } from '../../atoms'
import UserTableRow from '../UserTableRow/UserTableRow'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'
import DeleteIcon from '@mui/icons-material/Delete'

const HeaderCell = ({ children, sortable, width, align = 'center' }) => (
  <Box 
    sx={{ 
      display: 'flex',
      alignItems: 'center',
      justifyContent: align === 'right' ? 'flex-end' : 'center',
      gap: 1,
      width: '100%',
    }}
  >
    <Typography
      sx={{ 
        fontSize: '14px',
        color: '#344054',
        fontWeight: 500,
      }}
    >
      {children}
    </Typography>
    {sortable && <DownArrow style={{ color: '#98A2B3', width: 16, height: 16 }} />}
  </Box>
)

const UserTable = ({
  users,
  onEditUser,
  onDeleteUser,
  onBatchDeleteUsers,
  refetchUsers,
  isSmallScreen,
  selectedUsers,
  onSelectUser,
  onSelectAllUsers,
  onSendCourtesies,
}) => {
  if (!users) return <LoadingSpinner />

  const isAllSelected = users.length > 0 && selectedUsers.length === users.length
  const hasSelectedUsers = selectedUsers.length > 0

  const handleBulkDelete = () => {
    onBatchDeleteUsers(selectedUsers)
  }

  return (
    <Box>
      {/* Barra de acciones en masa */}
      <Box sx={{ height: hasSelectedUsers ? 'auto' : 0, mb: hasSelectedUsers ? { xs: 2, sm: 3 } : 0 }}>
        <Fade in={hasSelectedUsers}>
          <Box
            sx={{
              width: '100%',
              minHeight: 56,
              backgroundColor: '#fff',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', sm: 'space-between' },
              px: { xs: 2, sm: 3 },
              py: { xs: 1, sm: 0 },
              boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
              flexWrap: { xs: 'wrap', sm: 'nowrap' },
              gap: { xs: 1, sm: 0 }
            }}
          >
            <Typography sx={{ 
              color: '#344054', 
              fontWeight: 500,
              fontSize: { xs: '0.875rem', sm: '1rem' },
              width: { xs: '100%', sm: 'auto' },
              textAlign: { xs: 'center', sm: 'left' },
              mb: { xs: 1, sm: 0 }
            }}>
              {selectedUsers.length} {selectedUsers.length === 1 ? 'usuario seleccionado' : 'usuarios seleccionados'}
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              gap: 1,
              flexShrink: 0,
              justifyContent: { xs: 'center', sm: 'flex-end' },
              width: { xs: '100%', sm: 'auto' }
            }}>
              <Button
                variant="contained"
                startIcon={<DeleteIcon sx={{ 
                  color: '#fff',
                  fontSize: { xs: '1.125rem', sm: '1.5rem' }
                }} />}
                onClick={handleBulkDelete}
                sx={{
                  backgroundColor: '#DC2626',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#B91C1C',
                  },
                  textTransform: 'none',
                  fontFamily: 'Inter',
                  fontWeight: 400,
                  px: 2,
                  py: 1,
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  minWidth: { xs: '140px', sm: '160px' },
                  width: { xs: '140px', sm: '160px' },
                  flex: { xs: 1, sm: 'initial' },
                  lineHeight: 1,
                  whiteSpace: 'nowrap',
                  height: { sm: '32px' }
                }}
              >
                Eliminar
              </Button>
              <Button
                variant="contained"
                startIcon={<LocalActivityIcon sx={{ 
                  color: '#fff',
                  fontSize: { xs: '1.125rem', sm: '1.5rem' }
                }} />}
                onClick={onSendCourtesies}
                sx={{
                  backgroundColor: '#F79009',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#E27D08',
                  },
                  textTransform: 'none',
                  fontFamily: 'Inter',
                  fontWeight: 400,
                  px: 2,
                  py: 1,
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  minWidth: { xs: '140px', sm: '160px' },
                  width: { xs: '140px', sm: '160px' },
                  flex: { xs: 1, sm: 'initial' },
                  lineHeight: 1,
                  whiteSpace: 'nowrap',
                  height: { sm: '32px' }
                }}
              >
                Enviar Cortesías
              </Button>
            </Box>
          </Box>
        </Fade>
      </Box>

      <Paper
        sx={{
          width: '100%',
          height: { xs: "calc(100vh - 380px)", sm: "400px", md: "360px" },
          borderRadius: { xs: 2, sm: 3 },
          overflow: 'auto',
          backgroundColor: '#fff',
          boxShadow: 'none',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#666',
            borderRadius: '4px',
          },
        }}
      >
        {/* Header */}
        <Box sx={{ px: 2, py: 1.5, borderBottom: '1px solid #E5E7EB' }}>
          <Grid container alignItems="center">
            <Grid item sx={{ width: 48, pr: 1 }}>
              <Checkbox
                color="primary"
                indeterminate={selectedUsers.length > 0 && selectedUsers.length < users.length}
                checked={isAllSelected}
                onChange={() => onSelectAllUsers(!isAllSelected)}
                sx={{
                  color: '#D0D5DD',
                  '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                    color: '#F79009',
                  },
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <HeaderCell sortable>Nombre</HeaderCell>
            </Grid>
            {!isSmallScreen && (
              <Grid item xs={5}>
                <HeaderCell sortable>Correo</HeaderCell>
              </Grid>
            )}
            <Grid item sx={{ width: 60, ml: 'auto' }}>
              <HeaderCell align="right">Acciones</HeaderCell>
            </Grid>
          </Grid>
        </Box>

        {/* Rows */}
        <Box>
          {users.map((user) => (
            <UserTableRow
              key={user.id}
              user={user}
              onEditUser={onEditUser}
              onDeleteUser={onDeleteUser}
              isSelected={selectedUsers.includes(user.id)}
              onSelectUser={onSelectUser}
              isSmallScreen={isSmallScreen}
            />
          ))}
        </Box>
      </Paper>
    </Box>
  )
}

export default UserTable
