// components/organisms/EventFormModal.jsx

import React, { useCallback, useState } from 'react'
import { Checkbox, Grid } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { es } from 'date-fns/locale'

import { eventDataErrors as errorMessages } from '../../pages/CreateEventPage/constants'
import { DatePickerField, FileUploader, TimePickerField } from '../../molecules'
import { FormTextField, Typography } from '../../atoms'
import { transformDateDayjs } from '../../../utils/date'

const MAX_SIZE = 5 * 1024 * 1024

const EventFormModal = ({ setData, data }) => {
  const [errors, setErrors] = useState({})

  const handleNameChange = useCallback(
    (e) => {
      const value = e.target.value
      setData((prevData) => ({ ...prevData, name: value }))

      if (!value.trim()) {
        setErrors((prevErrors) => ({ ...prevErrors, name: errorMessages.REQUIRED_NAME }))
      } else {
        setErrors((prevErrors) => {
          const { name, ...rest } = prevErrors
          return rest
        })
      }
    },
    [setData]
  )

  const handleAddressChange = useCallback(
    (e) => {
      const value = e.target.value
      setData((prevData) => ({ ...prevData, address: value }))

      if (!value.trim()) {
        setErrors((prevErrors) => ({ ...prevErrors, address: errorMessages.REQUIRED_ADDRESS }))
      } else {
        setErrors((prevErrors) => {
          const { address, ...rest } = prevErrors
          return rest
        })
      }
    },
    [setData]
  )

  const handleVenueChange = useCallback(
    (e) => {
      const value = e.target.value
      setData((prevData) => ({ ...prevData, venue: value }))

      if (!value.trim()) {
        setErrors((prevErrors) => ({ ...prevErrors, venue: errorMessages.REQUIRED_VENUE }))
      } else {
        setErrors((prevErrors) => {
          const { venue, ...rest } = prevErrors
          return rest
        })
      }
    },
    [setData]
  )

  const handleDescriptionChange = useCallback(
    (e) => {
      const value = e.target.value
      setData((prevData) => ({ ...prevData, description: value }))

      if (!value.trim()) {
        setErrors((prevErrors) => ({ ...prevErrors, description: errorMessages.REQUIRED_DESCRIPTION }))
      } else if (value.length < 10) {
        setErrors((prevErrors) => ({ ...prevErrors, description: errorMessages.MIN_LENGTH_DESCRIPTION }))
      } else {
        setErrors((prevErrors) => {
          const { description, ...rest } = prevErrors
          return rest
        })
      }
    },
    [setData]
  )

  const handleStartDateChange = useCallback(
    (newValue) => {
      const value = dayjs(newValue).toDate()
      setData((prevData) => ({
        ...prevData,
        startDate: dayjs(newValue).format('DD/MM/YYYY'),
        rawStartDate: newValue,
        date: transformDateDayjs(newValue),
      }))

      if (!value || isNaN(value.getTime())) {
        setErrors((prevErrors) => ({ ...prevErrors, startDate: errorMessages.INVALID_START_DATE }))
      } else if (data.endDate && value > new Date(data.endDate)) {
        setErrors((prevErrors) => ({ ...prevErrors, startDate: errorMessages.INVALID_START_END_DATE }))
      } else {
        setErrors((prevErrors) => {
          const { startDate, ...rest } = prevErrors
          return rest
        })
      }
    },
    [setData, data.endDate]
  )

  const handleEndDateChange = useCallback(
    (newValue) => {
      const value = dayjs(newValue).toDate()
      setData((prevData) => ({
        ...prevData,
        endDate: dayjs(newValue).format('DD/MM/YYYY'),
        rawEndDate: newValue,
      }))

      if (!value || isNaN(value.getTime())) {
        setErrors((prevErrors) => ({ ...prevErrors, endDate: errorMessages.INVALID_END_DATE }))
      } else if (data.startDate && value < new Date(data.startDate)) {
        setErrors((prevErrors) => ({ ...prevErrors, endDate: errorMessages.INVALID_END_START_DATE }))
      } else {
        setErrors((prevErrors) => {
          const { endDate, ...rest } = prevErrors
          return rest
        })
      }
    },
    [setData, data.startDate]
  )

  const setTime = useCallback((startHour, endHour) => {
    return `${dayjs(startHour).format('HH:mm')} - ${dayjs(endHour).format('HH:mm')} hrs`
  }, [])

  const handleStartHourChange = useCallback(
    (e) => {
      const value = dayjs(e)
      setData((prevData) => ({
        ...prevData,
        startHour: value.format('HH:mm'),
        rawStartHour: e,
        time: setTime(e, data.rawEndHour),
      }))
    },
    [setData, data.rawEndHour, setTime]
  )

  const handleEndHourChange = useCallback(
    (e) => {
      const value = dayjs(e)
      setData((prevData) => ({
        ...prevData,
        endHour: value.format('HH:mm'),
        rawEndHour: e,
        time: setTime(data.rawStartHour, e),
      }))
    },
    [setData, data.rawStartHour, setTime]
  )

  const handleImage = useCallback(
    (image, name) => {
      if (!image) {
        setErrors((prevErrors) => ({ ...prevErrors, image: errorMessages.REQUIRED_IMAGE }))
      } else if (image.size > MAX_SIZE) {
        setErrors((prevErrors) => ({ ...prevErrors, image: errorMessages.MAX_SIZE_IMAGE }))
      } else {
        setErrors((prevErrors) => {
          const { image: imageError, ...rest } = prevErrors
          return rest
        })
      }
      setData((prevData) => ({ ...prevData, image, rawImageName: name }))
    },
    [setData]
  )

  const handleCheckboxChange = useCallback(
    (e) => {
      const value = e.target.checked
      setData((prevData) => ({ ...prevData, nominated: value }))
    },
    [setData]
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={es}>
      <Grid container spacing={2} width="100%" mt={1} sx={{ color: 'black' }}>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="Nombre del Evento"
            onChange={handleNameChange}
            error={Boolean(errors.name)}
            helperText={errors.name}
            value={data.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="Dirección del Evento"
            onChange={handleAddressChange}
            error={Boolean(errors.address)}
            helperText={errors.address}
            value={data.address}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            label="Venue"
            onChange={handleVenueChange}
            error={Boolean(errors.venue)}
            helperText={errors.venue}
            value={data.venue}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            label="Descripción del Evento"
            onChange={handleDescriptionChange}
            multiline
            rows={4}
            error={Boolean(errors.description)}
            helperText={errors.description}
            value={data.description}
          />
        </Grid>
        <Grid item xs={12}>
          <FileUploader handleImage={handleImage} error={Boolean(errors.image)} helperText={errors.image} data={data} />
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sm={6}>
            <DatePickerField
              label="Fecha Inicio"
              onChange={handleStartDateChange}
              fullWidth
              error={Boolean(errors.startDate)}
              helperText={errors.startDate}
              value={data.rawStartDate ? dayjs(data.rawStartDate) : null}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePickerField
              label="Fecha Término"
              onChange={handleEndDateChange}
              fullWidth
              error={Boolean(errors.endDate)}
              helperText={errors.endDate}
              value={data.rawEndDate ? dayjs(data.rawEndDate) : null}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TimePickerField
              label="Horario Inicio"
              onChange={handleStartHourChange}
              fullWidth
              value={data.rawStartHour || null}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TimePickerField
              label="Horario Término"
              onChange={handleEndHourChange}
              fullWidth
              value={data.rawEndHour || null}
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <Checkbox
              checked={data.nominated || false}
              onChange={handleCheckboxChange}
              inputProps={{ 'aria-label': 'Evento Nominado' }}
            />
          </Grid>
          <Grid item xs={12} md={11}>
            <Typography mt={1} sx={{ color: 'black' }}>
              Evento nominado
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}

export default EventFormModal
