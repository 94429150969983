// hooks/events/useCreateEventItems.js

import { useCallback, useState } from 'react'
import { batchCreateEventItem } from 'services/events-service'

const useCreateEventItems = () => {
  const [data, setData] = useState(null)
  const [loadingCreate, setLoadingCreate] = useState(false) // Cambiado a loadingCreate
  const [error, setError] = useState(null)

  const createItems = useCallback(async (eventId, items) => {
    setLoadingCreate(true)
    setError(null)
    try {
      const response = await batchCreateEventItem(items, eventId)
      setData(response)
    } catch (err) {
      setError(err)
    } finally {
      setLoadingCreate(false)
    }
  }, [])

  return { data, loadingCreate, error, createItems }
}

export default useCreateEventItems
