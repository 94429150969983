// src/apolloClient.js
import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, Observable, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'

// Error handling link
const errorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    // Handle errors if needed
    return response
  })
})

// HTTP link for queries and mutations
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_MANGO_DASHBOARD_API_URL}/graphql`,
  credentials: 'include', // Include cookies in outgoing requests
})

// SSE link for subscriptions using EventSource
const sseLink = new ApolloLink((operation) => {
  return new Observable((observer) => {
    const { query, variables, operationName } = operation

    const url = new URL(`${process.env.REACT_APP_MANGO_DASHBOARD_API_URL}/graphql`)

    // Build the URL with query parameters
    url.searchParams.append('query', query.loc && query.loc.source.body)
    if (operationName) {
      url.searchParams.append('operationName', operationName)
    }
    if (variables && Object.keys(variables).length > 0) {
      url.searchParams.append('variables', JSON.stringify(variables))
    }

    // Ensure the URL is correctly encoded
    const encodedURL = url.toString()

    const eventSource = new EventSource(encodedURL, { withCredentials: true })

    eventSource.onmessage = (event) => {
      try {
        const parsedData = JSON.parse(event.data)
        observer.next(parsedData)
      } catch (e) {
        observer.error(e)
      }
    }

    eventSource.onerror = (error) => {
      observer.error(error)
      eventSource.close()
    }

    return () => {
      eventSource.close()
    }
  })
})

// Split link to direct operations to the appropriate link
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  sseLink,
  from([errorLink, httpLink]) // Use the 'from' function to compose multiple links
)

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
})

export default client
