import React, { memo, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Grid, IconButton } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'

import { DatePickerField, TimePickerField } from '../../molecules'
import { FormTextField, Typography } from '../../atoms'

const TicketFormItem = memo(
  ({ ticketNumber, ticketData, setTicketData, expanded, onChange, onDelete, dragHandleProps }) => {
    const [errors, setErrors] = useState({})
    const [isEditingPrice, setIsEditingPrice] = useState(false)

    const validateField = (field, value) => {
      let error = ''
      if (field === 'name' && !value) {
        error = 'El nombre del ticket es requerido'
      }
      if (field === 'price') {
        if (!value) {
          error = 'El precio del ticket es requerido'
        } else if (isNaN(value)) {
          error = 'El precio del ticket debe ser un número'
        } else if (Number(value) <= 0) {
          error = 'El precio del ticket debe ser mayor que cero'
        }
      }
      if (field === 'quantity') {
        if (!value || isNaN(value)) {
          error = 'La cantidad disponible es requerida y debe ser un número'
        } else if (Number(value) <= 0) {
          error = 'La cantidad disponible debe ser mayor que cero'
        }
      }
      if (field === 'maxPerSale') {
        if (!value || isNaN(value)) {
          error = 'La cantidad máxima por venta es requerida y debe ser un número'
        } else if (Number(value) <= 0) {
          error = 'La cantidad máxima por venta debe ser mayor que cero'
        } else if (Number(value) > Number(ticketData.quantity)) {
          error = 'La cantidad máxima por venta no puede ser mayor que la cantidad disponible'
        }
      }
      if (ticketData.includesCover) {
        if (field === 'endDate') {
          if (!value) {
            error = 'La fecha fin es requerida'
          }
        }
        if (field === 'endHour') {
          if (!value) {
            error = 'La hora fin es requerida'
          }
        }
      }
      setErrors((prevErrors) => ({ ...prevErrors, [field]: error }))
    }

    const formatPrice = (value) => {
      if (!value) return ''
      const number = parseInt(value, 10)
      if (isNaN(number)) return ''
      return '$ ' + number.toLocaleString('es-CL')
    }

    const handleFieldChange = (field) => (e) => {
      let value = e.target.value
      if (field === 'price' || field === 'quantity' || field === 'maxPerSale') {
        value = value.replace(/\D/g, '')
      }

      const updatedData = { ...ticketData, [field]: value }
      setTicketData(updatedData)
      validateField(field, value)
    }

    const handleFieldBlur = (field) => (e) => {
      if (field === 'price') {
        const value = ticketData.price
        const formattedValue = formatPrice(value)
        const updatedData = { ...ticketData, formattedPrice: formattedValue }
        setTicketData(updatedData)
        setIsEditingPrice(false)
      }
    }

    const handleCheckboxChange = (e) => {
      const value = e.target.checked
      const updatedData = { ...ticketData, includesCover: value }
      setTicketData(updatedData)
    }

    const handleEndDateChange = (date) => {
      const updatedData = { ...ticketData, endDate: date }
      setTicketData(updatedData)
      validateField('endDate', date)
    }

    const handleEndHourChange = (time) => {
      const updatedData = { ...ticketData, endHour: time }
      setTicketData(updatedData)
      validateField('endHour', time)
    }

    return (
      <Accordion
        expanded={expanded}
        onChange={(event, isExpanded) => onChange(isExpanded)}
        sx={{
          width: '100%',
          border: '1px dashed black',
          borderRadius: '10px',
          '&::before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${ticketNumber}-content`}
          id={`panel${ticketNumber}-header`}
          sx={{ padding: '0 16px' }}
          {...dragHandleProps}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">
                {ticketData.name ? ticketData.name : `Ticket N°${ticketNumber}`}
                {ticketData.formattedPrice ? ` - ${ticketData.formattedPrice}` : ''}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                edge="end"
                onClick={(e) => {
                  e.stopPropagation()
                  onDelete()
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Grid container spacing={2} sx={{ padding: '16px' }}>
            {/* Nombre del Ticket */}
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Nombre del Ticket"
                value={ticketData.name || ''}
                onChange={handleFieldChange('name')}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            </Grid>
            {/* Precio del Ticket */}
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Precio del Ticket"
                value={isEditingPrice ? ticketData.price || '' : ticketData.formattedPrice || ''}
                onChange={handleFieldChange('price')}
                onFocus={() => setIsEditingPrice(true)}
                onBlur={handleFieldBlur('price')}
                error={Boolean(errors.price)}
                helperText={errors.price}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
            </Grid>
            {/* Cantidad disponible */}
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Cantidad disponible"
                value={ticketData.quantity || ''}
                onChange={handleFieldChange('quantity')}
                error={Boolean(errors.quantity)}
                helperText={errors.quantity}
              />
            </Grid>
            {/* Cantidad maxima por venta */}
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Cantidad máxima por venta"
                value={ticketData.maxPerSale || ''}
                onChange={handleFieldChange('maxPerSale')}
                error={Boolean(errors.maxPerSale)}
                helperText={errors.maxPerSale}
              />
            </Grid>
            {/* Condiciones Tiempo */}
            <Grid container alignItems="center" sx={{ mt: 1 }}>
              <Grid item xs="auto">
                <Checkbox
                  checked={ticketData.includesCover || false}
                  onChange={handleCheckboxChange}
                  inputProps={{ 'aria-label': 'Incluye Cover' }}
                />
              </Grid>
              <Grid item xs>
                <Typography>Agregar condiciones de tiempo</Typography>
              </Grid>
            </Grid>
            {ticketData.includesCover && (
              <>
                <Grid item xs={12} md={6}>
                  <DatePickerField
                    label="Fecha fin"
                    value={ticketData.endDate || null}
                    onChange={handleEndDateChange}
                    fullWidth
                    error={Boolean(errors.endDate)}
                    helperText={errors.endDate}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TimePickerField
                    label="Horario fin"
                    value={ticketData.endHour || null}
                    onChange={handleEndHourChange}
                    fullWidth
                    error={Boolean(errors.endHour)}
                    helperText={errors.endHour}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    )
  }
)

export default TicketFormItem
