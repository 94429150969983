import React from 'react'
import { TextField } from '@mui/material'

const FormTextField = ({ label, ...props }) => {
  return (
    <TextField
      fullWidth
      label={label}
      variant="outlined"
      InputLabelProps={{
        style: { fontWeight: 400 }, // Lighter font weight for labels
      }}
      sx={{
        borderRadius: '8px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
      }}
      {...props}
    />
  )
}

export default FormTextField
