import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  MenuItem
} from '@mui/material'
import { useSendCourtesies } from '../../../hooks/userManagement/useSendCourtesies'
import { useEventsData } from '../../../hooks/userManagement/useEventsData'
import { useNotification } from '../../../contexts/NotificationContext'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'

const SendCourtesiesDialog = ({ open, onClose, selectedUserIds }) => {
  const [amount, setAmount] = useState('')
  const [selectedEvent, setSelectedEvent] = useState('')
  const { sendCourtesies, loading: sendingCourtesies } = useSendCourtesies()
  const { eventsData, loading: loadingEvents } = useEventsData()
  const { showNotification } = useNotification()

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!selectedEvent) {
      showNotification('Por favor selecciona un evento', 'error')
      return
    }
    try {
      await sendCourtesies({
        userIds: Array.isArray(selectedUserIds) ? selectedUserIds : [selectedUserIds],
        eventId: selectedEvent,
        amount: parseInt(amount, 10)
      })
      showNotification('Cortesías enviadas exitosamente', 'success')
      onClose()
      setAmount('')
      setSelectedEvent('')
    } catch (error) {
      showNotification('Error al enviar las cortesías: ' + error.message, 'error')
    }
  }

  const handleClose = () => {
    onClose()
    setAmount('')
    setSelectedEvent('')
  }

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          maxWidth: '500px',
          width: '100%'
        }
      }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid #E5E7EB',
        p: 3
      }}>
        <Typography 
          variant="h6" 
          sx={{ 
            color: '#101828',
            fontWeight: 600,
            fontFamily: 'Plus Jakarta Sans',
            fontSize: '18px'
          }}
        >
          Enviar Cortesías
        </Typography>
      </DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ p: 3 }}>
          <Typography 
            variant="body2" 
            sx={{ 
              mb: 3,
              color: '#475467',
              fontFamily: 'Inter',
              fontSize: '14px'
            }}
          >
            Se enviarán cortesías a {selectedUserIds.length} {selectedUserIds.length === 1 ? 'usuario seleccionado' : 'usuarios seleccionados'}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              select
              placeholder="Selecciona un Evento"
              value={selectedEvent}
              onChange={(e) => setSelectedEvent(e.target.value)}
              fullWidth
              required
              disabled={loadingEvents}
              sx={{
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  height: '44px',
                  fontSize: '14px',
                  '& fieldset': {
                    borderColor: '#E5E7EB',
                  },
                  '&:hover fieldset': {
                    borderColor: '#F79009',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#F79009',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#6B7280',
                  fontSize: '14px',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#F79009',
                },
                '& .MuiSelect-select': {
                  paddingTop: '10px',
                  paddingBottom: '10px',
                },
              }}
              SelectProps={{
                displayEmpty: true,
                renderValue: (selected) => {
                  if (!selected) {
                    return <Typography sx={{ color: '#6B7280', fontSize: '14px' }}>Selecciona un Evento</Typography>
                  }
                  const event = eventsData.find(e => e.id === selected)
                  return <Typography sx={{ fontSize: '14px' }}>{event?.name}</Typography>
                }
              }}
            >
              {loadingEvents ? (
                <MenuItem disabled>
                  <Typography sx={{ fontSize: '14px' }}>Cargando eventos...</Typography>
                </MenuItem>
              ) : eventsData.length === 0 ? (
                <MenuItem disabled>
                  <Typography sx={{ fontSize: '14px' }}>No hay eventos disponibles</Typography>
                </MenuItem>
              ) : (
                eventsData.map((event) => (
                  <MenuItem 
                    key={event.id} 
                    value={event.id}
                  >
                    <Typography sx={{ fontSize: '14px' }}>{event.name}</Typography>
                  </MenuItem>
                ))
              )}
            </TextField>

            <TextField
              placeholder="Cantidad de cortesías"
              type="number"
              fullWidth
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
              InputProps={{
                inputProps: { min: 1 },
                style: { fontSize: '14px' }
              }}
              sx={{
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  height: '44px',
                  fontSize: '14px',
                  '& fieldset': {
                    borderColor: '#E5E7EB',
                  },
                  '&:hover fieldset': {
                    borderColor: '#F79009',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#F79009',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#6B7280',
                  fontSize: '14px',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#F79009',
                },
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ 
          p: 3, 
          borderTop: '1px solid #E5E7EB',
          gap: 2
        }}>
          <Button
            onClick={handleClose}
            sx={{
              color: '#344054',
              textTransform: 'none',
              fontWeight: 500,
              fontFamily: 'Inter',
              fontSize: '14px',
              '&:hover': {
                backgroundColor: '#F9FAFB'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!amount || !selectedEvent || sendingCourtesies || loadingEvents}
            startIcon={<LocalActivityIcon sx={{ fontSize: 20 }} />}
            sx={{
              backgroundColor: '#F79009',
              color: '#fff',
              textTransform: 'none',
              fontWeight: 500,
              fontFamily: 'Inter',
              fontSize: '14px',
              '&:hover': {
                backgroundColor: '#E27D08'
              },
              '&.Mui-disabled': {
                backgroundColor: '#FDA94E',
                color: '#fff'
              }
            }}
          >
            {sendingCourtesies ? 'Enviando...' : 'Enviar Cortesías'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default SendCourtesiesDialog
