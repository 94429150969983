import React, { useState } from 'react'
import { Checkbox, Grid } from '@mui/material'

import { eventDataErrors as errorMessages } from '../../pages/CreateEventPage/constants'
import { DatePickerField, FileUploader, TimePickerField } from '../../molecules'
import { FormTextField, Typography } from '../../atoms'

const MAX_SIZE = 5 * 1024 * 1024

const EventForm = ({ setData, data }) => {
  const [errors, setErrors] = useState({})

  function formatHour(hour) {
    const regex = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/
    if (!regex.test(hour)) {
      return errors.INVALID_FORMAT_HOUR
    }

    let [hours, minutes] = hour.split(':')

    hours = hours.padStart(2, '0')
    minutes = minutes.padStart(2, '0')

    return `${hours}:${minutes}`
  }

  const handleNameChange = (e) => {
    const value = e.target.value
    setData({ ...data, name: value })

    if (!value.trim()) {
      setErrors({ ...errors, name: errorMessages.REQUIRED_NAME })
    } else {
      const { name, ...rest } = errors
      setErrors(rest)
    }
  }
  const handleAddressChange = (e) => {
    const value = e.target.value
    setData({ ...data, address: value })

    if (!value.trim()) {
      setErrors({ ...errors, address: errorMessages.REQUIRED_ADDRESS })
    } else {
      const { address, ...rest } = errors
      setErrors(rest)
    }
  }
  const handleVenueChange = (e) => {
    const value = e.target.value
    setData({ ...data, venue: value })

    if (!value.trim()) {
      setErrors({ ...errors, venue: errorMessages.REQUIRED_VENUE })
    } else {
      const { venue, ...rest } = errors
      setErrors(rest)
    }
  }

  const handleDescriptionChange = (e) => {
    const value = e.target.value
    setData({ ...data, description: value })

    if (!value.trim()) {
      setErrors({ ...errors, description: errorMessages.REQUIRED_DESCRIPTION })
    } else if (value.length < 10) {
      setErrors({ ...errors, description: errorMessages.MIN_LENGTH_DESCRIPTION })
    } else {
      const { description, ...rest } = errors
      setErrors(rest)
    }
  }
  const handleStartDateChange = (e) => {
    const value = new Date(e?.toLocaleString())
    setData({ ...data, startDate: value.toLocaleDateString('es-CL'), rawStartDate: e })

    if (!value || isNaN(value.getTime())) {
      setErrors({ ...errors, startDate: errorMessages.INVALID_START_DATE })
    } else if (data.endDate && value > data.endDate) {
      setErrors({ ...errors, startDate: errorMessages.INVALID_START_END_DATE })
    } else {
      const { startDate, ...rest } = errors
      setErrors(rest)
    }
  }
  const handleEndDateChange = (e) => {
    const value = new Date(e.toLocaleString())
    setData({ ...data, endDate: value.toLocaleDateString('es-CL'), rawEndDate: e })

    if (!value || isNaN(value.getTime())) {
      setErrors({ ...errors, endDate: errorMessages.INVALID_END_DATE })
    } else if (data.startDate && value < data.startDate) {
      setErrors({ ...errors, endDate: errorMessages.INVALID_END_START_DATE })
    } else {
      const { endDate, ...rest } = errors
      setErrors(rest)
    }
  }
  const handleStartHourChange = (e) => {
    const value = new Date(e.toLocaleString())
    setData({ ...data, startHour: formatHour(`${value.getHours()}:${value.getMinutes()}`), rawStartHour: e })
  }
  const handleEndHourChange = (e) => {
    const value = new Date(e.toLocaleString())
    setData({ ...data, endHour: formatHour(`${value.getHours()}:${value.getMinutes()}`), rawEndHour: e })
  }
  const handleImage = (image, name) => {
    if (!image) {
      setErrors({ ...errors, image: errorMessages.REQUIRED_IMAGE })
    } else if (image.size > MAX_SIZE) {
      setErrors({ ...errors, image: errorMessages.MAX_SIZE_IMAGE })
    } else {
      const { image: imageError, ...rest } = errors
      setErrors(rest)
    }
    setData({ ...data, image, rawImageName: name })
  }
  const handleCheckboxChange = (e) => {
    const value = e.target.checked
    setData({ ...data, nominated: value })
  }
  return (
    <>
      <Grid container spacing={2} width="100%">
        <Grid item xs={12} md={6}>
          <FormTextField
            label="Nombre del Evento"
            onChange={handleNameChange}
            error={Boolean(errors.name)}
            helperText={errors.name}
            value={data.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="Dirección del Evento"
            onChange={handleAddressChange}
            error={Boolean(errors.address)}
            helperText={errors.address}
            value={data.address}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            label="Venue"
            onChange={handleVenueChange}
            error={Boolean(errors.venue)}
            helperText={errors.venue}
            value={data.venue}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            label="Descripción del Evento"
            onChange={handleDescriptionChange}
            multiline
            rows={4}
            error={Boolean(errors.description)}
            helperText={errors.description}
            value={data.description}
          />
        </Grid>
        <Grid item xs={12}>
          <FileUploader handleImage={handleImage} error={Boolean(errors.image)} helperText={errors.image} data={data} />
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sm={6}>
            <DatePickerField
              label="Fecha Inicio"
              onChange={handleStartDateChange}
              fullWidth
              error={Boolean(errors.startDate)}
              helperText={errors.startDate}
              value={data.rawStartDate || null}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePickerField
              label="Fecha Termino"
              onChange={handleEndDateChange}
              fullWidth
              error={Boolean(errors.endDate)}
              helperText={errors.endDate}
              value={data.rawEndDate || null}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TimePickerField
              label="Horario Inicio"
              onChange={handleStartHourChange}
              fullWidth
              value={data.rawStartHour || null}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TimePickerField
              label="Horario Termino"
              onChange={handleEndHourChange}
              fullWidth
              value={data.rawEndHour || null}
            />
          </Grid>
          <Grid container alignItems="center" sx={{ mt: 1 }}>
            <Grid item xs="auto">
              <Checkbox
                checked={data.nominated || false}
                onChange={handleCheckboxChange}
                inputProps={{ 'aria-label': 'Incluye Cover' }}
              />
            </Grid>
            <Grid item xs>
              <Typography>Evento nominado</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default EventForm
