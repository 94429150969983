import React, { memo, useState, useEffect } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Grid, IconButton } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'

import { DatePickerField, TimePickerField } from '../../molecules'
import { FormTextField, Typography } from '../../atoms'

const TicketFormItemModal = memo(
  ({ ticketNumber, ticketData, setTicketData, expanded, onChange, onDelete, dragHandleProps }) => {
    const [errors, setErrors] = useState({})
    const [isEditingPrice, setIsEditingPrice] = useState(false)

    useEffect(() => {
      if (ticketData.end_hour && !ticketData.includesCover) {
        const updatedData = { ...ticketData, includesCover: true }
        setTicketData(updatedData)
      }
    }, [ticketData.end_hour, ticketData.includesCover])

    const validateField = (field, value) => {
      let error = ''
      if (field === 'name' && !value) {
        error = 'El nombre del ticket es requerido'
      }
      if (field === 'price') {
        if (!value) {
          error = 'El precio del ticket es requerido'
        } else if (isNaN(value)) {
          error = 'El precio del ticket debe ser un número'
        } else if (Number(value) <= 0) {
          error = 'El precio del ticket debe ser mayor que cero'
        }
      }
      if (field === 'stock') {
        if (!value || isNaN(value)) {
          error = 'La cantidad disponible es requerida y debe ser un número'
        } else if (Number(value) <= 0) {
          error = 'La cantidad disponible debe ser mayor que cero'
        }
      }
      if (field === 'max_per_sale') {
        if (!value || isNaN(value)) {
          error = 'La cantidad máxima por venta es requerida y debe ser un número'
        } else if (Number(value) <= 0) {
          error = 'La cantidad máxima por venta debe ser mayor que cero'
        } else if (Number(value) > Number(ticketData.stock)) {
          error = 'La cantidad máxima por venta no puede ser mayor que la cantidad disponible'
        }
      }
      if (ticketData.includesCover) {
        if (field === 'end_at') {
          if (!value) {
            error = 'La fecha fin es requerida'
          }
        }
        if (field === 'end_hour') {
          if (!value) {
            error = 'La hora fin es requerida'
          }
        }
      }
      setErrors((prevErrors) => ({ ...prevErrors, [field]: error }))
    }

    const formatPrice = (value) => {
      if (!value) return ''
      const number = parseInt(value, 10)
      if (isNaN(number)) return ''
      return '$ ' + number.toLocaleString('es-CL')
    }

    const handleFieldChange = (field) => (e) => {
      let value = e.target.value
      if (field === 'price' || field === 'stock' || field === 'max_per_sale') {
        value = value.replace(/\D/g, '')
      }

      const updatedData = { ...ticketData, [field]: value }
      setTicketData(updatedData)
      validateField(field, value)
    }

    const handleFieldBlur = (field) => (e) => {
      if (field === 'price') {
        const value = ticketData.price
        const formattedValue = formatPrice(value)
        const updatedData = { ...ticketData, formattedPrice: formattedValue }
        setTicketData(updatedData)
        setIsEditingPrice(false)
      }
    }

    const handleCheckboxChange = (e) => {
      const value = e.target.checked
      const updatedData = { ...ticketData, includesCover: value }
      setTicketData(updatedData)
    }

    const handleEndDateChange = (date) => {
      const value = new Date(date?.toLocaleString())
      const updatedData = {
        ...ticketData,
        end_at: value.toLocaleDateString('es-CL'),
        rawEndDate: date
      }
      setTicketData(updatedData)
      validateField('end_at', date)
    }

    const handleEndHourChange = (time) => {
      const value = new Date(time?.toLocaleString())
      const updatedData = {
        ...ticketData,
        end_hour: `${value.getHours().toString().padStart(2, '0')}:${value.getMinutes().toString().padStart(2, '0')}`,
        rawEndHour: time
      }
      setTicketData(updatedData)
      validateField('end_hour', time)
    }

    return (
      <Accordion
        expanded={expanded}
        onChange={(event, isExpanded) => onChange(isExpanded)}
        sx={{
          width: '100%',
          border: '1px dashed #000',
          borderRadius: '10px',
          '&::before': { display: 'none' },
          backgroundColor: '#fff',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${ticketNumber}-content`}
          id={`panel${ticketNumber}-header`}
          sx={{ padding: '0 16px', color: '#000' }}
          {...dragHandleProps}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography 
                variant="h6" 
                sx={{ 
                  color: '#000',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: 700,
                  fontSize: { xs: '1.1rem', sm: '1.25rem' }
                }}
              >
                {ticketData.name ? ticketData.name : `Ticket`}
                {ticketData.formattedPrice ? ` - ${ticketData.formattedPrice}` : ''}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                edge="end"
                onClick={(e) => {
                  e.stopPropagation()
                  onDelete()
                }}
              >
                <DeleteIcon sx={{ color: '#000' }} /> {/* Icono negro */}
              </IconButton>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Grid container spacing={2} sx={{ padding: '16px', color: '#000' }}>
            {/* Nombre del Ticket */}
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Nombre del Ticket"
                value={ticketData.name || ''}
                onChange={handleFieldChange('name')}
                error={Boolean(errors.name)}
                helperText={errors.name}
                sx={{
                  '& .MuiInputLabel-root': { color: '#000' }, // Etiqueta negra
                  '& .MuiInputBase-input': { color: '#000' }, // Texto de input negro
                  '& .MuiFormHelperText-root': { color: 'red' }, // Texto de ayuda rojo para errores
                }}
              />
            </Grid>
            {/* Precio del Ticket */}
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Precio del Ticket"
                value={
                  isEditingPrice
                    ? formatPrice(ticketData.price) || ''
                    : ticketData.formattedPrice || formatPrice(ticketData.price)
                }
                onChange={handleFieldChange('price')}
                onFocus={() => setIsEditingPrice(true)}
                onBlur={handleFieldBlur('price')}
                error={Boolean(errors.price)}
                helperText={errors.price}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                sx={{
                  '& .MuiInputLabel-root': { color: '#000' },
                  '& .MuiInputBase-input': { color: '#000' },
                  '& .MuiFormHelperText-root': { color: 'red' },
                }}
              />
            </Grid>
            {/* Cantidad disponible */}
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Cantidad disponible"
                value={ticketData.stock || ''}
                onChange={handleFieldChange('stock')}
                error={Boolean(errors.stock)}
                helperText={errors.stock}
                sx={{
                  '& .MuiInputLabel-root': { color: '#000' },
                  '& .MuiInputBase-input': { color: '#000' },
                  '& .MuiFormHelperText-root': { color: 'red' },
                }}
              />
            </Grid>
            {/* Cantidad maxima por venta */}
            <Grid item xs={12} md={6}>
              <FormTextField
                label="Cantidad máxima por venta"
                value={ticketData.max_per_sale || ''}
                onChange={handleFieldChange('max_per_sale')}
                error={Boolean(errors.max_per_sale)}
                helperText={errors.max_per_sale}
                sx={{
                  '& .MuiInputLabel-root': { color: '#000' },
                  '& .MuiInputBase-input': { color: '#000' },
                  '& .MuiFormHelperText-root': { color: 'red' },
                }}
              />
            </Grid>
            {/* Checkboxes Container */}
            <Grid container spacing={{ xs: 0, sm: 2 }} sx={{ mt: 1, pl: { xs: 1, sm: 1 } }}>
              {/* Fuera de stock */}
              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs="auto">
                    <Checkbox
                      checked={ticketData.out_of_stock || false}
                      onChange={(e) => {
                        const updatedData = { ...ticketData, out_of_stock: e.target.checked }
                        setTicketData(updatedData)
                      }}
                      inputProps={{ 'aria-label': 'Fuera de stock' }}
                      sx={{ 
                        color: '#000', 
                        '&.Mui-checked': { color: '#000' },
                        padding: { xs: '4px', sm: '9px' }
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography sx={{ 
                      color: '#000',
                      fontFamily: 'Inter',
                      fontSize: { xs: '0.8rem', sm: '0.875rem' },
                      lineHeight: 1.2
                    }}>
                      Fuera de stock
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Condiciones Tiempo */}
              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center">
                  <Grid item xs="auto">
                    <Checkbox
                      checked={ticketData.includesCover || Boolean(ticketData.end_hour) || false}
                      onChange={handleCheckboxChange}
                      inputProps={{ 'aria-label': 'Incluye Cover' }}
                      sx={{ 
                        color: '#000', 
                        '&.Mui-checked': { color: '#000' },
                        padding: { xs: '4px', sm: '9px' }
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography sx={{ 
                      color: '#000',
                      fontFamily: 'Inter',
                      fontSize: { xs: '0.8rem', sm: '0.875rem' },
                      lineHeight: 1.2
                    }}>
                      Agregar condiciones de tiempo
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {(ticketData.includesCover || Boolean(ticketData.end_hour)) && (
              <>
                <Grid item xs={12} md={6}>
                  <DatePickerField
                    label="Fecha fin"
                    value={ticketData.end_at ? new Date(ticketData.end_at) : null}
                    onChange={handleEndDateChange}
                    fullWidth
                    error={Boolean(errors.end_at)}
                    helperText={errors.end_at}
                    sx={{
                      '& .MuiInputLabel-root': { color: '#000' },
                      '& .MuiInputBase-input': { color: '#000' },
                      '& .MuiFormHelperText-root': { color: 'red' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TimePickerField
                    label="Horario fin"
                    value={ticketData.end_hour ? new Date(`2024-01-01 ${ticketData.end_hour}`) : null}
                    onChange={handleEndHourChange}
                    fullWidth
                    error={Boolean(errors.end_hour)}
                    helperText={errors.end_hour}
                    sx={{
                      '& .MuiInputLabel-root': { color: '#000' },
                      '& .MuiInputBase-input': { color: '#000' },
                      '& .MuiFormHelperText-root': { color: 'red' },
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    )
  }
)

export default TicketFormItemModal
