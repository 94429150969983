import React, { useCallback, useMemo } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'

import { SidenavStyle } from './Sidenav.style'
import { setMiniSidenav, useMangoController } from '../../../context'
import LargeWindowSVG from '../../../assets/mango/logos/MangoTicket-Color-White.svg'
import SmallWindowSVG from '../../../assets/mango/logos/Mango.svg'
import { SidenavCollapse } from '../../organisms'
import { UserThumbnail } from '../../molecules'
import { Box } from '../../atoms'

function Sidenav({ routes, ...rest }) {
  const [controller, dispatch] = useMangoController()
  const { miniSidenav } = controller
  const location = useLocation()

  // Extraer eventId del pathname y verificar si estamos en new event
  const { eventId, isNewEvent } = useMemo(() => {
    const match = location.pathname.match(/\/events\/([^/]+)/)
    const isNew = location.pathname === '/events/new'
    return {
      eventId: match && !isNew ? match[1] : null,
      isNewEvent: isNew
    }
  }, [location.pathname])

  // Filtrar y modificar las rutas según las condiciones
  const filteredRoutes = useMemo(() => {
    return routes
      .filter(route => {
        // Mostrar Home siempre
        if (route.key === 'dashboard') return true
        
        // Mostrar Administración de usuarios siempre excepto en /events/new
        if (route.key === 'user-management' && !isNewEvent) return true
        
        // Mostrar Detalles y Métricas solo cuando hay un eventId y NO estamos en new
        if ((route.key === 'event-detail' || route.key === 'event-metrics') && eventId && !isNewEvent) {
          return true
        }
        
        // Ocultar Eventos y Crea tu evento
        if (route.key === 'events' || route.key === 'new-event') {
          return false
        }

        return false
      })
      .map(route => {
        // Reemplazar :eventId en las rutas si existe
        if (route.route.includes(':eventId') && eventId) {
          return {
            ...route,
            route: route.route.replace(':eventId', eventId)
          }
        }
        return route
      })
      // Ordenar las rutas: Home primero, luego Administración de usuarios, luego el resto
      .sort((a, b) => {
        if (a.key === 'dashboard') return -1
        if (b.key === 'dashboard') return 1
        if (a.key === 'user-management') return -1
        if (b.key === 'user-management') return 1
        return 0
      })
  }, [routes, eventId, isNewEvent])

  const renderAppRoutes = useMemo(() => {
    return filteredRoutes.map(({ type, name, icon, key, route }) => {
      if (type === 'protected') {
        return (
          <NavLink key={key} to={route}>
            {({ isActive }) => (
              <SidenavCollapse 
                name={!miniSidenav ? name : ''} 
                icon={icon} 
                active={isActive} 
              />
            )}
          </NavLink>
        )
      }
      return null
    })
  }, [filteredRoutes, miniSidenav])

  const handleOnClick = useCallback(() => {
    setMiniSidenav(dispatch, !miniSidenav)
  }, [dispatch, miniSidenav])

  return (
    <SidenavStyle {...rest} variant="permanent" ownerState={{ miniSidenav }}>
      <Box display="flex" flexDirection="column" height="100vh" mb={2}>
        <List sx={{ flexGrow: 1 }}>
          <Box pt={5} display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" onClick={handleOnClick}>
              {miniSidenav ? (
                <img src={SmallWindowSVG} alt="Small Window" style={{ width: '40px' }} />
              ) : (
                <img src={LargeWindowSVG} alt="Large Window" style={{ width: '200px' }} />
              )}
            </Box>
            <Divider light={true} sx={{ width: '100%' }} />
          </Box>
          <Box>{renderAppRoutes}</Box>
        </List>
        <Divider light={true} sx={{ width: '100%' }} />
        <UserThumbnail
          name="Panchito Perez"
          email="fperez@gmail.com"
          image="https://via.placeholder.com/150"
          displayUserData={!miniSidenav}
        />
        <Box mb={2} />
      </Box>
    </SidenavStyle>
  )
}

Sidenav.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Sidenav
