// Events.jsx
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme, useMediaQuery, MenuItem, Select } from '@mui/material'
import { ReactComponent as ChevronDownIcon } from '../../../assets/mango/icons/chevron-down-outline.svg'

import { Box, LoadingSpinner, Typography } from '../../atoms'
import { useFetchEventsData } from '../../../hooks'
import { CustomerEventsTable } from '../index'
import { HorizontalMenu } from '../../molecules'

const MENU_ITEMS = [
  'Todos',
  'Pendiente',
  'Activo',
  'Publicado',
  'Aprobado',
  'Finalizado',
  'Cancelado',
]

const STATUS_MAPPING = {
  'Todos': null,
  'Pendiente': 'EVENT_PENDING',
  'Activo': 'EVENT_ACTIVE',
  'Publicado': 'EVENT_PUBLISHED',
  'Aprobado': 'EVENT_APPROVED',
  'Finalizado': 'EVENT_FINISHED',
  'Cancelado': 'EVENT_CANCELED',
}

const Events = ({ title, height }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate()
  const [selectedStatus, setSelectedStatus] = useState('Todos')
  const { events, loading } = useFetchEventsData()

  const handleTabChange = useCallback((index) => {
    setSelectedStatus(MENU_ITEMS[index])
  }, [])

  const handleSelectChange = (event) => {
    const value = event.target.value
    setSelectedStatus(value)
    const selectedIndex = MENU_ITEMS.indexOf(value)
    handleTabChange(selectedIndex)
  }

  const filteredEvents = useMemo(() => {
    if (!events) return []
    if (selectedStatus === 'Todos') return events

    const statusToFilter = STATUS_MAPPING[selectedStatus]
    if (!statusToFilter) return events

    return events.filter((event) => event.status === statusToFilter)
  }, [events, selectedStatus])

  const handleEventClick = useCallback(
    (eventId) => {
      navigate(`/events/${eventId}`)
    },
    [navigate]
  )

  const renderEvents = () => {
    if (loading) {
      return <LoadingSpinner />
    }

    return (
      <CustomerEventsTable
        events={filteredEvents}
        onEventClick={handleEventClick}
        height={height}
      />
    )
  }

  return (
    <Box>
      <Box 
        mb={4} 
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column',
          alignItems: isMobile ? 'center' : 'flex-start',
          justifyContent: isMobile ? 'space-between' : 'flex-start',
          gap: isMobile ? 2 : 1
        }}
      >
        <Typography 
          variant="h4" 
          color="white" 
          fontWeight="bold"
          sx={{
            fontSize: isMobile ? '1.25rem' : '2rem',
          }}
        >
          {title}
        </Typography>
        <Box 
          sx={{
            width: isMobile ? 'auto' : '100%',
            maxWidth: isMobile ? '60%' : 'none'
          }}
        >
          {isMobile ? (
            <Select
              value={selectedStatus}
              onChange={handleSelectChange}
              size="small"
              IconComponent={() => (
                <ChevronDownIcon style={{ 
                  width: '20px', 
                  height: '20px', 
                  color: '#718EBF',
                  position: 'absolute',
                  right: '8px',
                  pointerEvents: 'none'
                }} />
              )}
              sx={{
                backgroundColor: '#344054',
                color: 'white',
                height: '32px',
                minWidth: '120px',
                fontSize: '14px',
                borderRadius: '4px',
                cursor: 'pointer',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:hover': {
                  backgroundColor: '#3f4b63',
                },
                '&.Mui-focused': {
                  backgroundColor: '#3f4b63',
                },
                '& .MuiSelect-select': {
                  padding: '4px 8px',
                  paddingRight: '32px !important',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  color: 'white !important',
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: '#fff',
                    marginTop: '4px',
                    '& .MuiMenuItem-root': {
                      fontSize: '14px',
                      padding: '6px 12px',
                      minHeight: 'unset',
                      color: '#000 !important',
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#f5f5f5',
                        '&:hover': {
                          backgroundColor: '#eeeeee',
                        },
                      },
                    },
                  },
                },
              }}
            >
              {MENU_ITEMS.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <HorizontalMenu
              menu={MENU_ITEMS}
              onTabChange={handleTabChange}
            />
          )}
        </Box>
      </Box>
      {renderEvents()}
    </Box>
  )
}

export default Events
