// src/hooks/useEventsData.js

import { gql, useQuery } from '@apollo/client'

const GET_CUSTOMER_EVENTS = gql`
  query GetCustomerEvents {
    getCustomerEvents {
      id
      name
      image
      status
      start_at
      end_at
      created_at
      updated_at
    }
  }
`

export const useEventsData = () => {
  const { data, loading, error, refetch } = useQuery(GET_CUSTOMER_EVENTS)

  return {
    eventsData: data?.getCustomerEvents || [],
    loading,
    error,
    refetch
  }
}
