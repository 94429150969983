import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { useCashiersData } from '../../../hooks/eventDetails/useCashiersData'
import useCreateCashier from '../../../hooks/eventDetails/useCreateCashier'
import useDeleteCashier from '../../../hooks/eventDetails/useDeleteCashier'
import useUpdateCashier from '../../../hooks/eventDetails/useUpdateCashier'
import useChangePassword from '../../../hooks/eventDetails/useChangePassword'
import { AddCashierDialog, CashierTableRow, ChangePasswordDialog } from '../index'
import { LoadingSpinner } from '../../atoms'
import { ReactComponent as DownArrow } from '../../../assets/mango/icons/chevron-down-outline.svg'

const CashierTable = () => {
  const { cashiersData, loading, error, refetch } = useCashiersData()
  const { createCashierData } = useCreateCashier()
  const { deleteCashierData } = useDeleteCashier()
  const { updateCashierData } = useUpdateCashier()
  const { changePassword } = useChangePassword()

  const [open, setOpen] = useState(false)
  const [passwordModalOpen, setPasswordModalOpen] = useState(false)
  const [selectedWorker, setSelectedWorker] = useState(null)
  const [newWorker, setNewWorker] = useState({
    id: '',
    name: '',
    email: '',
    password: '',
    role: '',
  })
  const [editingWorker, setEditingWorker] = useState(null)

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  useEffect(() => {
    if (editingWorker) {
      setNewWorker({
        id: editingWorker.id,
        name: editingWorker.name,
        email: editingWorker.email,
        password: '',
        role: editingWorker.role,
      })
    }
  }, [editingWorker])

  const handleOpen = useCallback(() => {
    setEditingWorker(null)
    setNewWorker({
      id: '',
      name: '',
      email: '',
      password: '',
      role: '',
    })
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
    setNewWorker({
      id: '',
      name: '',
      email: '',
      password: '',
      role: '',
    })
    setEditingWorker(null)
  }, [])

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target
    setNewWorker((prev) => ({
      ...prev,
      [name]: value,
    }))
  }, [])

  const handleSaveWorker = useCallback(async () => {
    if (newWorker.name && newWorker.email && (editingWorker || newWorker.password) && newWorker.role) {
      try {
        if (editingWorker) {
          await updateCashierData(newWorker)
        } else {
          await createCashierData(newWorker)
        }
        await refetch()
        handleClose()
      } catch (err) {
        console.error(err)
        alert('Ocurrió un error al guardar el trabajador.')
      }
    } else {
      alert('Por favor, completa todos los campos.')
    }
  }, [newWorker, editingWorker, updateCashierData, createCashierData, refetch, handleClose])

  const handleEditWorker = useCallback((worker) => {
    if (!worker) return
    setNewWorker({
      id: worker.id || '',
      name: worker.name || '',
      email: worker.email || '',
      password: '',
      role: worker.role || '',
    })
    setEditingWorker(worker)
    setOpen(true)
  }, [])

  const handleDeleteWorker = useCallback(
    async (workerId) => {
      try {
        await deleteCashierData(workerId)
        await refetch()
      } catch (err) {
        console.error(err)
        alert('Ocurrió un error al eliminar el trabajador.')
      }
    },
    [deleteCashierData, refetch]
  )

  const handleChangePassword = useCallback((worker) => {
    setSelectedWorker(worker)
    setPasswordModalOpen(true)
  }, [])

  if (loading) return <LoadingSpinner />

  if (error) {
    return (
      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          backgroundColor: '#07181E',
          color: '#fff',
          borderRadius: '10px',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" color="error">
            {error.message}
          </Typography>
          <Typography variant="body1">
            Por favor, intenta refrescar la página o contacta a soporte si el problema persiste.
          </Typography>
        </Box>
      </Paper>
    )
  }

  const handleClosePasswordModal = () => {
    setPasswordModalOpen(false)
    setSelectedWorker(null)
  }

  const handleSavePassword = async (workerId, newPassword) => {
    try {
      await changePassword(workerId, newPassword)
      handleClosePasswordModal()
      alert('Contraseña actualizada exitosamente.')
    } catch (err) {
      console.error(err)
      alert('Ocurrió un error al cambiar la contraseña.')
    }
  }

  return (
    <Paper
      elevation={3}
      sx={{
        padding: {
          xs: '16px',
          sm: '20px',
          md: '30px'
        },
        backgroundColor: '#07181E',
        color: '#fff',
        borderRadius: '10px',
      }}
    >
      <Box
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={isSmallScreen ? 'flex-start' : 'center'}
        marginBottom={2}
      >
        <Typography 
          variant="h6" 
          component="div" 
          sx={{ 
            color: 'white !important', 
            fontWeight: 'bold',
            fontSize: {
              xs: '16px',
              sm: '18px'
            },
            marginBottom: isSmallScreen ? '12px' : '0'
          }}
        >
          Trabajadores
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#F79009',
            color: 'white !important',
            borderRadius: '8px',
            fontWeight: 'bold',
            padding: {
              xs: '4px 8px',
              sm: '6px 12px'
            },
            fontSize: {
              xs: '14px',
              sm: '16px'
            },
            marginTop: isSmallScreen ? '0' : '0',
            alignSelf: isSmallScreen ? 'stretch' : 'auto',
          }}
          startIcon={<AddIcon sx={{ fontSize: { xs: '18px', sm: '24px' } }} />}
          onClick={handleOpen}
        >
          Agregar Cajero
        </Button>
      </Box>
      <Box
        sx={{
          flexShrink: 0,
          width: '100%',
          height: '1px',
          backgroundColor: '#041E2B',
          marginBottom: {
            xs: '12px',
            sm: '16px'
          },
        }}
      />
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          width: '100%',
          backgroundColor: '#07181E',
          borderRadius: '0',
          boxShadow: 'none',
          border: 'none',
          maxHeight: {
            xs: '300px',
            sm: 'none'
          },
          overflowY: 'auto'
        }}
      >
        <Table>
          <TableHead sx={{ display: 'table-header-group' }}>
            <TableRow>
              {cashiersData.length
                ? ['Nombre', 'Correo', 'Cargo'].map((header) => (
                    <TableCell
                      key={header}
                      style={{ 
                        paddingBottom: 8, 
                        borderBottom: 'none',
                        padding: isSmallScreen ? '8px 4px' : '8px 16px'
                      }}
                      sx={{ 
                        display: isSmallScreen && header === 'Correo' ? 'none' : 'table-cell',
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <Typography 
                          variant="h6" 
                          fontWeight="bold" 
                          color="white !important"
                          sx={{
                            fontSize: {
                              xs: '14px',
                              sm: '16px'
                            }
                          }}
                        >
                          {header}
                        </Typography>
                        <IconButton sx={{ padding: { xs: '2px', sm: '4px' } }}>
                          <DownArrow style={{ height: isSmallScreen ? '12px' : '15px', width: isSmallScreen ? '12px' : '15px', color: '#718EBF' }} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  ))
                : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {cashiersData.map((worker) => (
              <CashierTableRow
                key={worker.id}
                worker={worker}
                onEdit={handleEditWorker}
                onDelete={handleDeleteWorker}
                onChangePassword={handleChangePassword}
                isSmallScreen={isSmallScreen}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddCashierDialog
        open={open}
        onClose={handleClose}
        worker={newWorker}
        onInputChange={handleInputChange}
        onSave={handleSaveWorker}
        isEditing={!!editingWorker}
      />
      <ChangePasswordDialog
        open={passwordModalOpen}
        onClose={handleClosePasswordModal}
        onSave={handleSavePassword}
        worker={selectedWorker}
      />
    </Paper>
  )
}

export default CashierTable
