// useCashiersData.js

import { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'

const LIST_CASHIERS = gql`
  query ListCashiers {
    listCashiers {
      id
      name
      email
      role
    }
  }
`

export const useCashiersData = () => {
  const [cashiersData, setCashiersData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const { data: queryData, loading: queryLoading, error: queryError, refetch } = useQuery(LIST_CASHIERS)

  useEffect(() => {
    if (queryData) {
      const transformedData = queryData.listCashiers.map((worker) => ({
        ...worker,
        role: worker.role.toLowerCase(),
      }))
      setCashiersData(transformedData)
      setLoading(false)
    }
    if (queryError) {
      setError(queryError)
      setLoading(false)
    }
  }, [queryData, queryError])

  return {
    cashiersData,
    loading: loading || queryLoading,
    error,
    refetch,
  }
}
