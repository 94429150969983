import Drawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'

export const SidenavStyle = styled(Drawer)(({ theme, ownerState }) => {
  const { boxShadows, transitions, breakpoints } = theme
  const { miniSidenav } = ownerState

  const sidebarWidth = 250
  const miniSidebarWidth = 96
  const { xxl } = boxShadows

  // Set the background gradient directly
  let backgroundValue = 'linear-gradient(to bottom, #04121A, #092838)'

  const drawerOpenStyles = () => ({
    position: 'fixed',
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
    borderRadius: 0,
    height: '100%',
    background: backgroundValue,
    transform: 'translateX(0)',
    transition: transitions.create('transform', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),
    [breakpoints.up('xl')]: {
      boxShadow: 'none',
      width: sidebarWidth,
      transition: transitions.create(['width', 'background-color'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  })

  const drawerCloseStyles = () => ({
    position: 'fixed',
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
    borderRadius: 0,
    height: '100%',
    background: backgroundValue,
    transform: 'translateX(0)',
    transition: transitions.create('transform', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),
    [breakpoints.up('xl')]: {
      boxShadow: 'none',
      width: miniSidebarWidth,
      overflowX: 'hidden',
      transition: transitions.create(['width', 'background-color'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
    [breakpoints.down('xl')]: {
      width: miniSidebarWidth,
      overflowX: 'hidden',
      transition: transitions.create(['width', 'background-color'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  })

  return {
    '& .MuiDrawer-paper': {
      boxShadow: 'none',
      border: 'none',
      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  }
})
