import React, { useCallback, useContext, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { ThemeProvider, useTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { LoginPage, NotFoundPage, RegisterPage } from './components/pages'
import { ProtectedRoute } from 'components/organisms'
import { SideNav } from './components/templates'
import theme from 'assets/theme'
import routes from 'routes'
import routeComponents from './routeComponents'
import { AuthContext, setLayout, setMiniSidenav, useMangoController } from 'context'
import { useIsMobile } from './hooks/useIsMobile'
import { NotificationProvider } from './contexts/NotificationContext'

function AppContent() {
  const authContext = useContext(AuthContext)
  const [controller, dispatch] = useMangoController()
  const { miniSidenav, direction, layout, sidenavColor } = controller
  const { pathname } = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery('(min-width: 575px) and (max-width: 1025px)')
  const isMediumDesktop = useMediaQuery('(min-width: 1026px) and (max-width: 1310px)')

  useEffect(() => {
    if (isMobile || isTablet) {
      setMiniSidenav(dispatch, true)
    } else {
      setMiniSidenav(dispatch, false)
    }
  }, [isMobile, isTablet, dispatch])

  useEffect(() => {
    document.body.setAttribute('dir', direction)
  }, [direction])

  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
  }, [pathname])

  const routeExists = useCallback(
    (pathname, routes) => {
      return routes.some((route) => {
        if (route.collapse) {
          return routeExists(pathname, route.collapse)
        }
        if (route.route) {
          const routePattern = route.route.replace(/:\w+/g, '[^/]+')
          const regex = new RegExp(`^${routePattern}$`)
          return regex.test(pathname)
        }
        return false
      })
    },
    []
  )

  useEffect(() => {
    if (pathname === '/') {
      setLayout(dispatch, 'landing')
    } else if (pathname.startsWith('/events/')) {
      setLayout(dispatch, 'event')
    } else if (pathname === '/auth/login' || pathname === '/auth/register') {
      setLayout(dispatch, 'auth')
    } else if (routeExists(pathname, routes)) {
      setLayout(dispatch, 'home')
    } else {
      setLayout(dispatch, 'plain')
    }
  }, [pathname, dispatch, routeExists])

  const getRoutes = useCallback(
    (allRoutes) => {
      return allRoutes.map((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse)
        }

        if (route.route) {
          const Component = routeComponents[route.key]
          if (!Component) return null

          if (route.type === 'auth' || route.type === 'public') {
            return (
              <Route
                exact
                path={route.route}
                element={<Component />}
                key={route.key}
              />
            )
          }

          return (
            <Route
              exact
              path={route.route}
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  <Component />
                </ProtectedRoute>
              }
              key={route.key}
            />
          )
        }
        return null
      })
    },
    [authContext.isAuthenticated]
  )

  return (
    <>
      {layout !== 'landing' && layout !== 'auth' && !isMobile && (
        <SideNav color={sidenavColor} routes={routes} />
      )}
      <MainContent 
        miniSidenav={miniSidenav} 
        layout={layout} 
        isMobile={isMobile} 
        isTablet={isTablet}
        isMediumDesktop={isMediumDesktop}
      >
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </MainContent>
    </>
  )
}

function MainContent({ children, miniSidenav, layout, isMobile, isTablet, isMediumDesktop }) {
  const getStyles = () => {
    const baseStyles = {
      background: 'linear-gradient(to bottom, #04121A, #092838)',
    }

    if (layout === 'landing') {
      return {
        ...baseStyles,
        width: '100%',
        margin: 0,
        padding: 0,
        '& > *': {
          width: '100%',
          margin: 0,
          padding: 0,
          maxWidth: 'none'
        }
      }
    }

    if (isMobile) {
      return {
        ...baseStyles,
        marginLeft: 0,
        width: '100%',
        '& > *': {
          width: '100%',
          paddingLeft: '16px',
          paddingRight: '16px',
        }
      }
    }

    if (isTablet) {
      return {
        ...baseStyles,
        marginLeft: layout !== 'landing' && layout !== 'auth' ? (miniSidenav ? '96px' : '250px') : '0px',
        width: `calc(100% - ${layout !== 'landing' && layout !== 'auth' ? (miniSidenav ? '96px' : '250px') : '0px'})`,
        '& > *': {
          width: '100%',
          paddingLeft: '24px',
          paddingRight: '24px',
        }
      }
    }

    if (isMediumDesktop) {
      return {
        ...baseStyles,
        marginLeft: layout !== 'landing' && layout !== 'auth' ? (miniSidenav ? '96px' : '250px') : '0px',
        marginRight: layout !== 'landing' && layout !== 'auth' ? '96px' : '0px',
        '& > *': {
          width: '100%',
          maxWidth: '1000px',
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: '32px',
          paddingRight: '32px',
        }
      }
    }

    return {
      ...baseStyles,
      marginLeft: layout !== 'landing' && layout !== 'auth' ? (miniSidenav ? '96px' : '250px') : '0px',
      marginRight: layout !== 'landing' && layout !== 'auth' ? (miniSidenav ? '96px' : '250px') : '0px',
      '& > *': {
        width: '100%',
        maxWidth: '1000px',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: '32px',
        paddingRight: '32px',
      }
    }
  }

  return (
    <Box
      component="main"
      sx={getStyles()}
    >
      {children}
    </Box>
  )
}

function App() {
  return (
    <NotificationProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppContent />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </ThemeProvider>
    </NotificationProvider>
  )
}

export default App
