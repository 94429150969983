import React from 'react'
import { DeviceFrameset } from 'react-device-frameset'
import 'react-device-frameset/styles/marvel-devices.min.css'

const MobilePhone = ({ children }) => {
  return (
    <>
      <DeviceFrameset device="Nexus 5" color="black" portrait width="auto" height="auto">
        {children}
      </DeviceFrameset>
    </>
  )
}

export default MobilePhone
