import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Icon from '@mui/material/Icon'

import { useMangoController } from '../../../context'
import { Box } from '../../atoms'
import { collapseIcon, collapseIconBox, collapseItem, collapseText } from './SidenavCollapse.style'

function SidenavCollapse({ icon, name, active = false, ...rest }) {
  const [controller] = useMangoController()
  const { miniSidenav, sidenavColor } = controller

  const boxSx = useMemo(
    () => (theme) =>
      collapseItem(theme, {
        active,
        sidenavColor,
      }),
    [active, sidenavColor]
  )

  const iconBoxSx = useMemo(() => (theme) => collapseIconBox(theme, { active }), [active])

  const iconSx = useMemo(() => (theme) => collapseIcon(theme, { active }), [active])

  const textSx = useMemo(
    () => (theme) =>
      collapseText(theme, {
        miniSidenav,
        active,
      }),
    [miniSidenav, active]
  )

  return (
    <ListItem component="li">
      <Box {...rest} sx={boxSx}>
        <ListItemIcon sx={iconBoxSx}>{typeof icon === 'string' ? <Icon sx={iconSx}>{icon}</Icon> : icon}</ListItemIcon>
        {name !== '' ? <ListItemText primary={name} sx={textSx} /> : null}
      </Box>
    </ListItem>
  )
}

SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
}

export default React.memo(SidenavCollapse)
