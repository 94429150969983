import Axios from 'axios'

const API_URL = process.env.REACT_APP_MANGO_DASHBOARD_API_URL
Axios.defaults.baseURL = API_URL

export class HttpService {
  _axios = Axios.create()

  addRequestInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.request.use(onFulfilled, onRejected)
  }

  addResponseInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.response.use(onFulfilled, onRejected)
  }

  get = async (url) => await this.request(this.getOptionsConfig('get', url))

  post = async (url, data) => await this.request(this.getOptionsConfig('post', url, data))

  put = async (url, data) => await this.request(this.getOptionsConfig('put', url, data))

  patch = async (url, data) => await this.request(this.getOptionsConfig('patch', url, data))

  delete = async (url) => await this.request(this.getOptionsConfig('delete', url))

  getOptionsConfig = (method, url, data) => {
    return {
      method,
      url,
      data,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoie1widXNlcl9pZFwiOlwiNjk3MTlmZTctMDk3OS00NTgwLTljODEtNzExMGU0ZjBjY2RiXCIsXCJyb2xlc1wiOlwidXNlcixjdXN0b21lclwifSIsImV4cCI6MTcyMzY1NjgwNCwiaWF0IjoxNzIzNjU1MDA0LCJuYmYiOjE3MjM2NTUwMDR9.IWYRquUiBj7h-PfZZ1KQ7Xyv4cFGtsXEm4tCltEb4tevREVaXoFeSF-yrL7NYzWRODjDkhy6_DGncpQS8R7GZaNzit9FiXvadFthP2-101rNDYtHYMoVV0oChneVhfV2zHR57snSuhxyPd33iqGXEMe6IS-XnVoTLwKcDA5Y9JPHFwyPlKV7FeCBb-a7rZC3DgFWzNPKWCNe231E6zbq5gahjcV4N54qHDbe2NDw0jwU_5UBwqxgZDqrKyxefbXcj-tCpgLWTYkY3uElkodAkgdaBhkMY6qwOLsWXXz38V6zEH0FkmYAICiKgrjjbwyHxPLEpqncPofVI0cGFKHJTvZIXbSc2h6BuYM1CEIG4K62eajLbgzgfw7dFZAmaBBAmynCdLt6oUPqQm0frg0sXUNB53r3xxRiAr7RNd_NB_qDWB4PuJ_0ur2yZZlRo6XEvFLpvQ66hADDk6U90eisP7mYMrQx_L_GATGu6P0rzKqdA-20h_1hOA9s3BN29seLw-9e_QUpQ2-97srcvnSIfarlBsDmS15gb53Q3w2BfffEYaYsGUYQBTTiIvisYgt7TYZrqcFUzDySmM0wSi0PwD6EzsWN4-t7NTiGKxh-gaVKD5FdlI_TW7ieEvrQgLDPUEvbGDLAqThZ6ogVnRfZTzIgHlQNHDYyvhSD4b1KXS0`,
        // 'Access-Control-Allow-Credentials': false,
      },
    }
  }

  request(options) {
    return new Promise((resolve, reject) => {
      this._axios
        .request(options)
        .then((res) => resolve(res?.data))
        .catch((ex) => reject(ex?.response?.data))
    })
  }
}

export default new HttpService()
